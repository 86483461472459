import { Text } from "@doar/components";
import DOMPurify from "dompurify";
import { parse, stringify } from "query-string";
import { FC, useRef, useState } from "react";
import { Mail } from "react-feather";
import { FaReply } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import FileView from "src/components/file-view";
import { useAppSelector } from "src/redux/hooks";
import { Message, MessageType } from "src/types/api/ticket";
import ReplyToHtml from "./reply-to-html";
import {
    StyledCard,
    StyledCardBody,
    StyledContentMessage,
    StyledHeader,
    StyledReplyButton,
    StyledSubjectText,
} from "./style";

type Props = {
    message: Message;
};

const EmailMessage: FC<Props> = ({ message }) => {
    const { project, staff } = useAppSelector((store) => ({
        staff: store.authentication.user?.staff,
        project: store.authentication.user?.project,
    }));
    const MAX_CHARACTER_IN_PARAGRAPH = 100;
    const isShortMessage =
        message.message &&
        message.message?.replace(/ /g, "").length < MAX_CHARACTER_IN_PARAGRAPH;
    const history = useHistory();
    const containerRef = useRef<HTMLDivElement>(null);
    const [showFull, setShowFull] = useState(isShortMessage);
    const onReply = () => {
        history.push({
            search: stringify({
                ...parse(history.location.search),
                compose: "email",
                channel: message.channel_id,
                message_id: message.id,
            }),
        });
    };
    const handleOnShowFull = () => {
        if (isShortMessage) {
            return;
        }
        setShowFull(!showFull);
    };

    const renderSenderName = (val: string) => {
        if (val) {
            switch (val) {
                case "&lt;":
                    return "<";
                case "&gt;":
                    return ">";
                case "{Business name}":
                    return project?.name || "";
                case "{Staff email}":
                case "[{Staff email}]":
                    return project?.email || "";
                case "[{Staff first name}]":
                case "{Staff first name}":
                    return staff?.first_name || "";
                case "[{Staff last name}]":
                case "{Staff last name}":
                    return staff?.last_name || "";
                case "{Staff first name} {Staff last name}":
                    return `${staff?.first_name || ""} ${
                        staff?.last_name || ""
                    }`;
                default:
                    return "";
            }
        }

        return "";
    };

    const renderSignature = (signature: string) => {
        if (signature) {
            return signature.replace(
                /\{Business name\}|\{Staff first name\}|\[\{Staff email\}\]|\[\{Staff last name\}\]|\[\{Staff first name\}\]|\{Staff last name\}|&lt;|&gt;|\{Staff first name} {Staff last name\}/g,
                (match) => {
                    return renderSenderName(match);
                }
            );
        }
        return "";
    };

    return (
        <StyledCard
            isOutbound={message.type === MessageType.Outbound}
            onClick={handleOnShowFull}
            showFull={showFull}
        >
            <StyledCardBody
                ref={containerRef}
                isSystemEmail={message.from_system}
                showFull={showFull}
            >
                <StyledHeader
                    isOutbound={message.type === MessageType.Outbound}
                >
                    <div
                        style={{
                            minWidth: "0",
                            flexGrow: 1,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <StyledSubjectText>
                            <Mail
                                style={{
                                    verticalAlign: "middle",
                                    marginRight: "3px",
                                }}
                                width={16}
                                height={16}
                            />{" "}
                            {message.messageable?.subject ||
                                message.subject_temp ||
                                ""}
                        </StyledSubjectText>
                    </div>
                    {message.type !== MessageType.Outbound && (
                        <StyledReplyButton
                            size="xs"
                            color="light"
                            onClick={onReply}
                        >
                            <FaReply />{" "}
                            <Text ml="5px" as="span" fontSize="12px">
                                Reply
                            </Text>
                        </StyledReplyButton>
                    )}
                </StyledHeader>
                {(message.from_system && showFull) || !message.from_system ? (
                    <>
                        <StyledContentMessage
                            $createdByStaff={
                                message.type === MessageType.Outbound &&
                                !message.from_system
                            }
                            className={`email-message-${message.id}`}
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    renderSignature(
                                        message.messageable?.html ||
                                            message.html_temp ||
                                            ""
                                    )
                                ),
                            }}
                        />

                        {message.reply_to && <ReplyToHtml message={message} />}
                    </>
                ) : (
                    ""
                )}

                {message.files.length > 0 && <hr />}
                {message.files.map((file) => {
                    return (
                        <FileView
                            key={file.id}
                            file={file}
                            files={message.files}
                            isMessageable
                            isDarkTheme={message.type === MessageType.Outbound}
                        />
                    );
                })}
            </StyledCardBody>
        </StyledCard>
    );
};

export default EmailMessage;
