import { FC } from "react";
import { MediaBody, Text, Heading } from "@doar/components";
import { useAppSelector } from "src/redux/hooks";
import SmsIcon from "@doar/shared/images/smsicon.svg";
import QuestionIcon from "src/assets/svg/icon_question.svg";
import ConsultRequestIcon from "src/assets/svg/calendar.svg";
import classNames from "classnames";
import GmailIcon from "src/assets/svg/gmail.svg";
import TaskIcon from "@doar/shared/images/task-icon/icon_task.svg";
import ReminderIcon from "src/assets/svg/reminder.svg";
import { Channel } from "src/types/api/channel";
import {
    StyledMedia,
    StyledTop,
    StyledLeftSide,
    StyledText,
    StyledDesc,
} from "./style";

interface IProps {
    message?: string;
    html?: string;
    name?: string;
    type?: string;
    id?: number;
    time?: string;
    title?: string;
    status?: string;
    onClick?: () => void;
    channel?: Channel;
}

const GroupItem: FC<IProps> = ({
    html,
    message,
    name,
    type,
    time,
    id,
    title,
    status,
    onClick,
    channel,
}) => {
    const { messageId } = useAppSelector((state) => ({
        messageId: state.ticket.detail.messageId,
    }));

    const getChannelIcon = (typeIcon: string) => {
        if (typeIcon === "sms" || typeIcon === "App\\Models\\SmsMessage") {
            return channel?.channel_app?.app.image_url || SmsIcon;
        }
        if (
            typeIcon === "email" ||
            typeIcon === "App\\Models\\EmailMessage" ||
            typeIcon === "App\\Models\\Call"
        ) {
            return channel?.channel_app?.app.image_url || GmailIcon;
        }
        if (typeIcon === "question") {
            return QuestionIcon;
        }
        if (typeIcon === "task") {
            return TaskIcon;
        }
        if (typeIcon === "reminder") {
            return ReminderIcon;
        }
        if (typeIcon === "consult_request") {
            return ConsultRequestIcon;
        }
        return channel?.channel_app?.app.image_url;
    };

    const getContentMessage = (
        messageHTML?: string,
        messageText?: string,
        typeMessage?: string
    ) => {
        if (
            typeMessage === "App\\Models\\EmailMessage" ||
            typeMessage === "email"
        ) {
            const div = document.createElement("div");
            div.innerHTML = messageText || "";
            return div.textContent || div.innerText || "";
        }

        if (type === "App\\Models\\SmsMessage" || type === "sms") {
            if (messageText) return messageText;
        }

        const div = document.createElement("div");
        div.innerHTML = messageHTML || "";
        return div.textContent || div.innerText || "";
    };

    return (
        <>
            <StyledMedia
                className={classNames(status, {
                    "is-not-message-choose": messageId !== id,
                })}
            >
                <StyledLeftSide>
                    <img
                        style={{
                            width: "38px",
                            height: "38px",
                        }}
                        src={getChannelIcon(type as string)}
                        alt="channel"
                    />
                </StyledLeftSide>
                <MediaBody>
                    <div
                        onClick={onClick}
                        role="button"
                        tabIndex={0}
                        style={{
                            outline: "none",
                            border: "none",
                            padding: "15px 20px 15px 0",
                        }}
                    >
                        <StyledTop>
                            <StyledText>{name}</StyledText>

                            <Text as="span" fontSize="11px">
                                {time}
                            </Text>
                        </StyledTop>

                        <Heading fontSize="13px">{title}</Heading>

                        <Text
                            fontSize="12px"
                            color="text3"
                            display="flex"
                            className="wrap-text"
                            margin="5px 0"
                        >
                            <StyledDesc>
                                {getContentMessage(html, message, type)}
                            </StyledDesc>
                        </Text>
                    </div>
                </MediaBody>
            </StyledMedia>
        </>
    );
};

GroupItem.defaultProps = {
    status: "read",
};

export default GroupItem;
