import {
    ColorProps,
    LayoutProps,
    SpaceProps,
    TypographyProps,
} from "@doar/shared";
import { ElementType, FC } from "react";
import { Note } from "src/types/api/ticket";
import { Button, Spinner } from "@doar/components";
import { create as markSeen } from "src/redux/slices/ticket/audit";
import {
    StyledMentionDisplay,
    StyledMentionOtherDisplay,
    StyledText,
} from "../../../../../widgets/mentions/styles";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { StyledPopover } from "./style";

interface IProps extends SpaceProps, ColorProps, TypographyProps, LayoutProps {
    as?: ElementType;
    className?: string;
    value: string;
    highlightValue?: string;
    note: Note;
}

export const MentionsHighlightDisplay: FC<IProps> = ({
    as,
    className,
    highlightValue,
    value,
    note,
    ...restProps
}) => {
    const dispatch = useAppDispatch();
    const { status } = useAppSelector((store) => store.ticket.audit);

    const onMarkSeen = () => {
        dispatch(
            markSeen({
                type: "read",
                noteId: note.id,
            })
        );
    };
    const displayValues = [];
    let index = 0;
    const regexp = new RegExp("@\\[(.*?)]\\(\\d+\\)", "g");
    const staff = useAppSelector((state) => state.authentication.user?.staff);

    const staffMention = [staff?.first_name, staff?.last_name].join(".");
    const highlight = highlightValue ?? staffMention;

    let match = regexp.exec(value);
    while (match !== null) {
        displayValues.push(
            <span key={`text-${index}`}>
                {value.substring(index, match.index)}
            </span>
        );

        displayValues.push(
            highlight === `${match[1]}` ? (
                <StyledMentionDisplay key={match.index}>
                    @{match[1]}
                    {!note.audits?.find((n) => n.auditable_id === note.id) ? (
                        <StyledPopover className="popover">
                            {status === "loading" ? (
                                <div className="spinner">
                                    <Spinner size="xs" />
                                </div>
                            ) : (
                                <Button variant="texted" onClick={onMarkSeen}>
                                    Mark Seen
                                </Button>
                            )}
                        </StyledPopover>
                    ) : (
                        <></>
                    )}
                </StyledMentionDisplay>
            ) : (
                <StyledMentionOtherDisplay key={match.index}>
                    @{match[1]}
                </StyledMentionOtherDisplay>
            )
        );
        index = regexp.lastIndex;
        match = regexp.exec(value);
    }
    displayValues.push((value || "").substring(index));
    return (
        <StyledText as={as} className={className} {...restProps}>
            {displayValues}
        </StyledText>
    );
};
