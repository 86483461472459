import { Dropdown, DropdownMenu, DropdownToggle } from "@doar/components";
import { ChangeEvent, FC, ReactElement, useState } from "react";
import { ChevronDown, ChevronUp, X } from "react-feather";
import ThreeDotsLoading from "src/components/three-dots-loading";
import useOnclickOutside from "react-cool-onclickoutside";
import {
    StyledDropdownItem,
    StyledDropdownWrap,
    StyledInputWrapper,
    StyledSearchInput,
    StyledLoading,
    StyledRemoveStaff,
} from "./style";

interface IProps {
    value: string | ReactElement;
    items:
        | {
              id: number;
              name?: string;
              element?: ReactElement;
          }[]
        | [];
    isSearchable?: boolean;
    searchId?: string;
    onChangeSearchValue?: (value: string) => void;
    onChange: (data: { id: number; name: string }) => void;
    disabled?: boolean;
    isLoading?: boolean;
    isDeleteable?: boolean;
    removeOptionIsSelected?: () => void;
}
const CustomDropdown: FC<IProps> = ({
    value,
    items,
    onChange,
    isSearchable,
    searchId,
    onChangeSearchValue,
    isLoading,
    disabled,
    isDeleteable,
    removeOptionIsSelected,
}) => {
    const [show, setShow] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const onSelectOption = (id: number, name: string) => {
        setShow(false);
        onChange({ id, name });
    };

    const onChangeSearch = (
        e: ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        if (setSearchValue) {
            setSearchValue(e.target.value);
        }
        if (onChangeSearchValue) {
            onChangeSearchValue(e.target.value);
        }
    };

    const ref = useOnclickOutside(() => setShow(false));

    return (
        <StyledDropdownWrap ref={ref}>
            <Dropdown
                direction="down"
                isShow={show}
                getState={(state) => setShow(state)}
            >
                <DropdownToggle
                    variant="outlined"
                    className="dropdownToggle"
                    disabled={disabled}
                >
                    <div className="value">{value}</div>
                    <div className="selectionArrow">
                        {isDeleteable && (
                            <StyledRemoveStaff
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (removeOptionIsSelected)
                                        removeOptionIsSelected();
                                }}
                            >
                                <X size={20} />
                            </StyledRemoveStaff>
                        )}
                        {show ? <ChevronUp /> : <ChevronDown />}
                    </div>
                </DropdownToggle>
                <DropdownMenu show={show} className="dropdownMenu">
                    {isSearchable ? (
                        <StyledInputWrapper>
                            <StyledSearchInput
                                id={searchId}
                                name={searchId}
                                placeholder="Search..."
                                value={searchValue}
                                onChange={onChangeSearch}
                            />
                            {isLoading && (
                                <StyledLoading>
                                    <ThreeDotsLoading />
                                </StyledLoading>
                            )}
                        </StyledInputWrapper>
                    ) : (
                        ""
                    )}
                    {items.map((item) => {
                        return (
                            <StyledDropdownItem
                                key={item.id}
                                onClick={() =>
                                    onSelectOption(item.id, item.name || "")
                                }
                            >
                                {item.name ? item.name : item.element}
                            </StyledDropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
        </StyledDropdownWrap>
    );
};

export default CustomDropdown;
