import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CreateLeadForm, UpdateLeadForm } from "src/api/lead/lead";
import { Procedure } from "src/types/api/authentication";
import { Pagination } from "src/types/api/invoice";
import { Lead } from "../../../types/api/ticket";

export interface Source {
    source: string;
    bmi: string;
}

export interface Stage {
    stage: string;
    count: number;
    bmi: string;
}

export interface CheckAllContactsPage {
    index: number;
    checkPage: boolean;
}

export interface ContactLeadsState {
    leads: Lead[] | null;
    lead: Lead | null;
    source: Source[] | null;
    stage: Stage[] | null;
    checkAllContactPage: CheckAllContactsPage | null;
    loading: boolean;
    loadingSource: boolean;
    loadingStage: boolean;
    updateStage: boolean;
    receivingAllLeads: boolean;
    pagination: Pagination;
    updateLoading: boolean;
    filterGroup: {
        [key: string]: string | number;
    };
    listLeadIds: number[];
    loadingDetails: boolean;
    selectedTab: "info" | "activity" | "conversations" | "files" | null;
    paginationGetParams: {
        limit: number;
        page: number;
    };
    servicesInterestsIn: Procedure[] | null;
    loadingLeadServices: boolean;
    leadIdFromUrl: number;
    draggingLeadId: number | null;
    sortBy: string | null;
    sortOrder: string | null;
}

const initialState: ContactLeadsState = {
    leads: null,
    lead: null,
    source: null,
    stage: null,
    loadingStage: false,
    updateStage: false,
    loadingSource: false,
    receivingAllLeads: false,
    checkAllContactPage: null,
    loading: false,
    pagination: {
        total: 0,
        currentPage: 1,
        limit: 25,
        from: 0,
        to: 0,
    },
    updateLoading: false,
    filterGroup: {},
    listLeadIds: [],
    loadingDetails: false,
    selectedTab: null,
    paginationGetParams: {
        limit: 25,
        page: 1,
    },
    servicesInterestsIn: null,
    loadingLeadServices: false,
    leadIdFromUrl: 0,
    draggingLeadId: null,
    sortBy: null,
    sortOrder: null,
};

const detailContactLeadSlice = createSlice({
    name: "contacts/lead",
    initialState,
    reducers: {
        doUpdateSort(
            state,
            action: PayloadAction<{
                sortBy: string;
                sortOrder: string;
            }>
        ) {
            state.sortBy = action.payload.sortBy;
            state.sortOrder = action.payload.sortOrder;
        },
        doGetLeads(
            state,
            action: PayloadAction<{
                page?: number;
                limit?: number;
                keyword?: string;
            }>
        ) {
            state.loading = true;
        },
        doGetLeadsSuccess(
            state,
            action: PayloadAction<{
                leads: Lead[];
                pagination: Pagination;
            }>
        ) {
            state.leads = action.payload.leads;
            state.loading = false;
            state.pagination = action.payload.pagination;
        },
        doGetAllLeads(
            state,
            action: PayloadAction<{
                select_all: boolean;
                select: string;
                onSuccess: (allLeads: Lead[]) => void;
            }>
        ) {
            state.receivingAllLeads = true;
        },
        doGetSourceLeads(state, action: PayloadAction<{ source?: string }>) {
            state.loadingSource = true;
        },
        doGetSourceLeadsSuccess(
            state,
            action: PayloadAction<{ allSource: Source[] }>
        ) {
            state.source = action.payload.allSource;
            state.loadingSource = false;
        },
        doGetLeadStages(state, action: PayloadAction<{ stage?: string }>) {
            state.loadingStage = true;
        },
        doGetLeadStagesSuccess(
            state,
            action: PayloadAction<{ allStage: Stage[] }>
        ) {
            state.stage = action.payload.allStage;
            state.loadingStage = false;
        },
        doGetAllLeadsSuccess(state) {
            state.receivingAllLeads = false;
        },
        doDeleteContacts(
            state,
            action: PayloadAction<{
                ids: number[];
                onSuccess: () => void;
            }>
        ) {
            state.loading = true;
        },
        doDeleteContactsSuccess(state, action: PayloadAction<number[]>) {
            state.loading = false;
            const leads = state.leads as Lead[];
            state.leads = leads.filter((i) => !action.payload.includes(i.id));
        },
        doDeleteContactsFail(state) {
            state.loading = false;
        },
        doDeleteLead(
            state,
            action: PayloadAction<{
                id: number;
                onSuccess: () => void;
                onFail?: (err: string) => void;
            }>
        ) {
            state.loading = true;
        },
        doDeleteLeadSuccess(state, action: PayloadAction<number>) {
            state.loading = false;
            state.leads =
                state.leads?.filter((i) => i.id !== action.payload) || null;
        },
        doGetCheckAllPage(state, action: PayloadAction<CheckAllContactsPage>) {
            state.checkAllContactPage = action.payload;
        },
        doUpdateLead(
            state,
            action: PayloadAction<{
                id: number;
                form: UpdateLeadForm;
                onSuccess?: (id: number) => void;
                onFail?: (err: string) => void;
            }>
        ) {
            state.updateLoading = true;
        },
        doUpdateLeadSuccess(state, action: PayloadAction<Lead>) {
            state.updateLoading = false;
            state.lead = action.payload;
            state.leads = state.leads
                ? state.leads.map((i) =>
                      i.id === action.payload.id ? action.payload : i
                  )
                : null;
        },
        doUpdateLeadFail(state) {
            state.updateLoading = false;
        },
        doCreateLead(
            state,
            action: PayloadAction<{
                form: CreateLeadForm;
                onSuccess?: (id: number) => void;
                onFail?: (err: string) => void;
            }>
        ) {},
        doCreateLeadSuccess(state, action: PayloadAction<Lead>) {
            state.lead = action.payload;
        },
        doSetFilterByField(
            state,
            action: PayloadAction<{
                field:
                    | "created_from"
                    | "created_to"
                    | "in[source]"
                    | "in[procedure_id]"
                    | "is_qualified"
                    | "in[stage]"
                    | "stage"
                    | "in[staff_id]"
                    | "in[location_id]"
                    | "sort[by]"
                    | "sort[order]"
                    | "keyword";
                value: string | number;
            }>
        ) {
            let temp = state.filterGroup;
            temp = {
                ...temp,
                [action.payload.field]: action.payload.value,
            };
            state.filterGroup = temp;
        },
        doRemoveFieldInFilter(
            state,
            action: PayloadAction<
                | "created_from"
                | "created_to"
                | "in[source]"
                | "in[procedure_id]"
                | "is_qualified"
                | "in[stage]"
                | "stage"
                | "in[staff_id]"
                | "in[location_id]"
                | "keyword"
            >
        ) {
            const temp = state.filterGroup;
            delete temp[action.payload];
            state.filterGroup = temp;
        },
        doUpdateLeadIds(
            state,
            action: PayloadAction<{
                arrayLeadId: number[];
            }>
        ) {
            const num = state.listLeadIds.concat(action.payload.arrayLeadId);
            let result: number[] = [];
            result = num.filter((element) => {
                return result.includes(element) ? "" : result.push(element);
            });
            state.listLeadIds = result;
        },
        doDeleteLeadIds(state, action: PayloadAction<{ id: number }>) {
            state.listLeadIds = state.listLeadIds.filter(
                (i) => i !== action.payload.id
            );
        },
        doDeleteLeadIdsArray(
            state,
            action: PayloadAction<{ arrayId: number[] }>
        ) {
            // get listLeadId not includes arrayId
            const listLeadIds = state.listLeadIds.filter(
                (i) => !action.payload.arrayId.includes(i)
            );
            state.listLeadIds = listLeadIds;
        },
        doDeleteAllLeadIds(state) {
            state.listLeadIds = [];
        },
        doSetSelectedTab(
            state,
            action: PayloadAction<
                "info" | "activity" | "conversations" | "files" | null
            >
        ) {
            state.selectedTab = action.payload;
        },
        doSetLead(
            state,
            action: PayloadAction<{
                lead: Lead | null;
                loading: boolean;
            }>
        ) {
            state.lead = action.payload.lead;
            state.loadingDetails = action.payload.loading;
        },
        doSetPaginationGetParams(
            state,
            action: PayloadAction<{
                limit: number;
                page: number;
            }>
        ) {
            const { limit, page } = action.payload;
            state.paginationGetParams = {
                limit,
                page,
            };
        },
        doSetLeads(
            state,
            action: PayloadAction<{
                leads: Lead[] | null;
                loading: boolean;
                pagination: Pagination;
            }>
        ) {
            state.leads = action.payload.leads;
            state.loading = action.payload.loading;
            state.pagination = action.payload.pagination;
        },

        doSetLeadIdFromUrl(state, action: PayloadAction<number>) {
            state.leadIdFromUrl = action.payload;
        },
        doSetLeadProcedures(
            state,
            action: PayloadAction<{
                procedures: Procedure[] | null;
                loading: boolean;
            }>
        ) {
            state.loadingLeadServices = action.payload.loading;
            state.servicesInterestsIn = action.payload.procedures;
        },
        doUpdateLeadStage(
            state,
            action: PayloadAction<{
                id: number;
                newStage: string;
                oldStage: string;
                onFail?: (err: string) => void;
            }>
        ) {
            state.updateStage = true;
            const { oldStage, newStage, id } = action.payload;
            if (newStage !== oldStage && state.filterGroup.stage) {
                state.leads = state.leads?.filter((i) => i.id !== id) || [];
            }
        },
        doUpdateLeadStageSuccess(
            state,
            action: PayloadAction<{
                id: number;
                newStage: string;
                oldStage: string;
            }>
        ) {
            state.updateStage = false;
            const { oldStage, newStage, id } = action.payload;
            if (state.stage) {
                let stage: Stage[] = [...state.stage];
                stage = stage.map((i) =>
                    i.stage === oldStage ? { ...i, count: i.count - 1 } : i
                );

                if (!stage.find((i) => i.stage === newStage)) {
                    stage.push({
                        stage: newStage,
                        count: 1,
                        bmi: "",
                    });
                } else
                    stage = stage.map((i) =>
                        i.stage === newStage ? { ...i, count: i.count + 1 } : i
                    );
                state.stage = stage;
            }

            state.leads =
                state.leads?.map((i) =>
                    i.id === id ? { ...i, stage: newStage } : i
                ) || [];

            if (state.filterGroup.stage) {
                const stages = state.filterGroup.stage as string;
                state.leads =
                    state.leads?.filter((i) =>
                        stages.split(",").includes(i.stage)
                    ) || [];
            }
        },
        doUpdateLeadStageFail(state) {
            state.updateStage = false;
        },
        doSetDraggingLeadId(state, action: PayloadAction<number | null>) {
            state.draggingLeadId = action.payload;
        },
    },
});

export const {
    doUpdateSort,
    doGetLeads,
    doGetLeadsSuccess,
    doDeleteContacts,
    doDeleteContactsSuccess,
    doDeleteContactsFail,
    doDeleteLead,
    doDeleteLeadSuccess,
    doGetAllLeads,
    doGetAllLeadsSuccess,
    doGetSourceLeads,
    doGetSourceLeadsSuccess,
    doGetLeadStages,
    doGetLeadStagesSuccess,
    doGetCheckAllPage,
    doUpdateLead,
    doUpdateLeadFail,
    doUpdateLeadSuccess,
    doCreateLead,
    doCreateLeadSuccess,
    doSetFilterByField,
    doRemoveFieldInFilter,
    doUpdateLeadIds,
    doDeleteLeadIds,
    doDeleteLeadIdsArray,
    doDeleteAllLeadIds,
    doSetSelectedTab,
    doSetLead,
    doSetPaginationGetParams,
    doSetLeads,
    doSetLeadIdFromUrl,
    doSetLeadProcedures,
    doUpdateLeadStage,
    doUpdateLeadStageFail,
    doUpdateLeadStageSuccess,
    doSetDraggingLeadId,
} = detailContactLeadSlice.actions;

export default detailContactLeadSlice.reducer;
