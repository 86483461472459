import {
    Avatar,
    AvatarInitial,
    Button,
    CardBody,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Spinner,
} from "@doar/components";
import moment from "moment";
import { FC, KeyboardEvent, useEffect, useState } from "react";
import { MoreVertical } from "react-feather";
import FileView from "src/components/file-view";
import useOnclickOutside from "react-cool-onclickoutside";
import ReactTooltip from "react-tooltip";
import { useAppDispatch } from "../../../../../../redux/hooks";
import {
    doDeleteNote,
    update,
} from "../../../../../../redux/slices/ticket/note";
import {
    StyledCard,
    StyledCardContent,
    StyledCardFooter,
    StyledDropdownItem,
    StyledEditableWrap,
    StyledLeftFooter,
    StyledControl,
    StyledNoteWrap,
    StyledTime,
    StyledMentionWrap,
} from "./style";
import { getStaffsApi } from "../../../../../../api/staff/staff";
import Mentions from "../../../../../widgets/mentions/Mentions";
import { Note } from "../../../../../../types/api/ticket";
import { MentionsHighlightDisplay } from "./mention-display";

interface Props {
    note: Note;
}

const NoteItem: FC<Props> = ({ note }) => {
    const [editable, setEditable] = useState(false);
    const [value, setValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [showEditable, setShowEditable] = useState(false);
    const [data, setData] = useState([] as { id: number; display: string }[]);

    const fetchStaffApi = () => {
        getStaffsApi(null)
            .then((r) => {
                setData(
                    r.data.map((staff) => {
                        return {
                            id: staff.id,
                            display: [staff.first_name, staff.last_name].join(
                                " "
                            ),
                        };
                    })
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const dispatch = useAppDispatch();

    const onSubmit = () => {
        if (value.trim() !== "" && !loading) {
            setLoading(true);
            dispatch(
                update({
                    id: note.id,
                    note: value.trim(),
                    onSuccess: () => {
                        setEditable(false);
                        setLoading(false);
                    },
                    onFail: () => {
                        setLoading(false);
                    },
                })
            );
        }
    };

    const onChange = (e: { target: { value: string } }) => {
        setValue(e.target.value);
    };

    useEffect(() => {
        if (note) {
            setValue(note.note || "");
        }
    }, [note]);

    const onKeyDown = (
        e: KeyboardEvent<HTMLTextAreaElement> | KeyboardEvent<HTMLInputElement>
    ) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            onSubmit();
        }
        if (e.key === "Escape") {
            setEditable(false);
        }
    };

    const editableRef = useOnclickOutside(() => setEditable(false));

    return (
        <StyledCard mb="25px">
            <StyledCardContent>
                <CardBody p="20px">
                    <StyledNoteWrap ref={editableRef}>
                        {editable ? (
                            <StyledEditableWrap>
                                <StyledMentionWrap>
                                    <Mentions
                                        value={value}
                                        onChange={onChange}
                                        onKeyDown={onKeyDown}
                                        trigger="@"
                                        data={data}
                                        disabled={loading}
                                    />
                                </StyledMentionWrap>

                                <Button
                                    onClick={onSubmit}
                                    mt="10px"
                                    variant="contained"
                                    disabled={loading}
                                    width="60px"
                                >
                                    {loading ? (
                                        <div className="loading">
                                            <Spinner size="xs" color="white" />
                                        </div>
                                    ) : (
                                        "Save"
                                    )}
                                </Button>
                            </StyledEditableWrap>
                        ) : (
                            <MentionsHighlightDisplay
                                mb="0"
                                value={value}
                                note={note}
                            />
                        )}
                        <StyledControl>
                            <Dropdown
                                direction="left"
                                isShow={showEditable}
                                getState={(state) => setShowEditable(state)}
                            >
                                <DropdownToggle variant="texted">
                                    <MoreVertical width={18} height={18} />
                                </DropdownToggle>
                                <DropdownMenu>
                                    {!editable ? (
                                        <>
                                            <StyledDropdownItem
                                                onClick={() => {
                                                    setEditable(true);
                                                    setShowEditable(false);
                                                    fetchStaffApi();
                                                }}
                                            >
                                                Edit
                                            </StyledDropdownItem>
                                            <StyledDropdownItem
                                                onClick={() =>
                                                    dispatch(
                                                        doDeleteNote({
                                                            id: note.id,
                                                        })
                                                    )
                                                }
                                            >
                                                Delete
                                            </StyledDropdownItem>
                                        </>
                                    ) : (
                                        <StyledDropdownItem
                                            onClick={() => {
                                                setEditable(false);
                                                setShowEditable(false);
                                            }}
                                        >
                                            Cancel
                                        </StyledDropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </StyledControl>
                    </StyledNoteWrap>
                    <div>
                        {(note.note || editable) &&
                            note.files &&
                            note.files.length > 0 && <hr />}
                        {note.files && note.files.length > 0 && (
                            <>
                                {note.files.map((file) => {
                                    return (
                                        <FileView
                                            key={file.id}
                                            file={file}
                                            files={note.files || []}
                                        />
                                    );
                                })}
                            </>
                        )}
                    </div>
                </CardBody>
            </StyledCardContent>
            <StyledCardFooter>
                <StyledTime>
                    {moment(note.created_at).format("MM/DD/YYYY")}
                </StyledTime>
                <StyledLeftFooter>
                    <ReactTooltip
                        place="left"
                        effect="solid"
                        id="note-staff-name"
                        className="tooltip"
                    />
                    <Avatar
                        shape="circle"
                        size="xs"
                        data-tip={`${note.staff.first_name} ${note.staff.last_name}`}
                        data-for="note-staff-name"
                    >
                        {note.staff.photo ? (
                            <img src={note.staff.photo} alt="avatar" />
                        ) : (
                            <AvatarInitial>
                                {note.staff.first_name
                                    ? note.staff.first_name[0]
                                    : ""}
                                {note.staff.last_name
                                    ? note.staff.last_name[0]
                                    : ""}
                            </AvatarInitial>
                        )}
                    </Avatar>
                </StyledLeftFooter>
            </StyledCardFooter>
        </StyledCard>
    );
};

export default NoteItem;
