import { Button, Spinner } from "@doar/components";
import { FC, useState } from "react";
import { useAppDispatch } from "src/redux/hooks";
import * as yup from "yup";
import { Edit } from "react-feather";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Lead } from "src/types/api/ticket";
import { doUpdateLead } from "src/redux/slices/contacts/lead";
import PhoneCountriesInput from "src/components/phone-countries-input";
import { StyledInputWrapper, StyledWrapper } from "./style";

interface IProps {
    lead: Lead | null | undefined;
    onClose: () => void;
    onAddPhoneSuccess?: (leadId?: number) => void;
    onAddPhoneFail?: () => void;
}

interface IFormValues {
    phone: string;
}

const schema = yup.object().shape({
    phone: yup.string().required().trim().label("Phone number"),
});

const AddNewPhoneContainer: FC<IProps> = ({
    lead,
    onClose,
    onAddPhoneSuccess,
    onAddPhoneFail,
}) => {
    const { setValue, getValues, handleSubmit } = useForm<IFormValues>({
        resolver: yupResolver(schema),
    });
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleUpdatePhone = (data: IFormValues) => {
        setLoading(true);
        if (lead) {
            dispatch(
                doUpdateLead({
                    id: lead.id,
                    form: {
                        phone: data.phone,
                    },
                    onSuccess: () => {
                        setLoading(false);
                        onClose();
                        setError("");
                        if (onAddPhoneSuccess) {
                            onAddPhoneSuccess(lead.id);
                        }
                    },
                    onFail: (err) => {
                        setLoading(false);
                        setError(err);
                        if (onAddPhoneFail) {
                            onAddPhoneFail();
                        }
                    },
                })
            );
        }
    };

    return (
        <StyledWrapper>
            <Button type="submit" variant="texted">
                Add missing phone number
            </Button>
            <form
                onSubmit={handleSubmit(handleUpdatePhone)}
                style={{ display: "flex" }}
            >
                <StyledInputWrapper $hasError={!!error}>
                    <PhoneCountriesInput
                        value={getValues("phone")}
                        id="phone"
                        onChange={(number) => {
                            setValue("phone", number);
                        }}
                        errorMessage={error}
                        loading={loading}
                    />
                </StyledInputWrapper>
                <div style={{ padding: "0.5em 0.5em 0.5em 0" }}>
                    <Button
                        color="primary"
                        type="submit"
                        disabled={loading}
                        height={38}
                        width={38}
                        padding="0 0.5rem !important"
                        margin="0 !important"
                    >
                        {loading ? (
                            <Spinner size="xs" color="white" />
                        ) : (
                            <Edit size={20} color="white" />
                        )}
                    </Button>
                </div>
            </form>
        </StyledWrapper>
    );
};

export default AddNewPhoneContainer;
