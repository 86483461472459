/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetNotification } from "src/api/notification/notification";

export interface UIState {
    sidebar: boolean;
    alert: GetNotification[] | null;
    isBody: boolean;
    isShowBanner: boolean;
}

const initialState: UIState = {
    sidebar: false,
    alert: null,
    isBody: false,
    isShowBanner: false,
};

const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        toggleSidebar(state, action: PayloadAction<{ isOpen?: "open" }>) {
            const {
                payload: { isOpen },
            } = action;
            if (isOpen) {
                state.sidebar = false;
            } else {
                state.sidebar = !state.sidebar;
            }
        },
        toggleBody(state) {
            state.isBody = !state.isBody;
        },
        showNotification(state, action: PayloadAction<GetNotification[] | null>) {
            state.alert = action.payload;
        },
        showBanner(state, action: PayloadAction<{isShow: boolean}>) {
            state.isShowBanner = action.payload.isShow;
        }
    },
});

export const { toggleSidebar, toggleBody, showNotification, showBanner } = uiSlice.actions;
export default uiSlice.reducer;
