/* eslint-disable react/no-danger */
import { Avatar, AvatarInitial } from "@doar/components";
import classNames from "classnames";
import moment from "moment";
import { FC, useMemo, useRef } from "react";
import { Settings } from "react-feather";
import { useHistory } from "react-router-dom";
import { generateLeadImageColor } from "src/helpers/generateLeadImageColor";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { doSetSelectedTab } from "src/redux/slices/contacts/lead";
import {
    Message,
    MessageableTypeEnum,
    MessageType,
    Ticket,
} from "src/types/api/ticket";
import { Lead } from "../../../../../types/api/ticket";
import Appointment from "./appointment/appointment";
import EmailMessage from "./email-message";
import Estimate from "./estimate/estimate";
import FileMessage from "./file-message";
import Qualification from "./qualification/qualification";
import Question from "./question/question";
import SmsMessage from "./sms-message";
import CallMessage from "./call-message";
import {
    StyledAvatarWrapper,
    StyledItemBody,
    StyledItemContent,
    StyledMedia,
    StyledTimeHeader,
    StyledTitle,
    StyledCard,
} from "./style";

interface IProps {
    message: Message;
    lead: Lead;
    ticket?: Ticket;
    hidden?: boolean;
}

const Item: FC<IProps> = ({ message, lead, ticket, hidden }) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { messageId } = useAppSelector((store) => ({
        messageId: store.ticket.detail.messageId,
    }));
    const ref = useRef<HTMLDivElement>(null);

    const senderName = message.sender
        ? [message.sender.first_name, message.sender.last_name].join(" ")
        : [lead.first_name, lead.last_name].join(" ");

    const timeCreatedMessage = (
        <StyledTimeHeader>
            {moment(message.created_at).format("hh:mm A")}
        </StyledTimeHeader>
    );

    const renderContent = () => {
        if (
            message.messageable_type ===
            MessageableTypeEnum.AppModelsEventMessage
        ) {
            return <Appointment appointment={message.messageable} />;
        }
        if (message.messageable?.estimate) {
            return <Estimate estimate={message.messageable.estimate} />;
        }
        if (message.messageable?.question) {
            return <Question message={message} />;
        }
        if (message.messageable?.qualification) {
            return <Qualification message={message} />;
        }
        if (
            message.messageable_type ===
            MessageableTypeEnum.AppModelsEmailMessage
        ) {
            return <EmailMessage message={message} />;
        }
        if (
            message.messageable_type === MessageableTypeEnum.AppModelsSmsMessage
        ) {
            return <SmsMessage message={message} />;
        }
        if (
            message.messageable_type ===
            MessageableTypeEnum.AppModelsFileUploadedMessage
        ) {
            return (
                <StyledCard>
                    <FileMessage message={message} lead={lead} />
                </StyledCard>
            );
        }
        if (
            message.messageable_type ===
            MessageableTypeEnum.AppModelsCallMessage
        ) {
            return <CallMessage message={message} />;
        }
        return <></>;
    };

    const renderHeader = () => {
        if (
            message.messageable_type ===
            MessageableTypeEnum.AppModelsEventMessage
        ) {
            return (
                <>
                    {senderName} has requested a appointment{" "}
                    {timeCreatedMessage}
                </>
            );
        }
        if (message.messageable?.estimate) {
            return (
                <>
                    {senderName} received an estimate {timeCreatedMessage}
                </>
            );
        }
        if (message.messageable?.question) {
            return (
                <>
                    {senderName} asked a question
                    {timeCreatedMessage}
                </>
            );
        }
        if (message.messageable?.qualification) {
            return (
                <>
                    {senderName} submitted qualification form
                    {timeCreatedMessage}
                </>
            );
        }

        if (
            message.messageable_type ===
            MessageableTypeEnum.AppModelsEmailMessage
        ) {
            if (message.type === MessageType.Inbound)
                return (
                    <>
                        inbound email from {senderName}
                        {timeCreatedMessage}
                    </>
                );
            if (message.type === MessageType.Outbound && message.staff) {
                return (
                    <>
                        {[
                            message.staff.first_name,
                            message.staff.last_name,
                        ].join(" ")}{" "}
                        sent an email
                        {timeCreatedMessage}
                    </>
                );
            }

            return (
                <>
                    Email sent by system
                    {timeCreatedMessage}
                </>
            );
        }
        if (
            message.messageable_type === MessageableTypeEnum.AppModelsSmsMessage
        ) {
            if (message.type === MessageType.Inbound)
                return (
                    <>
                        inbound sms from {senderName}
                        {timeCreatedMessage}
                    </>
                );
            if (message.type === MessageType.Outbound && message.staff) {
                return (
                    <>
                        {[
                            message.staff.first_name,
                            message.staff.last_name,
                        ].join(" ")}{" "}
                        sent an sms
                        {timeCreatedMessage}
                    </>
                );
            }

            return (
                <>
                    Sms sent by system
                    {timeCreatedMessage}
                </>
            );
        }

        if (
            message.messageable_type ===
            MessageableTypeEnum.AppModelsFileUploadedMessage
        ) {
            return (
                <>
                    {senderName} uploaded a file
                    {timeCreatedMessage}
                </>
            );
        }
        if (
            message.messageable_type ===
            MessageableTypeEnum.AppModelsCallMessage
        ) {
            if (message.type === MessageType.Inbound) {
                return (
                    <>
                        {senderName}
                        {timeCreatedMessage}
                    </>
                );
            }
            if (message.type === MessageType.Outbound && message.staff) {
                return (
                    <>
                        Call by{" "}
                        {[
                            message.staff.first_name,
                            message.staff.last_name,
                        ].join(" ")}{" "}
                        {timeCreatedMessage}
                    </>
                );
            }
            return (
                <>
                    Call by system
                    {timeCreatedMessage}
                </>
            );
        }
        return <></>;
    };

    const avatar = useMemo(() => {
        if (message.from_system) {
            return (
                <AvatarInitial bg="dark">
                    <Settings />
                </AvatarInitial>
            );
        }
        if (message.staff?.photo) {
            return <img src={message.staff.photo} alt="" />;
        }

        if (message.staff) {
            return (
                <AvatarInitial bg={generateLeadImageColor(message.staff?.id)}>
                    {[message.staff?.first_name, message.staff?.last_name]
                        .join(" ")
                        .substring(0, 1)}
                </AvatarInitial>
            );
        }

        return <></>;
    }, [message.from_system, message.staff]);

    const onClickAvatar = (messageType: string) => {
        if (messageType !== MessageType.Outbound) {
            history.push(`/leads/${lead.id}`);
            dispatch(doSetSelectedTab("activity"));
        }
    };

    return (
        <StyledMedia
            isRightSide={message.type !== MessageType.Outbound}
            className={classNames({
                "ticket-select":
                    ticket?.message_id === message.id &&
                    history.location.pathname.includes("tickets"),
                "message-select":
                    messageId === message.id &&
                    history.location.pathname.includes("messages"),
                hidden,
            })}
        >
            <StyledAvatarWrapper onClick={() => onClickAvatar(message.type)}>
                <Avatar size="sm">
                    {message.type === MessageType.Outbound ? (
                        avatar
                    ) : (
                        <AvatarInitial bg={generateLeadImageColor(lead.id)}>
                            {[lead.first_name, lead.last_name]
                                .join(" ")
                                .substring(0, 1)}
                        </AvatarInitial>
                    )}
                </Avatar>
            </StyledAvatarWrapper>
            <StyledItemBody isRightSide={message.type !== MessageType.Outbound}>
                <StyledTitle
                    isRightSide={message.type !== MessageType.Outbound}
                    onClick={() => {
                        history.push(`/leads/${lead.id}`);
                        dispatch(doSetSelectedTab("activity"));
                    }}
                >
                    {renderHeader()}
                </StyledTitle>
                <StyledItemContent ref={ref}>
                    {renderContent()}
                </StyledItemContent>
            </StyledItemBody>
        </StyledMedia>
    );
};

export default Item;
