import { Card } from "@doar/components";
import styled from "@doar/shared";
import { themeGet, css } from "@doar/shared/styled";

export const StyledCard = styled(({ ...rest }) => <Card {...rest} />)`
    box-shadow: none;
    max-width: 400px;
    position: relative;
    border-radius: 5px;
    cursor: pointer;
    min-width: 200px;
    width: fit-content;
    margin-bottom: 20px;
    .card-body {
        width: fit-content;
    }
    ${(props) => {
        if (props.isOutbound) {
            return css`
                margin-left: auto;
                background-color: ${themeGet("colors.primary")};
                border-top-right-radius: 0;
                color: #fff;
                &:before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    z-index: 1;
                    border-color: ${themeGet("colors.primary")} transparent
                        transparent transparent;
                    position: absolute;
                    top: -1px;
                    border-width: 13px 16px 0 0;
                    right: -16px;
                }
                &:after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-color: ${themeGet("colors.border")} transparent
                        transparent transparent;
                    position: absolute;
                    top: -1px;
                    border-width: 15px 18px 0 0;
                    right: -18px;
                }
            `;
        }
        return css`
            border-top-left-radius: 0;
            &:before {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                z-index: 1;
                border-width: 0 13px 8px 0;
                border-color: transparent #fff transparent transparent;
                position: absolute;
                left: -13px;
                top: 0px;
            }
            &:after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 15px 10px 0;
                border-color: transparent ${themeGet("colors.border")}
                    transparent transparent;
                position: absolute;
                left: -15px;
                top: -1px;
            }
        `;
    }}
`;

export const StyledStatusWrap = styled.div`
    text-align: end;
    position: absolute;
    top: 100%;
    right: 0;
`;

export const StyledStatus = styled.div`
    text-align: end;
    font-size: 13px;
    margin-top: 5px;
    position: relative;
    color: ${themeGet("colors.gray600")};
    width: fit-content;
`;

export const StyledStatusContainer = styled.div`
    display: flex;
    align-items: start;
`;

export const StyledFailedMessage = styled.div`
    color: ${themeGet("colors.danger")};
    margin-right: 5px;
`;
