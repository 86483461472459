import { FC, Fragment } from "react";
import SkeletonLoading from "src/components/skeleton-loading";
import { StyledAvt, StyledInfo, StyledLoadingWrapper } from "./style";

const Loading: FC = () => {
    return (
        <StyledLoadingWrapper>
            {new Array(10).fill(Math.random()).map((i, index) => (
                <Fragment key={`${i}-${String(index)}`}>
                    <SkeletonLoading height="60px" />
                    <StyledInfo>
                        <SkeletonLoading height="15px" width="100px" />
                        <StyledAvt>
                            <SkeletonLoading
                                height="40px"
                                width="40px"
                                circle
                            />
                            <SkeletonLoading
                                height="20px"
                                width="100px"
                                style={{ marginLeft: "10px" }}
                            />
                        </StyledAvt>
                    </StyledInfo>
                </Fragment>
            ))}
        </StyledLoadingWrapper>
    );
};

export default Loading;
