import { FC } from "react";
import loginImage from "@doar/shared/images/logo/logo.png";
import { StyledLogo } from "./style";

const Logo: FC = () => {
    return (
        <StyledLogo path="/">
            <>
                <img
                    src={loginImage}
                    alt="Logo"
                    height="25px"
                    style={{ minWidth: "162px" }}
                />
            </>
        </StyledLogo>
    );
};

export default Logo;
