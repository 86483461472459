import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "@doar/components";
import { FC, useMemo, useState } from "react";
import { Filter } from "react-feather";
import { Mention } from "src/types/api/ticket";
import { StyledBadge, StyledFilterIcon, StyledFilterWrap } from "./style";

export type StateProps = "Read" | "Unread";

interface IProps {
    selected: StateProps;
    mentions: Mention[];
    onChange: (state: StateProps) => void;
}
export const mentionIsRead = (mention: Mention): boolean =>
    !!mention.note.audits.find((i) => i.auditable_id === mention.note_id);

const MentionFilter: FC<IProps> = ({ selected, mentions, onChange }) => {
    const [show, setShow] = useState(false);
    const readCount = useMemo(() => {
        return mentions?.filter((i) => mentionIsRead(i))?.length || 0;
    }, [mentions]);

    const unReadCount = useMemo(() => {
        return mentions?.filter((i) => !mentionIsRead(i))?.length || 0;
    }, [mentions]);

    const onSelectItem = (item: StateProps) => {
        onChange(item);
        setShow(false);
    };

    return (
        <StyledFilterWrap>
            <Dropdown
                direction="down"
                getState={(state) => setShow(state)}
                isShow={show}
            >
                <DropdownToggle
                    color="light"
                    shape="rounded"
                    size="sm"
                    variant="outlined"
                    height={28}
                >
                    <StyledFilterIcon>
                        <Filter size={18} />
                    </StyledFilterIcon>
                    {selected}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem
                        path="#"
                        onClick={() => onSelectItem("Unread")}
                    >
                        Unread
                        <StyledBadge>{unReadCount}</StyledBadge>
                    </DropdownItem>
                    <DropdownItem path="#" onClick={() => onSelectItem("Read")}>
                        Read
                        <StyledBadge>{readCount}</StyledBadge>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </StyledFilterWrap>
    );
};

export default MentionFilter;
