import styled, { themeGet } from "@doar/shared";

export const StyledItem = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid ${themeGet("colors.border")};
`;

export const StyledInfo = styled.div`
    width: 100%;
`;
