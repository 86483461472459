import styled, { themeGet } from "@doar/shared/styled";

export const StyledPreviewImgWrap = styled.div`
    position: relative;
    width: fit-content;
    margin-right: 5px;
    margin-top: 5px;
    cursor: pointer;
    &:hover {
        .remove-btn {
            display: flex;
        }
    }
`;

export const StyledPreviewImage = styled.img`
    object-fit: cover;
    min-width: 60px;
    width: 60px;
    height: 60px;
    min-height: 60px;
    border-radius: 5px;
`;

export const StyledRemoveFile = styled.div`
    margin-left: auto;
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: absolute;
    top: -7px;
    right: -8px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background: ${themeGet("colors.gray700")};
    div {
        height: 12px;
    }
    cursor: pointer;
    display: none;
    &:hover {
        background: ${themeGet("colors.gray900")};
    }
`;
