import styled, { themeGet, css } from "@doar/shared/styled";

export const StyledWrap = styled.div<{ $screenHeight: number }>`
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    position: absolute;
    left: 0;
    height: 320px;
    ${({ $screenHeight }) =>
        $screenHeight &&
        css`
            top: calc((${$screenHeight}px - 120px - 320px) / 2);
        `}
    img {
        width: 50%;
        max-width: 300px;
    }
`;

export const StyledTitle = styled.span`
    color: ${themeGet("colors.text3")};
    font-weight: 600;
    margin-top: 14px;
    font-size: 16px;
`;

export const StyledDescription = styled.span`
    margin-top: 4px;
    color: ${themeGet("colors.text3")};
    font-size: 14px;
`;
