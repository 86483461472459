import { Text, Button } from "@doar/components";
import { FC } from "react";
import { StyledModal, StyledWrapButtons } from "./style";

interface IProps {
    show: boolean;
    message: string;
    onClose: () => void;
    onExit: () => void;
}

const ConfirmBox: FC<IProps> = ({ show, message, onClose, onExit }) => {
    return (
        <>
            <StyledModal show={show} size="sm">
                <Text>
                    <b>{message}</b>
                </Text>
                <StyledWrapButtons>
                    <Button
                        color="white"
                        onClick={onClose}
                        className="cancelBtn"
                    >
                        Cancel
                    </Button>
                    <Button color="primary" onClick={onExit}>
                        Exit
                    </Button>
                </StyledWrapButtons>
            </StyledModal>
        </>
    );
};

export default ConfirmBox;
