import styled, { themeGet, css } from "@doar/shared/styled";

export const StyledToggleActivity = styled.div`
    position: absolute;
    top: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 13px;
    background: #fff;
    z-index: 10;
    border-radius: 30px;
    width: fit-content;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    cursor: pointer;
    left: 50%;
    transform: translate(-50%, 0px);
    &:hover {
        background: ${themeGet("colors.gray100")};
    }
    .arrow {
        height: 15px;
        margin-left: 5px;
    }
`;

export const StyledDropdownItem = styled.div`
    display: flex;
    align-items: center;
    .staffName {
        margin-left: 10px;
    }
    width: 100%;
    padding: 6px 15px;
    clear: both;
    font-weight: 400;
    color: #1c273c;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: ${themeGet("colors.gray200")};
    }
`;

export const StyledDropdownMenu = styled.div<{ $show: boolean }>`
    position: absolute;
    top: 100%;
    right: 0;
    will-change: transform;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    font-size: 0.875rem;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid ${themeGet("colors.border")};
    border-radius: 0.25rem;
    box-shadow: 0 0 8px 2px rgb(28 39 60 / 4%);
    padding: 5px;
    ${({ $show }) =>
        $show &&
        css`
            display: block;
        `}
`;
