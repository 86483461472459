import { updateApiIntegration } from "src/api/integration/integration";
import { all, call, takeLatest } from "typed-redux-saga";
import { doUpdateIntegration } from "../../../slices/settings/channels/integration";

function* updateIntegrationGoogleAdsSaga(
    action: ReturnType<typeof doUpdateIntegration>
) {
    const { id, data, onSuccess, onFail } = action.payload;
    try {
        const response = yield* call(updateApiIntegration, id, data);
        if (onSuccess) {
            yield* call(onSuccess, response);
        }
    } catch (error) {
        const errors = JSON.parse(JSON.stringify(error));
        if (onFail && errors.status !== 500) {
            yield* call(onFail, errors?.data?.message);
        } else if (onFail)
            yield* call(onFail, "Integration Google ADS updated fail");
    }
}

export function* integrationGoogleAdsSaga(): Generator {
    yield all([
        takeLatest(doUpdateIntegration, updateIntegrationGoogleAdsSaga),
    ]);
}
