import { FC, useEffect, useState } from "react";
import { X } from "react-feather";
import { generateLeadImageColor } from "src/helpers/generateLeadImageColor";
import usePressEscape from "src/hooks/usePressEscape";
import { isImageFile, renderFileType } from "src/helpers/fileHelpers";
import {
    StyledWrap,
    StyledModalBody,
    StyledModal,
    StyledModalHeader,
    StyledModalClosed,
    StyledWrapIcon,
    StyledPreviewSelectImage,
    StyledSelectFileThumb,
} from "./style";
import Sender from "../../ticket/sender";

interface IProps {
    show: boolean;
    onClose: () => void;
    src: string;
    type: string;
    name: string;
}

const ModalViewSelectFile: FC<IProps> = ({
    show,
    onClose,
    src,
    type,
    name,
}) => {
    usePressEscape(onClose);
    const [fileName, setFileName] = useState("");
    useEffect(() => {
        if (type && src && name && show) {
            setFileName(name);
        }
    }, [type, src, name, show]);

    return (
        <StyledModal show={show} onClose={onClose}>
            <StyledModalHeader>
                <Sender
                    color={generateLeadImageColor(0)}
                    name={fileName}
                    className="modalView"
                />
                <StyledWrapIcon>
                    <StyledModalClosed onClose={onClose}>
                        <X size={25} />
                    </StyledModalClosed>
                </StyledWrapIcon>
            </StyledModalHeader>
            <StyledModalBody>
                <StyledWrap>
                    {isImageFile(name) ? (
                        <StyledPreviewSelectImage src={src} />
                    ) : (
                        <StyledSelectFileThumb
                            color={renderFileType(name).color}
                        >
                            <i
                                className={`far fa-file-${
                                    renderFileType(name).type
                                }`}
                            />
                        </StyledSelectFileThumb>
                    )}
                </StyledWrap>
            </StyledModalBody>
        </StyledModal>
    );
};

export default ModalViewSelectFile;
