import React, { useEffect, useRef, useState, FC, ChangeEvent } from "react";
import WaveSurfer from "wavesurfer.js";
import { Pause, Play, Volume2 } from "react-feather";
import SkeletonLoading from "src/components/skeleton-loading";
import { classic } from "@doar/shared/styled/colors";
import { SkeletonTheme } from "react-loading-skeleton";
import useOnclickOutside from "react-cool-onclickoutside";
import {
    StyledButton,
    StyledWrapper,
    StyledInput,
    StyledWaveWrapper,
    StyledWave,
    StyledContentWrapper,
    StyledLoading,
} from "./style";

type IProps = {
    url: string;
    isDarkTheme?: boolean;
};
const Recording: FC<IProps> = ({ url, isDarkTheme }) => {
    const waveformRef = useRef(null);
    const waveSurfer = useRef<WaveSurfer>();
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0);
    const [showVolumeRange, setShowVolumeRange] = useState(false);
    const [loading, setLoading] = useState(false);

    const volumeRef = useOnclickOutside(() => setShowVolumeRange(false));

    useEffect(() => {
        setPlaying(false);
        setLoading(true);
        waveSurfer.current = WaveSurfer.create({
            container: waveformRef?.current || "",
            waveColor: isDarkTheme ? "#A2ACB8" : classic.gray300,
            progressColor: isDarkTheme ? "white" : classic.text2,
            cursorColor: "#265470",
            barWidth: 3,
            barRadius: 3,
            responsive: true,
            height: 30,
            normalize: true,
            partialRender: true,
            mediaControls: true,
        });
        waveSurfer.current.load(url);
        waveSurfer.current.on("ready", () => {
            if (waveSurfer.current) {
                waveSurfer.current.drawer.container.style.display = "";
                waveSurfer.current.drawBuffer();
                waveSurfer.current.setVolume(0.5);
                setVolume(0.5);
                setLoading(false);
            }
        });
        waveSurfer.current.on("finish", () => {
            setPlaying(false);
        });
        return () => waveSurfer.current?.destroy();
    }, [isDarkTheme, url]);

    const handlePlayPause = () => {
        setPlaying(!playing);
        waveSurfer.current?.playPause();
    };

    const onVolumeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { target } = e;
        const newVolume = +target.value;

        if (newVolume) {
            setVolume(newVolume);
            waveSurfer.current?.setVolume(newVolume);
        }
    };

    return (
        <StyledWrapper>
            <StyledContentWrapper>
                <StyledButton onClick={handlePlayPause}>
                    {!playing ? (
                        <Play
                            cursor="pointer"
                            size={20}
                            color={isDarkTheme ? "#fff" : classic.text2}
                        />
                    ) : (
                        <Pause
                            cursor="pointer"
                            size={20}
                            color={isDarkTheme ? "#fff" : classic.text2}
                        />
                    )}
                </StyledButton>
                <StyledWaveWrapper>
                    {loading && (
                        <StyledLoading>
                            {isDarkTheme ? (
                                <SkeletonTheme
                                    baseColor="#2a5e7c"
                                    highlightColor="#1a4863"
                                >
                                    <SkeletonLoading height="27px" />
                                </SkeletonTheme>
                            ) : (
                                <SkeletonLoading height="27px" />
                            )}
                        </StyledLoading>
                    )}
                    <StyledWave id="waveform" ref={waveformRef} />
                </StyledWaveWrapper>
                <StyledButton ref={volumeRef}>
                    <Volume2
                        size={20}
                        onClick={() => setShowVolumeRange(!showVolumeRange)}
                        color={isDarkTheme ? "#fff" : classic.text2}
                        cursor="pointer"
                    />
                    {showVolumeRange ? (
                        <StyledInput
                            id="volume"
                            name="volume"
                            type="range"
                            min="0"
                            max="1"
                            step="0.25"
                            onChange={onVolumeChange}
                            defaultValue={volume}
                            $isDarkTheme={isDarkTheme}
                        />
                    ) : (
                        <></>
                    )}
                </StyledButton>
            </StyledContentWrapper>
        </StyledWrapper>
    );
};

export default Recording;
