import { ServiceCategory } from "src/types/api/authentication";
import { CommonPagination } from "src/types/api/common";
import request from "src/utils/request";

export const createServiceCategoryApi = (
    form: FormData
): Promise<ServiceCategory> =>
    request.post<ServiceCategory, ServiceCategory>("/api/categories", form);

export const getServiceCategoryApi = (): Promise<
    CommonPagination<ServiceCategory>
> =>
    request.get<
        CommonPagination<ServiceCategory>,
        CommonPagination<ServiceCategory>
    >(`/api/categories`);
