import { FC, useEffect, useState } from "react";
import { Heading, Text, Anchor } from "@doar/components";
import CONFIG from "src/config";
import { Lead, File } from "src/types/api/ticket";
import { getFilesApi } from "src/api/file/file";
import { useQuery } from "react-query";
import ModalViewFile from "../modal-view";
import FileDropdown from "../file-dropdown";
import {
    StyledCard,
    StyledThumb,
    StyledBody,
    StyledFooter,
    StyledImg,
    StyledCardBody,
} from "./style";

interface IProps {
    icon: string;
    title: string;
    link: string;
    size: string;
    accessed?: string;
    type?: string;
    color: string;
    file?: File;
    lead?: Lead;
}

const FilePreview: FC<IProps> = ({
    icon,
    title,
    link,
    size,
    accessed,
    type,
    color,
    file,
    lead,
}) => {
    const [src, setSrc] = useState("");
    const [showView, setShowView] = useState(false);

    useEffect(() => {
        if (file) {
            setSrc(
                `${CONFIG.GCS_IMAGE_URL}${file?.path.split("\\").join("/")}`
            );
        }
    }, [file]);

    const handleDownload = () => {
        if (file) {
            window?.open(file.download_url, "_blank")?.focus();
        }
    };

    const files = useQuery(
        "fetchFilesByLeadId",
        () =>
            lead &&
            getFilesApi({
                lead_id: lead?.id,
                limit: 9999,
            })
    )?.data?.data;

    return (
        <>
            <StyledCard>
                <FileDropdown handleDownload={handleDownload} />
                <StyledCardBody
                    onClick={() => setShowView(true)}
                    role="button"
                    tabIndex={0}
                >
                    <StyledThumb color={color}>
                        {icon === "far fa-file-image" ? (
                            <StyledImg src={src} alt="img" />
                        ) : (
                            <i className={icon} />
                        )}
                    </StyledThumb>
                    <StyledBody>
                        <Heading mb="0px">
                            <Anchor path={link} variant="link2">
                                {title}
                            </Anchor>
                        </Heading>
                        {type && <Text>{type}</Text>}
                        <Text as="span">{size}</Text>
                    </StyledBody>
                    {accessed && (
                        <StyledFooter>
                            <Text as="span" display={["none", "inline"]}>
                                Last accessed:
                            </Text>{" "}
                            {accessed}
                        </StyledFooter>
                    )}
                </StyledCardBody>
            </StyledCard>
            <ModalViewFile
                show={showView}
                onClose={() => setShowView(false)}
                handleDownload={handleDownload}
                src={src}
                type={icon}
                lead={lead || null}
                file={file || null}
                files={files || []}
            />
        </>
    );
};

export default FilePreview;
