import { Message } from "src/types/api/ticket";
import request from "src/utils/request";
import { CommonPagination } from "../../types/api/common";

type Params = {
    lead_id: number;
    page: number;
};

export const getDetailLeadMessageApi = (
    id: number
): Promise<CommonPagination<Message>> =>
    request.get<CommonPagination<Message>, CommonPagination<Message>>(
        `api/messages?lead_id=${id}`
    );

export const getOlderMessagesApi = (
    params: Params
): Promise<CommonPagination<Message>> => {
    const paramsTemp: {
        [key: string]: string | number;
    } = {
        ...params,
        lead_id: params.lead_id,
        page: params.page || 1,
    };
    return request.get<CommonPagination<Message>, CommonPagination<Message>>(
        "api/messages",
        {
            params: paramsTemp,
        }
    );
};
