/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Heading } from "@doar/components";
import { groupBy } from "lodash-es";
import moment from "moment";
import { FC, Fragment, useEffect, useMemo } from "react";
import { shallowEqual } from "react-redux";
import { loadMore } from "src/redux/slices/ticket/message";
import { toggleBody } from "src/redux/slices/ui";
import IllustrationCaugthupIcon from "src/assets/svg/illustration_caugthup.svg";
import { Message } from "src/types/api/ticket";
import { doSetLead, doSetSelectedTab } from "src/redux/slices/contacts/lead";
import { setDetailLeadId, setMessageId } from "src/redux/slices/ticket/detail";
import { getLeadApi } from "src/api/lead/lead";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { stringify, parse } from "query-string";
import ScrollBar from "../../../../components/scrollbar";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
    StyledBody,
    StyledGroup,
    StyledLabel,
    StyledList,
    StyledLoadingWrapper,
    StyledNoDataWrapper,
    StyledReadMore,
    StyledTopDiv,
} from "./style";
import GroupItem from "./group-item-message";
import useFetchMessage from "../hook/useFetchMessage";
import TicketLoading from "../group/loading";

const GroupMessage: FC = () => {
    const dispatch = useAppDispatch();
    const [fetchMessage] = useFetchMessage();
    const history = useHistory();
    const { data, status, isBody } = useAppSelector(
        (state) => ({
            data: state.ticket.message.data,
            status: state.ticket.message.status,
            isBody: state.ui.isBody,
        }),
        shallowEqual
    );

    const { messageId, detailLeadId } = useAppSelector((store) => ({
        messageId: store.ticket.detail.messageId,
        detailLeadId: store.ticket.detail.detailLeadId,
    }));

    const leadResponse = useQuery(["fetchMessageLead", { detailLeadId }], () =>
        detailLeadId ? getLeadApi(detailLeadId) : null
    );

    useEffect(() => {
        dispatch(
            doSetLead({
                lead: leadResponse?.data || null,
                loading: !!leadResponse?.isLoading,
            })
        );
    }, [leadResponse?.data, leadResponse?.isLoading, dispatch]);

    useEffect(() => {
        fetchMessage();
    }, [fetchMessage]);

    const handleViewDetail = (message: Message | null) => {
        if (message && messageId) {
            dispatch(setMessageId(message.id));

            dispatch(doSetSelectedTab("activity"));
            dispatch(setDetailLeadId(message.recipients[0].id));

            history.push({
                pathname: `/messages/${message.id}`,
                search: stringify({
                    ...parse(history.location.search),
                }),
            });

            if (!isBody) {
                dispatch(toggleBody());
            }
        }
    };

    const dayName = (item: Message) => {
        return moment(item.created_at).format("DD/MM/YYYY");
    };
    const messages = useMemo(() => {
        if (!data) {
            return [];
        }

        const messagesData = data.data;

        let messageSort = groupBy(messagesData, dayName);

        const sortKey = Object.keys(messageSort).sort(
            (a, b) =>
                moment(b, "DD/MM/YYYY").valueOf() -
                moment(a, "DD/MM/YYYY").valueOf()
        );
        const sortMessaged = sortKey.reduce((acc: any, key: any) => {
            // eslint-disable-next-line no-param-reassign
            acc[key] = messageSort[key];
            return acc;
        }, {});
        messageSort = sortMessaged;
        return messageSort;
    }, [data]);

    const handleLoadMore = () => {
        if (data) {
            dispatch(loadMore({ page: Number(data.current_page) + 1 }));
        }
    };

    const renderContent = () => {
        return (
            <>
                {Object.keys(messages).length > 0 && (
                    <>
                        {Object.entries(messages).map(([k, v]) => {
                            return (
                                <Fragment key={k}>
                                    <StyledLabel>
                                        {moment(k, "DD/MM/YYYY").calendar(
                                            null,
                                            {
                                                sameDay: "[Today]",
                                                nextDay: "[Tomorrow]",
                                                nextWeek: "dddd, MMM DD yyyy",
                                                lastDay: "[Yesterday]",
                                                lastWeek: "dddd, MMM DD yyyy",
                                                sameElse: "dddd, MMM DD yyyy",
                                            }
                                        )}
                                    </StyledLabel>
                                    <StyledList>
                                        {v.map((i) => (
                                            <GroupItem
                                                message={i?.message || ""}
                                                html={
                                                    i?.messageable?.subject ||
                                                    ""
                                                }
                                                type={i.messageable_type}
                                                id={i.id}
                                                key={i.id}
                                                title={i?.messageable.subject}
                                                name={
                                                    i?.recipients
                                                        ? [
                                                              i?.recipients[0]
                                                                  ?.first_name,
                                                              i?.recipients[0]
                                                                  ?.last_name,
                                                          ].join(" ")
                                                        : ""
                                                }
                                                time={moment(
                                                    i.created_at
                                                ).format("hh:mm a")}
                                                onClick={() => {
                                                    handleViewDetail(i);
                                                }}
                                            />
                                        ))}
                                    </StyledList>
                                </Fragment>
                            );
                        })}

                        {data?.next_page_url && status === "finished" && (
                            <StyledReadMore>
                                <Button
                                    size="xs"
                                    color="light"
                                    fullwidth
                                    onClick={handleLoadMore}
                                >
                                    Load more
                                </Button>
                            </StyledReadMore>
                        )}
                    </>
                )}
                {status === "finished" && Object.keys(messages).length === 0 && (
                    <StyledNoDataWrapper>
                        <img src={IllustrationCaugthupIcon} alt="" />
                        <p className="text">You&apos;re all caught up!</p>
                        <p className="note">You&apos;ve seen all new tasks</p>
                    </StyledNoDataWrapper>
                )}
            </>
        );
    };

    return (
        <StyledGroup>
            <ScrollBar>
                <StyledBody>
                    <StyledTopDiv>
                        <Heading tt="uppercase" fontWeight={600} mb="0px">
                            Sent
                        </Heading>
                    </StyledTopDiv>
                    <>{renderContent()}</>
                    {status === "loading" && (
                        <StyledLoadingWrapper
                            hasData={Boolean(data && data.data.length !== 0)}
                        >
                            <TicketLoading />
                        </StyledLoadingWrapper>
                    )}
                </StyledBody>
            </ScrollBar>
        </StyledGroup>
    );
};

export default GroupMessage;
