import { FC, useEffect, useState } from "react";
import ChatForm from "src/components/apps/ticket/chat-form";
import { ArrowLeft, MoreVertical } from "react-feather";
import moment from "moment";
import ChatLoading from "src/components/chat-loading";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Note from "../note";
import {
    StyledBody,
    StyledContent,
    StyledMain,
    StyledNoteWrapper,
} from "./main-style";
import {
    StyledHeader,
    StyledOptionsBtn,
    StyledRightHeaderWrapper,
    StyledToggleBtn,
} from "../main/style";
import Sender from "../../../../components/apps/ticket/sender";
import { generateLeadImageColor } from "../../../../helpers/generateLeadImageColor";
import RightActionButtons from "../../../../components/apps/ticket/right-action-buttons";
import MailBody from "../../../../components/apps/ticket/ticket-body";
import { toggleBody } from "../../../../redux/slices/ui";
import MessageTypeSelection from "../message-type-selection";
import { StyledSenderWrap } from "./style";

const Main: FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const bodyHandler = () => {
        dispatch(toggleBody());
    };
    const { details, lead, loading } = useAppSelector((state) => ({
        details: state.ticket.mention.details,
        lead: state.contact.lead.lead,
        loading: state.contact.lead.loadingDetails,
    }));
    const meetingRoom = useAppSelector((store) => store.ticket.daily.room);
    const pathRoom = useAppSelector((store) => store.ticket.ui.path);
    const [showRoom, setShowRoom] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);

    useEffect(() => {
        if (meetingRoom?.url) {
            setShowRoom(true);
        }

        if (!meetingRoom?.url && pathRoom) {
            setShowRoom(false);
        }
    }, [meetingRoom?.url, pathRoom]);

    const sidebarHandler = () => {
        setSidebarOpen((prev) => !prev);
    };

    return (
        <>
            {lead && (
                <StyledMain $rightSidebar={sidebarOpen}>
                    <StyledContent>
                        {loading && <ChatLoading />}
                        {details && !loading && (
                            <>
                                <StyledHeader>
                                    <StyledToggleBtn
                                        type="button"
                                        onClick={bodyHandler}
                                    >
                                        <ArrowLeft />
                                    </StyledToggleBtn>

                                    <StyledSenderWrap
                                        onClick={() =>
                                            history.push(`/leads/${lead.id}`)
                                        }
                                    >
                                        <Sender
                                            color={generateLeadImageColor(
                                                lead.id
                                            )}
                                            name={[
                                                lead.first_name,
                                                lead.last_name,
                                            ].join(" ")}
                                            time={moment(
                                                details.created_at
                                            ).format(
                                                "dddd, DD MMMM, yyyy - hh:mm A"
                                            )}
                                        />
                                    </StyledSenderWrap>

                                    <StyledRightHeaderWrapper>
                                        <RightActionButtons />

                                        <StyledOptionsBtn
                                            onClick={sidebarHandler}
                                        >
                                            <MoreVertical />
                                        </StyledOptionsBtn>
                                    </StyledRightHeaderWrapper>
                                    <MessageTypeSelection />
                                </StyledHeader>
                                <StyledBody>
                                    <MailBody />
                                    <ChatForm />
                                </StyledBody>
                            </>
                        )}
                    </StyledContent>

                    <StyledNoteWrapper
                        $rightSidebar={sidebarOpen}
                        $hasData={!!lead}
                    >
                        {!showRoom && <Note isShowProfileInfo lead={lead} />}
                    </StyledNoteWrapper>
                </StyledMain>
            )}
        </>
    );
};

export default Main;
