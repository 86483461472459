import styled, {
    ColorProps,
    color,
    device,
    themeGet,
} from "@doar/shared/styled";
import {
    ModalBody,
    ModalHeader,
    ModalFooter,
    ModalClose,
    Card,
    Button,
} from "@doar/components";
import Modal from "src/components/custom-modal";

export const StyledModal = styled(({ ...rest }) => <Modal {...rest} />)`
    z-index: 10000;
    & .modal-dialog {
        ${device.large} {
            padding-bottom: 20px;
        }
        padding-bottom: 0;
        height: 95%;
        max-width: 87.5%;
        & .modal-content {
            height: 98%;
        }
    }
`;
export const StyledSelectFileThumb = styled(({ ...rest }) => (
    <div {...rest} />
))<ColorProps>`
    min-width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 112px;
    margin-right: 10px;

    ${color};
`;
export const StyledModalClosed = styled(({ ...rest }) => (
    <ModalClose {...rest} />
))``;

export const StyledWrapFooter = styled.div`
    ${device.medium} {
        width: 46.5vw;
    }
    ${device.large} {
        width: 900px;
    }
    width: 65vw;
`;

export const StyledBoxWrap = styled.div`
    &.fileSelect {
        box-shadow: 0 0 5px 5px #0168fa;
    }
    min-width: 80px;
    max-width: 80px;
    border: 1px solid #485e9029;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px;
`;

export const StyledWrap = styled.div`
    &.imageFile {
        height: 100%;
        width: 100%;
    }
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyleButtonWrap = styled.div`
    display: flex;
    justify-content: center;
`;
export const StyledPreviewSelectImage = styled.img`
    max-height: 40vh;
    min-width: 100px;
    @media (min-width: 1300px) {
        max-height: 60vh;
    }
    object-fit: contain;
    justify-self: center;
`;
export const StyledWrapIcon = styled.div`
    display: flex;
    ${device.large} {
        padding-top: 0;
    }
    padding-top: 20px;
`;

export const StyledButton = styled(({ ...rest }) => <Button {...rest} />)`
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: #f9f9f9;
    border: none;
    color: #031939;
    &:hover,
    &:focus {
        color: #fff;
    }
`;

export const StyledModalBody = styled(({ ...rest }) => <ModalBody {...rest} />)`
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`;

export const StyledModalHeader = styled(({ ...rest }) => (
    <ModalHeader {...rest} />
))`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    & .modalView {
        display: flex;
        ${device.large} {
            width: 100%;
            display: block;
        }
        flex-wrap: wrap;
        width: 150px;
    }
`;

export const StyledModalFooter = styled(({ ...rest }) => (
    <ModalFooter {...rest} />
))`
    justify-content: center;
`;

export const StyledCard = styled(({ ...rest }) => <Card {...rest} />)`
    padding: 10px;
    width: 80px;
    height: 80px;
    position: relative;
    border-color: ${themeGet("colors.border")};
    transition: all 0.2s ease-in-out;
    &:hover,
    &:focus {
        border-color: ${themeGet("colors.text4")};
    }
    span {
        font-size: 12px;
        color: ${themeGet("colors.text3")};
    }
    p {
        margin-bottom: 0;
        color: ${themeGet("colors.river")};
    }
`;
export const StyledImg = styled.img`
    &.listImage {
        min-height: 75px;
        min-width: 75px;
        max-width: 75px;
        max-height: 75px;
    }
    &.showImage {
    }
    object-fit: contain;
`;
