import { Package, Layers, Calendar } from "react-feather";

const menus = [
    // {
    //     id: 1,
    //     label: "Calendar",
    //     url: "/calendar",
    //     Icon: Calendar,
    // },
    {
        id: 2,
        label: "Inbox",
        url: "/tickets",
        Icon: Package,
    },
    {
        id: 3,
        label: "Contacts",
        url: "/contacts",
        Icon: Layers,
    },
    {
        id: 4,
        label: "Reports",
        url: "/reports",
        Icon: Layers,
    },
];

export default menus;
