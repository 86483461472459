import { Avatar, AvatarInitial } from "@doar/components";
import { debounce } from "lodash-es";
import { FC, ReactElement, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { doGetStaffs } from "src/redux/slices/settings/manage-users/active-staffs";
import { Staff } from "src/types/api/staff";
import ServiceDropdown from "src/components/apps/settings/services/service-details/service-dropdown";
import { StyledNameWrap, StyledWrap } from "./style";

interface IProps {
    isSearch?: boolean;
    selectedStaff: Staff | null;
    onChange: (staff: Staff | null) => void;
}

const StaffFilter: FC<IProps> = ({ selectedStaff, onChange, isSearch }) => {
    const [list, setList] = useState<{ id: number; element: ReactElement }[]>(
        []
    );
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);

    const [isDeleteStaff, setIsDeleteStaff] = useState(false);

    const { staffs } = useAppSelector((store) => ({
        staffs: store.setting.manage_users.activeStaffs.staffs,
    }));

    useEffect(() => {
        dispatch(
            doGetStaffs({
                limit: 100,
                keyword: null,
            })
        );
    }, [dispatch]);

    const renderStaff = (staff: Staff | null) => {
        return (
            <StyledNameWrap>
                {staff ? (
                    <>
                        <Avatar shape="circle">
                            {staff?.photo ? (
                                <img src={staff.photo} alt="" />
                            ) : (
                                <AvatarInitial>
                                    {staff?.first_name
                                        ? staff?.first_name[0]
                                        : "?"}
                                </AvatarInitial>
                            )}
                        </Avatar>
                        <div className="name">
                            {staff?.first_name || staff?.last_name
                                ? `${staff?.first_name} ${staff?.last_name}`
                                : "Unknown"}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="lname">Select staff </div>
                    </>
                )}
            </StyledNameWrap>
        );
    };
    const handleSearch = useMemo(
        () =>
            debounce((name: string) => {
                setLoading(true);
                dispatch(doGetStaffs({ keyword: name }));
            }, 500),
        [dispatch]
    );

    useEffect(() => {
        if (staffs) {
            setLoading(false);
            setList(
                staffs.map((i) => ({
                    id: i.id,
                    element: renderStaff(i),
                }))
            );
        }
    }, [staffs]);

    const removeStaffIsSelect = () => {
        setIsDeleteStaff(false);
        onChange(null);
    };

    return (
        <StyledWrap className="modal-filter">
            <ServiceDropdown
                isDeleteable={isDeleteStaff}
                removeOptionIsSelected={removeStaffIsSelect}
                isSearchable={isSearch}
                isLoading={loading}
                onChangeSearchValue={(string) => handleSearch(string)}
                value={renderStaff(selectedStaff)}
                items={list}
                onChange={(data) => {
                    setIsDeleteStaff(true);
                    const staffTemp =
                        staffs?.find((i) => i.id === data.id) || null;
                    onChange(staffTemp);
                }}
            />
        </StyledWrap>
    );
};

export default StaffFilter;
