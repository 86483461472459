import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreateWidgetForm, UpdateWidgetForm } from "src/api/widgets/widget";
import {
    BOOK_APPOINTMENT,
    CALL_US,
    FAQS,
    INSTANT_QUOTE,
    REQUEST_CONSULTATION,
    ASK_A_QUESTION,
} from "src/components/apps/settings/channels/detail/channel-setting/widget-setting/assistant-main-page/settings/options/constants";
import { Channel } from "src/types/api/channel";
import { Widget, WidgetCustomOption, WidgetOption } from "src/types/api/form";

interface ChannelState {
    channel: Channel | null;
    widget: Widget | null;
    widgetForm: {
        color: string;
        options: WidgetOption[];
        customOptions: WidgetCustomOption[];
        surgical?: boolean;
        nonSurgical?: boolean;
    };
    loading: boolean;
    services: {
        selectedProcedures: string[];
    };
}

const initialState: ChannelState = {
    channel: null,
    widget: null,
    widgetForm: {
        color: "#0E405F",
        options: [
            {
                name: "Instant Quote",
                type: "Instant Quote",
                checked: true,
            },
            {
                name: "Request Consultation",
                type: "Request Consultation",
                checked: true,
            },
            { name: "Call Us", type: "Call Us", checked: true },
            {
                name: "Book Appointment",
                type: "Book Appointment",
                checked: true,
            },
            {
                name: "Ask A Question",
                type: "Ask A Question",
                checked: true,
            },
            {
                name: "FAQs",
                type: "FAQs",
                checked: true,
            },
        ],
        customOptions: [],
        surgical: false,
        nonSurgical: false,
    },
    loading: false,
    services: {
        selectedProcedures: [],
    },
};

const channelSlices = createSlice({
    name: "channels/channel",
    initialState,
    reducers: {
        doSetChannel(state, action: PayloadAction<Channel | null>) {
            state.channel = action.payload;
            if (action.payload?.widget) {
                state.widget = action.payload.widget;
                const tempCustomOptionArr = [
                    ...action.payload.widget.widget_options.filter(
                        (i) => i.url
                    ),
                ];
                const findType = (type: string) =>
                    action.payload?.widget &&
                    !!action.payload?.widget.widget_options.find(
                        (i) => i.type === type
                    );
                state.widgetForm.color = action.payload.widget.color_theme;
                state.widgetForm.options = state.widgetForm.options.map((i) => {
                    if (findType(i.type)) {
                        return { ...i, checked: true };
                    }
                    if (!findType(i.type)) {
                        return { ...i, checked: false };
                    }
                    return i;
                });

                state.widgetForm.customOptions = tempCustomOptionArr.map(
                    (i) => {
                        return { name: i.name, url: i.url, checked: true };
                    }
                );
                const bookAppoiment = (type: string) =>
                    action.payload?.widget &&
                    !!action.payload.widget.widget_options.find(
                        (i) => i.book_appointment_type === type
                    );

                if (bookAppoiment("surgical")) {
                    state.widgetForm.surgical = true;
                }
                if (bookAppoiment("non_surgical")) {
                    state.widgetForm.nonSurgical = true;
                }
                if (bookAppoiment("surgical_and_non_surgical")) {
                    state.widgetForm.nonSurgical = true;
                    state.widgetForm.surgical = true;
                }
            }
        },
        doSetWidgetColor(state, action: PayloadAction<string>) {
            state.widgetForm.color = action.payload;
        },
        doSetSurgical(state) {
            state.widgetForm.surgical = !state.widgetForm.surgical;
        },
        doSetNonSurgical(state) {
            state.widgetForm.nonSurgical = !state.widgetForm.nonSurgical;
        },
        doSetWidgetOptions(state, action: PayloadAction<WidgetOption[]>) {
            state.widgetForm.options = action.payload.map((i) => {
                if (i.type === INSTANT_QUOTE) {
                    return { ...i };
                }
                if (i.type === REQUEST_CONSULTATION) {
                    return { ...i };
                }
                if (i.type === CALL_US) {
                    return { ...i };
                }
                if (i.type === BOOK_APPOINTMENT) {
                    return { ...i };
                }
                if (i.type === ASK_A_QUESTION) {
                    return { ...i };
                }
                if (i.type === FAQS) {
                    return { ...i };
                }
                return i;
            });
        },

        doSetWidgetCustomOptions(
            state,
            action: PayloadAction<WidgetCustomOption[]>
        ) {
            state.widgetForm.customOptions = action.payload;
        },

        doSetOptionName(state, action: PayloadAction<WidgetOption[]>) {
            state.widgetForm.options = action.payload;
        },

        doCreateWidget(
            state,
            action: PayloadAction<{ form: CreateWidgetForm }>
        ) {
            state.loading = true;
        },

        doCreateWidgetSuccess(state, action: PayloadAction<Widget>) {
            state.loading = false;
            state.widgetForm.color = action.payload.color_theme;
            const findType = (type: string) =>
                action.payload.widget_options &&
                !!action.payload?.widget_options.find((i) => i.type === type);
            const tempCustomOptionArr = [
                ...action.payload.widget_options.filter((i) => i.url),
            ];
            state.widgetForm.options = state.widgetForm.options.map((i) => {
                if (findType(i.type)) {
                    return { ...i, checked: true };
                }
                if (!findType(i.type)) {
                    return { ...i, checked: false };
                }
                return i;
            });
            state.widgetForm.customOptions = tempCustomOptionArr.map((i) => {
                return { name: i.name, url: i.url, checked: true };
            });
        },

        doCreateWidgetFail(state) {
            state.loading = false;
        },

        doGetWidget(state, action: PayloadAction<number>) {
            state.loading = true;
        },

        doGetWidgetSuccess(state, action: PayloadAction<Widget>) {
            state.loading = false;
        },

        doGetWidgetFail(state) {
            state.loading = false;
        },

        doUpdateWidget(
            state,
            action: PayloadAction<{ id: number; form: UpdateWidgetForm }>
        ) {
            state.loading = true;
        },

        doUpdateWidgetSuccess(state, action: PayloadAction<Widget>) {
            state.loading = false;
        },

        doUpdatetWidgetFail(state) {
            state.loading = false;
        },

        doSetSelectedProcedure(state, action: PayloadAction<string[]>) {
            state.services.selectedProcedures = action.payload;
        },
    },
});

export const {
    doSetChannel,
    doSetWidgetColor,
    doSetWidgetOptions,
    doSetWidgetCustomOptions,
    doSetOptionName,
    doCreateWidget,
    doCreateWidgetSuccess,
    doCreateWidgetFail,
    doGetWidget,
    doGetWidgetSuccess,
    doGetWidgetFail,
    doUpdateWidget,
    doUpdateWidgetSuccess,
    doSetSurgical,
    doSetNonSurgical,
    doSetSelectedProcedure,
} = channelSlices.actions;

export default channelSlices.reducer;
