import styled, { css, themeGet, device } from "@doar/shared";

export const StyledMinimizeCard = styled.div<{
    $show?: boolean;
    $isConversation: boolean;
}>`
    display: none;
    width: 250px;
    padding: 0 10px 0 10px;
    position: fixed;
    bottom: 0px;
    right: 0px;
    background-color: ${themeGet("colors.primary")};
    color: ${themeGet("colors.light")};
    border-radius: 10px 10px 0 0;
    ${({ $isConversation }) =>
        $isConversation &&
        css`
            right: 20px;
            ${device.xlarge} {
                right: 420px;
            }
        `}
    ${({ $show }) =>
        $show &&
        css`
            display: block;
        `};
`;

export const StyledMinimize = styled.div`
    display: flex;
    align-items: center;
    height: 45px;
`;
export const StyledParagraph = styled.div`
    font-size: 13px;
    color: #fff;
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 5px;
    cursor: pointer;
    flex-grow: 2;
`;
export const StyledMinimizeClose = styled.div`
    margin-left: auto;
    height: 18px;
    cursor: pointer;
`;
