import { createFileApi } from "src/api/file/file";
import { toastError } from "src/utils/toast";
import { all, call, put, takeLatest } from "typed-redux-saga";
import { File as FileType } from "src/types/api/ticket";
import {
    deleteLeadNoteApi,
    getLeadNotesApi,
    storeLeadNoteApi,
    updateLeadNoteApi,
} from "../../../api/lead/note";
import {
    update,
    updateSuccess,
    fail,
    start,
    success,
    doCreateNote,
    doCreateNoteSuccess,
    doCreateNoteHasFileFail,
    doDeleteNote,
    doDeleteNoteSuccess,
    doDeleteNoteFail,
} from "../../slices/ticket/note";

function* getListNoteSaga(action: ReturnType<typeof start>) {
    try {
        const response = yield* call(getLeadNotesApi, action.payload.id, 1);
        yield* put(success(response));
    } catch (error) {
        yield* put(fail());
    }
}

function* updateNoteSaga(action: ReturnType<typeof update>) {
    try {
        const response = yield* call(
            updateLeadNoteApi,
            action.payload.id,
            action.payload
        );
        yield* put(updateSuccess(response));
        yield* call(action.payload.onSuccess);
    } catch (error) {
        toastError(error);
        yield* call(action.payload.onFail);
    }
}

function* createNoteSaga(action: ReturnType<typeof doCreateNote>) {
    const { form, onSuccess, onFail } = action.payload;
    try {
        const note = yield* call(storeLeadNoteApi, form);
        const { files } = form;
        const filesResult: FileType[] = [];
        if (files) {
            for (let i = 0; i < files.length; i += 1) {
                const file = files[i];
                const formData = new FormData();

                formData.append(
                    "original_name",
                    file.name.substring(0, file.name.lastIndexOf("."))
                );
                formData.append("fileable_id", String(note.id));
                formData.append("fileable_type", "App\\Models\\Note");
                formData.append("file", file);

                const res = yield* call(createFileApi, formData);
                filesResult.push(res);
            }
        }

        yield* put(
            doCreateNoteSuccess({
                ...note,
                files: filesResult,
            })
        );
        if (onSuccess) {
            yield* call(onSuccess);
        }
    } catch (err) {
        yield* put(doCreateNoteHasFileFail());
        if (onFail) {
            const error = JSON.parse(JSON.stringify(err));
            yield* call(onFail, error?.data?.message);
        }
    }
}

function* deleteNoteSaga(action: ReturnType<typeof doDeleteNote>) {
    const { id, onFail, onSuccess } = action.payload;
    try {
        yield* call(deleteLeadNoteApi, id);
        yield* put(doDeleteNoteSuccess(id));
        if (onSuccess) {
            yield* call(onSuccess);
        }
    } catch (err) {
        if (onFail) {
            const errors = JSON.parse(JSON.stringify(err));
            yield* call(onFail, errors?.data?.message);
        }
        yield* put(doDeleteNoteFail());
    }
}

export function* noteSaga(): Generator {
    yield all([
        takeLatest(start, getListNoteSaga),
        takeLatest(update, updateNoteSaga),
        takeLatest(doCreateNote, createNoteSaga),
        takeLatest(doDeleteNote, deleteNoteSaga),
    ]);
}
