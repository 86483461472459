import styled, { themeGet, ColorProps, color } from "@doar/shared/styled";

export const StyledFileAttach = styled.div`
    display: flex;
    align-items: center;
    background: ${themeGet("colors.gray200")};
    padding: 10px;
    width: 180px;
    margin-top: 5px;
    border-radius: 9px;
    position: relative;
    margin-right: 5px;
    cursor: pointer;
    &:hover {
        .remove-btn {
            display: flex;
        }
    }
`;

export const StyledFileThumb = styled(({ ...rest }) => (
    <div {...rest} />
))<ColorProps>`
    min-width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    margin-right: 10px;

    ${color};
`;

export const StyledFileName = styled.div`
    font-weight: 500;
    color: ${themeGet("colors.primary")};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px;
    cursor: default;
`;

export const StyledFileSize = styled.div`
    font-weight: 500;
    color: ${themeGet("colors.gray600")};
    margin-left: 3px;
    margin-right: 5px;
    font-size: 13px;
`;

export const StyledRemoveFile = styled.div`
    margin-left: auto;
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: absolute;
    top: -7px;
    right: -8px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background: ${themeGet("colors.gray700")};
    div {
        height: 12px;
    }
    cursor: pointer;
    display: none;
    &:hover {
        background: ${themeGet("colors.gray900")};
    }
`;
