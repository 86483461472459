import { Button, Spinner } from "@doar/components";
import { ChangeEvent, FC } from "react";
import { Paperclip } from "react-feather";
import { FilePreview } from "src/types/api/message";
import {
    StyledAttachFileBtn,
    StyledToolbar,
    StyledToolbarWrap,
} from "../style";

interface Props {
    loading: boolean;
    files: FilePreview[];
    onChangeFiles: (file: FilePreview[]) => void;
}
const CustomToolbar: FC<Props> = ({ loading, onChangeFiles, files }) => {
    const onUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.files && e.target.files.length > 0) {
            const newFiles: FilePreview[] = Array.from(e.target?.files).map(
                (i) => ({
                    file: null,
                    preview: i,
                })
            );
            onChangeFiles([...files, ...newFiles]);
        }
    };
    return (
        <StyledToolbarWrap>
            <Button
                type="submit"
                ml="2px"
                className="send-email-btn"
                disabled={loading}
            >
                {loading ? <Spinner color="white" size="xs" /> : "Send email"}
            </Button>
            <StyledToolbar id="toolbar">
                <span className="ql-formats">
                    <StyledAttachFileBtn htmlFor="email-file-attachment">
                        <Paperclip size={15} strokeWidth={2.5} />
                    </StyledAttachFileBtn>
                    <input
                        type="file"
                        hidden
                        id="email-file-attachment"
                        multiple
                        onChange={onUploadFile}
                    />
                    <button type="button" className="ql-bold" />
                    <button type="button" className="ql-italic" />
                    <button type="button" className="ql-underline" />
                </span>
                <span className="ql-formats">
                    <select className="ql-size">
                        <option value="small" />
                        <option selected />
                        <option value="large" />
                        <option value="huge" />
                    </select>
                </span>
                <span className="ql-formats">
                    <select className="ql-align" />
                    <select className="ql-color" />
                </span>
                <span className="ql-formats">
                    <button type="button" className="ql-list" value="ordered" />
                    <button type="button" className="ql-list" value="bullet" />
                    <button type="button" className="ql-link" />
                </span>
            </StyledToolbar>
        </StyledToolbarWrap>
    );
};

export default CustomToolbar;
