import styled from "@doar/shared/styled";

export const StyledWrap = styled.div`
    padding: 5px;
    flex: 1;
    overflow-y: scroll;
`;

export const StyledTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
`;

export const StyledScrollZone = styled.div`
    height: 100%;
`;
