import request from "src/utils/request";
import { ApiIntegration } from "src/types/api/app";

export const getDetailApiIntegration = (id: number): Promise<ApiIntegration> =>
    request.get<ApiIntegration, ApiIntegration>(`/api/integrations/${id}`);

export const updateApiIntegration = (
    id: number,
    form: {
        integration: ApiIntegration;
        config: {
            customer_id?: string | number;
        };
    }
): Promise<ApiIntegration> => request.put(`api/integrations/${id}`, form);
