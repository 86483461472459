import { Button, ModalBody, ModalFooter } from "@doar/components";
import Modal from "src/components/custom-modal";
import { FC } from "react";
import { useHistory } from "react-router-dom";

interface IProps {
    show: boolean;
    onClose: () => void;
    channel: "sms" | "email";
}

const EmptyChannelModal: FC<IProps> = ({ show, onClose, channel }) => {
    const history = useHistory();

    return (
        <Modal show={show} onClose={onClose} size="sm" centered>
            <ModalBody>
                <p>
                    {channel === "sms"
                        ? "You must connect an sms channel to send and receive texts"
                        : "You must connect an email channel to send and receive emails"}
                </p>
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={onClose}>
                    Close
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        onClose();
                        history.push("/settings/channels");
                    }}
                >
                    Register a channel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default EmptyChannelModal;
