import styled, { css, themeGet } from "@doar/shared/styled";

const link3 = css`
    color: ${themeGet("colors.text3")};
    &:hover {
        color: ${themeGet("colors.text2")};
    }
`;

export const StyledWrap = styled.form`
    background-color: #fff;
    margin-top: auto;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    border-top: 1px solid ${themeGet("colors.border")};
    position: relative;
    width: 400px;
    padding: 5px 12px 12px 12px;
    min-height: 100px;
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            background-color: ${themeGet("colors.gray900")};z
        `}
`;

export const StyledShare = styled.div<{ $hasError?: boolean }>`
    margin-right: auto;
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    textarea {
        border: none;
    }
    ${({ $hasError }) =>
        $hasError &&
        css`
            textarea {
                border-bottom: 1px solid ${themeGet("colors.danger")};
            }
        `}
`;

export const RightBtn = styled.div`
    max-width: 50px;
    flex-basis: 50px;
    border-left: 1px solid ${themeGet("colors.border")};
    align-items: center;
    justify-content: center;
    display: flex;
`;

export const RightBtnLink = styled.a`
    ${link3}
    line-height: 1;
`;

export const StyledSpinnerWrapper = styled.div`
    position: absolute;
    right: 25px;
`;

export const StyledIcon = styled.div`
    width: 14px;
    height: 14px;
    margin-right: 7px;
    label {
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
    }
`;

export const StyledFilesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    max-height: 150px;
`;

export const StyledSendIcon = styled.img`
    width: 20px;
`;

export const StyledDropdownItem = styled.div<{ active?: boolean }>`
    display: flex;
    align-items: center;
    .staffName {
        margin-left: 10px;
    }
    width: 100%;
    padding: 6px 15px;
    clear: both;
    font-weight: 400;
    color: #1c273c;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    transition: all 0.2s ease-in-out;
    ${({ active }) =>
        active &&
        css`
            color: #fff;
            background: ${themeGet("colors.primary")};
        `}
    .avatar {
        width: 22px;
        height: 22px;
    }
`;
