import { Call } from "src/types/api/call";
import request from "src/utils/request";

export interface CreateCallForm {
    call_sid?: string;
    form_id?: number;
    from_phone_number?: string;
    to_phone_number: string;
    location?: string;
    recording?: string;
    duration?: number;
    status: "initiated" | "completed" | "busy" | "no-answer";
    lead_id: number;
    direction: "inbound" | "outbound";
}

export interface UpdateCallForm {
    call_sid?: string;
    form_id?: number;
    from_phone_number?: string;
    to_phone_number?: string;
    location?: string;
    recording?: string;
    duration?: number;
    status?: "initiated" | "completed" | "busy" | "no-answer";
    lead_id?: number;
    direction?: "inbound" | "outbound";
}

export const createCallApi = (form: CreateCallForm): Promise<Call> =>
    request.post<Call, Call>("api/calls", form);

export const updateCallApi = (data: {
    id: number;
    form: UpdateCallForm;
}): Promise<Call> => request.put<Call, Call>(`api/calls/${data.id}`, data.form);
