import { Avatar, AvatarInitial } from "@doar/components";
import { FC, ReactElement, useState, useMemo, useEffect } from "react";
import { Lead } from "src/types/api/ticket";
import { getLeadsApi } from "src/api/lead/lead";
import { debounce } from "lodash-es";
import CustomDropdown from "./custom-dropdown";
import { StyledNameWrap, StyledWrap } from "./style";

interface IProps {
    isSearch?: boolean;
    selectedLead: Lead | null;
    onChange: (lead: Lead | null) => void;
}

const LeadFilter: FC<IProps> = ({ selectedLead, onChange, isSearch }) => {
    const [list, setList] = useState<{ id: number; element: ReactElement }[]>(
        []
    );
    const [defaultList, setDefaultList] = useState<
        { id: number; element: ReactElement }[]
    >([]);
    const [defaultLead, setDefaultLead] = useState<Lead[]>([]);
    const [isDeleteLead, setIsDeleteLead] = useState(false);
    const [loading, setLoading] = useState(false);

    const renderLead = (lead: Lead | null) => {
        return (
            <StyledNameWrap>
                {lead ? (
                    <>
                        <Avatar shape="circle">
                            <AvatarInitial backgroundColor="#3F64AA">
                                {lead?.first_name ? lead?.first_name[0] : "?"}
                            </AvatarInitial>
                        </Avatar>
                        <div className="name">
                            {lead?.first_name || lead?.last_name
                                ? `${lead?.first_name} ${lead?.last_name}`
                                : "Unknown"}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="lname">Select lead contact </div>
                    </>
                )}
            </StyledNameWrap>
        );
    };

    useEffect(() => {
        getLeadsApi({ limit: 10 })
            .then((r) => {
                setDefaultLead(r.data);
                setList(
                    r.data.map((i) => ({
                        id: i.id,
                        element: renderLead(i),
                    }))
                );
                setDefaultList(
                    r.data.map((i) => ({
                        id: i.id,
                        element: renderLead(i),
                    }))
                );
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    const handleSearch = useMemo(
        () =>
            debounce((name: string) => {
                if (name.length === 0) {
                    setList(defaultList);
                    return;
                }
                setLoading(true);
                getLeadsApi({ keyword: name, limit: 10 })
                    .then((r) => {
                        setDefaultLead(r.data);
                        setList(
                            r.data.map((i) => ({
                                id: i.id,
                                element: renderLead(i),
                            }))
                        );
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            }, 500),
        [defaultList]
    );

    const removeLeadIsSelect = () => {
        setIsDeleteLead(false);
        onChange(null);
    };

    return (
        <StyledWrap className="modal-filter">
            <CustomDropdown
                isDeleteable={isDeleteLead}
                removeOptionIsSelected={removeLeadIsSelect}
                isSearchable={isSearch}
                isLoading={loading}
                onChangeSearchValue={(string) => handleSearch(string)}
                value={renderLead(selectedLead)}
                items={list}
                onChange={(data) => {
                    setIsDeleteLead(true);
                    const leadTemp =
                        defaultLead?.find((i) => i.id === data.id) || null;
                    onChange(leadTemp);
                }}
            />
        </StyledWrap>
    );
};

export default LeadFilter;
