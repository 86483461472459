import {
    CardBody,
    CardHeader,
    Dropdown,
    DropdownToggle,
    Input,
} from "@doar/components";
import styled, { css, themeGet, device } from "@doar/shared/styled";
import EmailFormModal from "./modal";

export const StyledForm = styled.form`
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-top: 1px solid ${themeGet("colors.border")};
`;

export const StyledEmailFormModal = styled(({ ...rest }) => (
    <EmailFormModal {...rest} />
))`
    .modal-dialog {
        height: 100%;
        margin-bottom: 0;
        margin-top: 0;
    }
    .modal-content {
        min-height: 650px;
        height: 65%;
    }
`;
export const StyledSendSmsIcon = styled.img`
    width: 20px;
`;
export const StyledSendSmsButton = styled.div`
    margin: 10px;
    position: relative;
    button {
        min-width: 50px;
    }
    .loading {
        height: 18px;
    }
`;
export const StyldFile = styled.label`
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${themeGet("colors.text3")};
    border-right: 1px solid ${themeGet("colors.border")};
    cursor: pointer;
    &:hover,
    &:focus {
        color: ${themeGet("colors.text2")};
    }
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            color: ${themeGet("colors.gray500")};
            &:hover,
            &:focus {
                color: #fff;
            }
        `}
`;

export const StyledInput = styled(({ ...rest }) => <Input {...rest} />)`
    font-size: inherit;
    border: 0;
    box-shadow: none !important;
    background-color: transparent;
    margin: 10px 10px 10px 0;
    &.disabled {
        background-color: #f5f6fa;
        caret-color: transparent;
        cursor: default;
    }
    align-self: center;
    &:placeholder {
        color: ${themeGet("colors.gray800")};
    }
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            color: ${themeGet("colors.gray300")};
            &:placeholder {
                color: ${themeGet("colors.gray800")};
            }
        `}
`;

export const StyledNav = styled.nav`
    display: flex;
    align-items: center;
    border-left: 1px solid ${themeGet("colors.border")};
    padding: 10px 20px;
    svg {
        width: 20px;
        color: ${themeGet("colors.text3")};
        &:hover {
            color: ${themeGet("colors.cornflower")};
        }
    }
`;

export const StyledImage = styled.label`
    padding: 2px 5px;
    border-radius: 0.25rem;
    cursor: pointer;
    line-height: 1;
`;

export const StyledBtn = styled.button`
    padding: 2px 5px;
    border-radius: 0.25rem;
    cursor: pointer;
    background: transparent;
    border: none;
    margin-left: 2px;
    line-height: 1;
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            color: ${themeGet("colors.gray500")};
            &:hover,
            &:focus {
                color: #fff;
            }
        `}
`;

export const StyledToggle = styled(({ ...rest }) => (
    <DropdownToggle {...rest} />
))`
    height: 100%;
`;

export const StyledDropdown = styled(({ ...rest }) => <Dropdown {...rest} />)`
    min-width: 102px;
    margin: 10px;
`;

export const StyledChatFormCard = styled.div<{
    $show?: boolean;
    $width: number;
    $inLeadPage?: boolean;
}>`
    width: 400px;
    display: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    position: fixed;
    z-index: 100;
    bottom: 0;
    right: 0;
    border: 1px solid ${themeGet("colors.border")};
    ${device.small} {
        width: 580px;
    }
    ${device.large} {
        right: -100px;
    }
    ${device.xlarge} {
        right: -200px;
    }
    ${({ $width, $inLeadPage }) =>
        $width &&
        $inLeadPage &&
        css`
            ${device.small} {
                right: ${($width - 550) / 2}px;
            }
            ${device.xlarge} {
                right: 200px;
            }
            @media screen and (min-width: 1600px) {
                right: 300px;
            }
        `}
    ${({ $show }) =>
        $show &&
        css`
            display: block;
        `};
`;

export const StyledChatFormCardHeader = styled(({ ...rest }) => (
    <CardHeader {...rest} />
))`
    padding: 8px 1rem;
    background-color: ${themeGet("colors.primary")};
    color: ${themeGet("colors.light")};
    display: flex;
    align-items: center;
`;

export const StyledCardClose = styled.div`
    margin-left: auto;
    height: 25px;
    cursor: pointer;
`;

export const StyledCardTitle = styled.div`
    color: ${themeGet("colors.light")};
    font-size: 15px;
`;

export const StyledCardBody = styled(({ ...rest }) => <CardBody {...rest} />)`
    padding: 10px;
    background: #fff;
    max-height: calc(100% - 44px);
`;

export const StyledOptionIcon = styled.img`
    width: 20px;
    margin-right: 8px;
    margin-bottom: 2px;
`;

export const StyledDropdownItem = styled.div<{ active?: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 6px 15px;
    clear: both;
    font-weight: 400;
    color: #1c273c;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
        background: ${themeGet("colors.gray200")};
    }
    ${({ active }) =>
        active &&
        css`
            background: ${themeGet("colors.primary")};
            color: #fff;
            &:hover {
                background: ${themeGet("colors.primary")};
            }
        `}
`;
