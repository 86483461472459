import { FC, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { CSSTransition } from "react-transition-group";
import AddNewEmailContainer from "src/containers/apps/ticket/group/drop-down/add-new-email-container";
import { Lead } from "src/types/api/ticket";
import { StyledMenu, StyledDropdown, StyledMenuItem } from "../style";

interface Props {
    lead: Lead | null;
    show: boolean;
    onClose: () => void;
    onAddEmailSuccess?: () => void;
    onAddEmailFail?: () => void;
}

const HandleAddEmail: FC<Props> = ({
    lead,
    show,
    onClose,
    onAddEmailSuccess,
    onAddEmailFail,
}) => {
    const [activeMenu, setActiveMenu] = useState<"main" | "addEmail">("main");

    const onCloseDropdown = () => {
        onClose();
        setActiveMenu("main");
    };

    const ref = useOnclickOutside(() => onCloseDropdown());

    return (
        <StyledDropdown ref={ref} $show={show}>
            <CSSTransition in={activeMenu === "main"} timeout={0} unmountOnExit>
                <StyledMenu className="dropdownOutSide">
                    <StyledMenuItem
                        variant="texted"
                        onClick={() => setActiveMenu("addEmail")}
                    >
                        Add missing email
                    </StyledMenuItem>
                </StyledMenu>
            </CSSTransition>
            <CSSTransition
                in={activeMenu === "addEmail"}
                timeout={0}
                unmountOnExit
            >
                <StyledMenu className="dropdownInSide">
                    <AddNewEmailContainer
                        lead={lead}
                        onClose={onCloseDropdown}
                        onAddEmailSuccess={onAddEmailSuccess}
                        onAddEmailFail={onAddEmailFail}
                    />
                </StyledMenu>
            </CSSTransition>
        </StyledDropdown>
    );
};

export default HandleAddEmail;
