import { Media, MediaBody } from "@doar/components";
import styled, { css, themeGet } from "@doar/shared/styled";

export const StyledCard = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const StyledGroup = styled.div`
    min-height: 100%;
    padding: 20px 20px 60px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    .media {
        &.ticket-select,
        &.message-select {
            border-radius: 5px;
            background-color: rgba(229, 233, 242, 0.8);
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 15px;
            padding-bottom: 25px;
        }
        & + .media {
            margin-top: 20px;
        }
    }
`;

export const StyledDivider = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin: 20px 0;
    color: ${themeGet("colors.text3")};
    font-size: 10px;
    font-family: ${themeGet("fonts.interUi")};
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    &:first-of-type {
        margin-top: 0;
    }
    &:before,
    &:after {
        content: "";
        display: block;
        flex: 1;
        height: 1px;
        background-color: ${themeGet("colors.light")};
    }
    &:before {
        margin-right: 10px;
    }
    &:after {
        margin-left: 10px;
    }
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            &::before,
            &::after {
                background-color: rgba(255, 255, 255, 0.06);
            }
        `}
`;

export const StyledOpenTicket = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin: 20px 0;
    color: ${themeGet("colors.danger")};
    font-size: 10px;
    font-family: ${themeGet("fonts.interUi")};
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    &:first-of-type {
        margin-top: 0;
    }
    &:before {
        content: "";
        display: block;
        flex: 1;
        height: 1px;
        background-color: ${themeGet("colors.danger")};
    }
    &:before {
        margin-right: 10px;
    }
    &:after {
        margin-left: 10px;
    }
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            &::before,
            &::after {
                background-color: rgba(255, 255, 255, 0.06);
            }
        `}
`;

export const StyledItemBody = styled(({ ...rest }) => <MediaBody {...rest} />)`
    max-width: calc(100% - 52px);
    ${(props) => {
        return props.isRightSide
            ? css`
                  margin-left: 20px;
              `
            : css`
                  margin-right: 20px;
              `;
    }}

    p {
        margin-bottom: 5px;
    }
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            color: ${themeGet("colors.gray700")};
        `}
`;

export const StyledTitle = styled.h6<{ isRightSide?: boolean }>`
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    color: ${themeGet("colors.text2")};
    ${(props) => {
        return props.isRightSide
            ? css`
                  text-align: left;
              `
            : css`
                  text-align: right;
              `;
    }}
    small {
        color: ${themeGet("colors.text3")};
    }
`;

export const StyledItemContent = styled.div``;

export const StyledTimeHeader = styled.span<{ fromSystem?: boolean }>`
    font-weight: 300;
    margin-left: 5px;
`;

export const StyledMedia = styled(({ ...rest }) => <Media {...rest} />)`
    ${(props) => {
        return props.isRightSide
            ? css`
                  flex-direction: row;
              `
            : css`
                  flex-direction: row-reverse;
              `;
    }}
    &.hidden {
        display: none;
    }
`;

export const StyledAvatarWrapper = styled.div`
    min-width: 32px;
    cursor: pointer;
`;
