import { setMessageId, setDetailLeadId } from "src/redux/slices/ticket/detail";
import { useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { parse, stringify } from "query-string";
import { getMessagesApi } from "src/api/message/message";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { setMessages } from "src/redux/slices/ticket/message";

const useFetchMessage = (): [() => void] => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const { data, isLoading } = useQuery("fetchMessages", () =>
        getMessagesApi({
            page: 1,
            type: "outbound",
            has: "staff_id",
            with: "recipients",
        })
    );

    const { messageId } = useAppSelector((store) => ({
        messageId: store.ticket.detail.messageId,
    }));

    useEffect(() => {
        if (
            data &&
            data.data.length > 0 &&
            !data.data.find((m) => m.id === messageId)
        ) {
            dispatch(setMessageId(data.data[0].id));
            dispatch(setDetailLeadId(data.data[0].recipients[0].id));
            history.push({
                pathname: `/messages/${data.data[0].id}`,
                search: stringify({
                    ...parse(history.location.search),
                }),
            });
        }
        else if (messageId) {
            history.push({
                pathname: `/messages/${messageId}`,
                search: stringify({
                    ...parse(history.location.search),
                }),
            });
        }
    }, [data, dispatch, history, messageId]);

    return [
        useCallback(() => {
            dispatch(
                setMessages({
                    data: data || null,
                    status: isLoading ? "loading" : "finished",
                })
            );
        }, [data, dispatch, isLoading]),
    ];
};

export default useFetchMessage;
