import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreateCallForm, UpdateCallForm } from "src/api/call/call";
import { SendMessageParams } from "src/api/message/message";
import { FilePreview } from "src/types/api/message";
import {
    File as FileType,
    Lead,
    Message,
    Messageable,
    MessageableTypeEnum,
    MessageType,
    ReplyToMessage,
} from "src/types/api/ticket";

interface MessageState {
    messages: Message[] | null;
    leadMessageTo: {
        id: number;
        email: string;
        name?: string;
        phone?: string;
    }; // to save lead email when create new mail message
    loadingSendMessage: boolean;
    filesUploaded: FilePreview[];
    loadingGetMessage: boolean;
    loadingUpdateCall: boolean;
    limit: number;
    total: number;
    sent: boolean;
}

const initialState: MessageState = {
    messages: null,
    leadMessageTo: {
        id: 0,
        email: "",
    },
    loadingSendMessage: false,
    filesUploaded: [],
    loadingGetMessage: false,
    loadingUpdateCall: false,
    limit: 20,
    total: 0,
    sent: false,
};

const messageSlices = createSlice({
    name: "lead/messages",
    initialState,
    reducers: {
        doSetMessages(state, action: PayloadAction<Message[]>) {
            const reverseArray = (array: Message[]) => {
                const result: Message[] = [];
                for (let i = 0; i < array.length; i += 1) {
                    result.unshift(array[i]);
                }
                return result;
            };
            state.messages = reverseArray(action.payload);
        },
        doSetLeadMessageTo(
            state,
            action: PayloadAction<{
                id: number;
                email: string;
                name?: string;
            }>
        ) {
            const { id, email, name } = action.payload;
            state.leadMessageTo = {
                id,
                email,
                name,
            };
        },
        doSetLimitDefault(state) {
            state.limit = 20;
        },
        doSetLimit(state) {
            state.limit += 20;
        },
        doSetTotal(state, action: PayloadAction<number>) {
            state.total = action.payload;
        },

        doSendMessage(
            state,
            action: PayloadAction<{
                form: SendMessageParams;
                files?: FileType[];
                leadId: number;
                onSuccess?: () => void;
            }>
        ) {
            state.loadingSendMessage = true;
            state.sent = !state.sent;
        },
        doSendMessageSuccess(state, action: PayloadAction<Message>) {
            state.loadingSendMessage = false;
            if (state.messages) {
                state.messages = [...state.messages, action.payload];
            } else state.messages = [action.payload];
        },
        doSendMessageFail(state) {
            state.loadingSendMessage = false;
        },
        doGetLeadMessages(state, action: PayloadAction<number>) {},
        doGetLeadMessagesSuccess(state, action: PayloadAction<Message[]>) {
            state.messages = action.payload;
        },
        doGetLeadMessageFail(state) {},
        doUploadFilesMessage(state, action: PayloadAction<FilePreview[]>) {
            state.filesUploaded = action.payload;
        },
        doUploadFilesMessageSuccess(
            state,
            action: PayloadAction<{
                index: number;
                file: FileType | null;
                preview: File;
            }>
        ) {
            state.filesUploaded[action.payload.index] = {
                preview: action.payload.preview,
                file: action.payload.file,
            };
        },
        doUploadFilesMessageFail(state) {},
        doClearFilesAttachment(state) {
            state.filesUploaded = [];
        },
        doCreateCall(
            state,
            action: PayloadAction<{
                form: CreateCallForm;
                recipient: Lead;
                onFail?: (err: string) => void;
            }>
        ) {
            const messageTemp: Message = {
                id: 1,
                type: MessageType.Outbound,
                message: null,
                metadata: null,
                messageable_id: 1,
                created_at: new Date(),
                updated_at: new Date(),
                channel_id: 0,
                staff_id: null,
                staff: null,
                messageable_type: MessageableTypeEnum.AppModelsCallMessage,
                messageable: {
                    id: 1,
                    created_at: new Date(),
                    updated_at: new Date(),
                    question: null,
                    appointment_id: null,
                    type: null,
                    estimate_id: null,
                    qualification_id: null,
                    direction: MessageType.Outbound,
                    status: "initiated",
                    reply_to: "",
                    reply_to_name: "",
                },
                to: null,
                from_system: true,
                pivot: null,
                channel: null,
                recipients: [action.payload.recipient],
                ticket: null,
                ticket_id: null,
                cc: null,
                is_conversation: false,
                files: [],
                status: "on_queue",
                failed_message: "",
                reply_to: null,
                sender: null,
            };
            if (state.messages) {
                /* Create a temp message type call before call api completed */
                state.messages = [
                    ...state.messages,
                    {
                        ...messageTemp,
                        id:
                            Number(
                                state.messages[state.messages.length - 1].id
                            ) + 1,
                        messageable_id:
                            Number(
                                state.messages[state.messages.length - 1].id
                            ) + 1,
                    },
                ];
            } else state.messages = [messageTemp];
        },
        doUpdateCall(
            state,
            action: PayloadAction<{
                id: number;
                form: UpdateCallForm;
                leadId?: number;
                onFail?: (err: string) => void;
                onSuccess?: () => void;
            }>
        ) {
            state.loadingUpdateCall = true;
        },
        doUpdateCallSuccess(
            state,
            action: PayloadAction<{
                id: number;
                messageable: Messageable;
            }>
        ) {
            state.loadingUpdateCall = false;
            const { id, messageable } = action.payload;
            if (state.messages) {
                state.messages = state.messages.map((m) =>
                    m.id === id ? { ...m, messageable } : m
                );
            }
        },
        doUpdateCallFail(state) {
            state.loadingUpdateCall = false;
        },

        doGetMessage(state, action: PayloadAction<number>) {
            state.loadingGetMessage = true;
        },
        doGetMessageSuccess(
            state,
            action: PayloadAction<{
                replyToMessage: ReplyToMessage;
                messageId: number;
            }>
        ) {
            state.loadingGetMessage = false;

            if (state.messages) {
                state.messages = state.messages.map((i) =>
                    i.id === action.payload.messageId
                        ? {
                              ...i,
                              reply_to_message: action.payload.replyToMessage,
                          }
                        : i
                );
            }
        },
    },
});

export const {
    doSetMessages,
    doSetLeadMessageTo,
    doSendMessage,
    doSendMessageSuccess,
    doSendMessageFail,
    doGetLeadMessageFail,
    doGetLeadMessages,
    doGetLeadMessagesSuccess,
    doUploadFilesMessage,
    doUploadFilesMessageSuccess,
    doUploadFilesMessageFail,
    doClearFilesAttachment,
    doCreateCall,
    doGetMessage,
    doGetMessageSuccess,
    doUpdateCall,
    doUpdateCallFail,
    doUpdateCallSuccess,
    doSetLimitDefault,
    doSetLimit,
    doSetTotal,
} = messageSlices.actions;

export default messageSlices.reducer;
