import { storeAuditApi } from "src/api/audit/audit";
import { updateList } from "src/redux/slices/ticket/list";
import { updateMention } from "src/redux/slices/ticket/mention";
import { updateSuccess as updateNote } from "src/redux/slices/ticket/note";
import { RootState } from "src/redux/store";
import { all, call, put, takeLatest, select } from "typed-redux-saga";
import { fail, create, createSuccess } from "../../slices/ticket/audit";

function* createAuditSaga(action: ReturnType<typeof create>) {
    try {
        const { type, ticketId, noteId } = action.payload;
        const { tickets, notes, mentions } = yield* select(
            (store: RootState) => ({
                tickets: store.ticket.list.data?.data,
                notes: store.ticket.note.data?.data,
                mentions: store.ticket.mention.data?.data,
            })
        );

        if (ticketId) {
            const response = yield* call(storeAuditApi, {
                type,
                auditable_id: ticketId,
                auditable_type: "App\\Models\\Ticket",
            });

            if (tickets) {
                yield* put(
                    updateList(
                        tickets.map((ticket) => {
                            if (ticket.id !== action.payload.ticketId) {
                                return ticket;
                            }

                            return {
                                ...ticket,
                                audits: [...ticket.audits, response],
                            };
                        })
                    )
                );
            }
        } else if (noteId) {
            const response = yield* call(storeAuditApi, {
                type,
                auditable_id: noteId,
                auditable_type: "App\\Models\\Note",
            });

            const note = notes?.find((i) => i.id === noteId);

            if (note) {
                yield* put(
                    updateNote({
                        ...note,
                        audits: [...note.audits, response],
                    })
                );
            }

            const mention = mentions?.find((i) => i.note_id === noteId);

            if (mention) {
                yield* put(
                    updateMention({
                        ...mention,
                        note: {
                            ...mention.note,
                            audits: [...mention.note?.audits, response],
                        },
                    })
                );
            }
        }
        yield* put(createSuccess());
    } catch (error) {
        yield* put(fail());
    }
}

export function* auditSaga(): Generator {
    yield all([takeLatest(create, createAuditSaga)]);
}
