import { Avatar, CardBody } from "@doar/components";
import moment from "moment";
import { FC, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getStaffsApi } from "src/api/staff/staff";
import ClockIcon from "src/components/svg/ClockIcon";
import HospitalIcon from "src/components/svg/HospitalIcon";
import LocationIcon from "src/components/svg/LocationIcon";
import StethoscopeIcon from "src/components/svg/StethoscopeIcon";
import { Staff } from "src/types/api/staff";
import { Messageable } from "src/types/api/ticket";
import { StyledSeenAllText } from "../estimate/style";
import {
    StyledCard,
    StyledContentItem,
    StyledContentText,
    StyledContentTitle,
    StyledProcedureContentItem,
    StyledProcedureItem,
    StyledProceduresCol,
    StyledSubTitle,
} from "./style";

type Props = {
    appointment: Messageable;
};

const Appointment: FC<Props> = ({ appointment }) => {
    const [showAll, setShowAll] = useState(false);
    const { data } = useQuery("fetchProvidersForAppointment", () =>
        getStaffsApi(null)
    );
    const staff: Staff | null = useMemo(() => {
        if (appointment.participants) {
            const participant = appointment.participants[0];

            if (participant && data) {
                return (
                    data.data.find(
                        (i) => i.id === participant.participable_id
                    ) || null
                );
            }

            return null;
        }
        return null;
    }, [appointment.participants, data]);

    return (
        <StyledCard>
            <CardBody>
                <StyledSubTitle>Appointment Detail</StyledSubTitle>
                <div>
                    <StyledContentItem>
                        <StyledContentTitle>
                            <StethoscopeIcon /> <span>Provider:</span>
                        </StyledContentTitle>

                        <StyledContentText>
                            <Avatar size="xs" status="online">
                                <img
                                    src={
                                        staff?.photo ||
                                        `https://ui-avatars.com/api/?name=${String(
                                            staff?.first_name
                                        )}+${String(staff?.last_name)}`
                                    }
                                    alt="Avatar"
                                />
                            </Avatar>
                            <span>
                                {[staff?.first_name, staff?.last_name].join(
                                    " "
                                )}
                            </span>
                        </StyledContentText>
                    </StyledContentItem>
                    {appointment.location && (
                        <StyledContentItem>
                            <StyledContentTitle>
                                <LocationIcon /> <span>Location:</span>
                            </StyledContentTitle>

                            <StyledContentText>
                                {appointment.location.name.toUpperCase()}
                            </StyledContentText>
                        </StyledContentItem>
                    )}
                    {appointment.start_time && (
                        <StyledContentItem>
                            <StyledContentTitle>
                                <ClockIcon />
                                <span>Time:</span>
                            </StyledContentTitle>
                            <StyledContentText>
                                {moment(appointment.start_time).format(
                                    "hh A MMM DD, YYYY"
                                )}
                            </StyledContentText>
                        </StyledContentItem>
                    )}
                    {appointment.procedures &&
                        appointment.procedures.length > 0 && (
                            <StyledProcedureContentItem>
                                <StyledContentTitle>
                                    <HospitalIcon />
                                    <span>Procedures:</span>
                                </StyledContentTitle>
                                <StyledProceduresCol>
                                    <>
                                        {appointment.procedures
                                            .filter(
                                                (_, index) =>
                                                    showAll || index < 2
                                            )
                                            .map((procedure) => (
                                                <StyledProcedureItem
                                                    key={procedure.id}
                                                >
                                                    {procedure.name}
                                                </StyledProcedureItem>
                                            ))}

                                        {appointment.procedures.length > 2 && (
                                            <StyledSeenAllText
                                                onClick={() =>
                                                    setShowAll(!showAll)
                                                }
                                            >
                                                {!showAll ? "See all" : "Hide"}{" "}
                                                (
                                                {
                                                    appointment?.procedures
                                                        ?.length
                                                }
                                                )
                                            </StyledSeenAllText>
                                        )}
                                    </>
                                </StyledProceduresCol>
                            </StyledProcedureContentItem>
                        )}
                </div>
            </CardBody>
        </StyledCard>
    );
};

export default Appointment;
