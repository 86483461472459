import React from "react";
import Layout from "../../layouts";
import Wrapper from "../../containers/apps/ticket/wrapper";
import Sidebar from "../../containers/apps/ticket/sidebar";
import Main from "../../containers/apps/ticket/message/main-message";
import SEO from "../../components/seo";
import GroupMessage from "../../containers/apps/ticket/message";

const Messages: React.FC = () => {
    return (
        <Layout hasSidebar hideFooter>
            <SEO />
            <Wrapper>
                <Sidebar />
                <GroupMessage />
                <Main />
            </Wrapper>
        </Layout>
    );
};

export default Messages;
