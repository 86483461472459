import { Textarea, InputGroup } from "@doar/components";
import styled, { themeGet, css } from "@doar/shared";

export const StyledForm = styled.form`
    min-height: 500px;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const StyledTextarea = styled(({ ...rest }) => <Textarea {...rest} />)<{
    $hasError?: boolean;
}>`
    flex-grow: 1;
    margin-top: 10px;
    resize: none;
    border-bottom: 1px solid ${themeGet("colors.gray200")};
    ${({ $hasError }) =>
        $hasError &&
        css`
            border-bottom: 1px solid ${themeGet("colors.danger")};
        `}
`;

export const StyledInputGroup = styled(({ ...rest }) => (
    <InputGroup {...rest} />
))`
    display: block;
`;

export const StyledToLabel = styled.div`
    position: absolute;
    color: gray;
    z-index: 10;
    top: 10px;
    left: 3px;
`;

export const StyledSmsIcon = styled.img`
    position: absolute;
    z-index: 10;
    width: 20px;
    bottom: 12px;
    left: 50px;
`;
