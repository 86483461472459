import { Badge } from "@doar/components";
import styled, { device, themeGet, tinycolor, css } from "@doar/shared/styled";

export const StyledGroup = styled.div`
    width: 100vw;
    position: absolute;
    top: 0;
    left: 100vw;
    bottom: 0;
    transition: all 0.3s;
    @media (min-width: 375px) {
        left: 220px;
    }
    ${device.medium} {
        width: auto;
        right: 0;
        left: 220px;
    }
    ${device.xlarge} {
        width: 320px;
        border-right: 1px solid ${themeGet("colors.border")};
    }
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            background-color: ${tinycolor(themeGet("colors.gray900")(props))
                .darken(5)
                .toString()};
        `}
`;

export const StyledHeader = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 55px;
    background-color: #fff;
    border-bottom: 1px solid ${themeGet("colors.border")};
    display: flex;
    align-items: center;
    padding: 0 20px;
    .search {
        color: ${themeGet("colors.text3")};
        align-self: center;
        width: 18px;
        stroke-width: 2.8px;
        margin-right: 10px;
        margin-top: -2px;
    }
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            background-color: ${tinycolor(themeGet("colors.gray900")(props))
                .darken(3)
                .toString()};
        `}
`;

export const StyledBody = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const StyledTopDiv = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid ${themeGet("colors.border")};
`;

export const StyledLabel = styled.h6`
    display: block;
    padding: 6px 20px;
    background-color: ${(props) =>
        !!props.theme &&
        css`
            ${tinycolor(themeGet("colors.light")(props))
                .setAlpha(0.8)
                .toRgbString()}
        `};
    text-transform: uppercase;
    font-size: 10px;
    font-family: ${themeGet("fonts.interUi")};
    font-weight: 500;
    letter-spacing: 0.5px;
    color: ${themeGet("colors.text3")};
    margin: 1px 0;
    &:first-of-type {
        margin-top: 0;
    }
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            background-color: ${tinycolor(themeGet("colors.gray900")(props))
                .darken(7)
                .toString()};
        `}
`;

export const StyledList = styled.div``;

export const StyledReadMore = styled.div`
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 1px;
    button {
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 500;
        min-height: inherit;
        border-width: 0;
    }
`;

export const StyledLoadingWrapper = styled.div<{ hasData: boolean }>`
    height: 100%;

    ${({ hasData }) =>
        !hasData &&
        css`
            flex: 1;
        `}
`;

export const StyledNoDataWrapper = styled.div`
    margin-bottom: 50px;
    line-height: 16px;
    display: inline-flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    justify-content: center;
    align-items: center;

    img {
        margin-bottom: 25px;
    }

    p {
        color: #8392a5;
        font-family: "IBM Plex Sans", sans-serif;
        &.text {
            font-size: 16px;
            font-weight: 600;
        }
        &.note {
            line-height: 21px;
            font-size: 14px;
            font-weight: 400;
            margin-top: -8px;
        }
    }
`;

export const StyledItemWrap = styled.div`
    display: flex;
    align-items: stretch;
`;

export const StyledMarkSeenWrap = styled.div`
    text-align: end;
    margin-top: 10px;
`;

export const StyledSenderWrap = styled.div`
    cursor: pointer;
`;

export const StyledLoading = styled.div`
    display: flex;
    position: absolute;
    width: 100%;
    height: calc(100% - 2px);
    top: 1px;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background: #fff;
`;

export const StyledFilterWrap = styled.div`
    .dropdown-menu {
        right: 0;
        left: auto;
        min-width: 8rem;
    }
    .dropdown-item {
        display: flex;
        justify-content: space-between;
    }
`;

export const StyledFilterIcon = styled.span`
    height: 20px;
    position: relative;
    left: -5px;
    top: 1px;
`;

export const StyledBadge = styled(({ ...rest }) => <Badge {...rest} />)`
    background: ${themeGet("colors.gray500")};
    color: ${themeGet("colors.white")};
    height: 20px;
    width: 30px;
    border-radius: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
`;
