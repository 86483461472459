import { Badge, Button, Card, CardBody } from "@doar/components";
import styled, { css, themeGet, tinycolor } from "@doar/shared/styled";

export const StyledCard = styled(({ ...rest }) => <Card {...rest} />)`
    @media (min-width: 1600px) {
        width: 75%;
    }
    /* border: none; */
    box-shadow: none;
    width: 100%;
    position: relative;
    border-radius: 5px;
    cursor: pointer;
    max-width: 500px;
    ${(props) => {
        if (props.isOutbound) {
            return css`
                margin-left: auto;
                background-color: ${themeGet("colors.primary")};
                color: #fff;
                border-top-right-radius: 0;
                &:before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    z-index: 1;
                    border-color: ${themeGet("colors.primary")} transparent
                        transparent transparent;
                    position: absolute;
                    top: -1px;
                    border-width: 13px 16px 0 0;
                    right: -16px;
                }
                &:after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-color: ${themeGet("colors.border")} transparent
                        transparent transparent;
                    position: absolute;
                    top: -1px;
                    border-width: 15px 18px 0 0;
                    right: -18px;
                }
            `;
        }

        return css`
            border-top-left-radius: 0;
            &:before {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                z-index: 1;
                border-width: 0 13px 8px 0;
                border-color: transparent #fff transparent transparent;
                position: absolute;
                left: -13px;
                top: 0px;
            }
            &:after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 15px 10px 0;
                border-color: transparent ${themeGet("colors.border")}
                    transparent transparent;
                position: absolute;
                left: -15px;
                top: -1px;
            }
        `;
    }}
`;
export const StyledTargetUser = styled.div`
    display: flex;
    align-items: center;
    margin-top: 8px;
    justify-content: space-between;
`;

export const StyledHeader = styled.div<{
    isOutbound?: boolean;
}>`
    display: flex;
    ${({ isOutbound }) =>
        isOutbound &&
        css`
            h6 {
                color: #fff;
            }
        `}
`;

export const StyledContentMessage = styled.div<{ $createdByStaff?: boolean }>`
    margin-top: 16px;
    width: 100%;
    max-height: 300px;
    overflow: auto;
    border: none;
    ${({ $createdByStaff }) =>
        $createdByStaff &&
        css`
            * {
                color: #fff;
            }
        `}
`;

export const StyledDivider = styled.div`
    height: 0;
    overflow: hidden;
    border-top: 1px solid ${themeGet("colors.gray200")};
    margin: 16px 0;
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            border-color: ${tinycolor(themeGet("colors.white")(props))
                .setAlpha(0.06)
                .toRgbString()};
        `}
`;

export const StyledFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledReplyText = styled(({ ...rest }) => <Button {...rest} />)`
    margin: 0;
    text-decoration: underline;
`;

export const StyledSubjectText = styled.h6`
    display: inline-block;
    margin-bottom: 0;
    /* width: 30ch;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis; */
`;

export const StyledBadge = styled(({ ...rest }) => <Badge {...rest} />)`
    font-weight: 600;
`;

export const StyledLeftPoint = styled.div`
    width: 0;
    height: 0;
    border-left: 2vh solid transparent;
    border-right: 2vh solid transparent;
    border-top: 10vh solid #fff;
    position: absolute;
    top: 0;
    left: -25px;
    transform: rotate(120deg);
`;

export const StyledCardBody = styled(({ ...rest }) => <CardBody {...rest} />)`
    overflow: hidden;
`;
export const StyledReplyButton = styled(({ ...rest }) => <Button {...rest} />)`
    /* position: absolute;
    right: -100px;
    top: 10px; */
    min-width: 80px;
    display: flex;
    align-items: center;
    margin-left: 20px;
`;
export const StyledMoreButton = styled(({ ...rest }) => <Button {...rest} />)`
    opacity: 0.5;
`;
