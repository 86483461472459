import { FC } from "react";
import { Avatar, Button, MediaBody, Spinner, Text } from "@doar/components";
import moment from "moment";
import { Mention } from "src/types/api/ticket";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { create as markSeen } from "src/redux/slices/ticket/audit";
import {
    StyledMedia,
    StyledTop,
} from "../../../../components/apps/ticket/group-item/style";
import { MentionsHighlightDisplay } from "./mention-hightlight-display";
import { StyledItemWrap, StyledLoading, StyledMarkSeenWrap } from "./style";

interface IProps {
    mention: Mention;
    onClick?: () => void;
}

const GroupItem: FC<IProps> = ({ mention, onClick }) => {
    const { status, detail, noteIsSeen } = useAppSelector((state) => ({
        status: state.ticket.audit.status,
        noteIsSeen: state.ticket.audit.noteIsSeen,
        detail: state.ticket.mention.details,
    }));
    const dispatch = useAppDispatch();

    const onMarkSeen = () => {
        dispatch(
            markSeen({
                type: "read",
                noteId: mention.note_id,
            })
        );
    };

    return (
        <StyledMedia
            className={mention.id !== detail?.id ? "unread" : "read"}
            onClick={onClick}
            p="15px"
            style={{ display: "block" }}
        >
            <StyledItemWrap>
                <Avatar status="online">
                    <img
                        src={
                            (mention.mentionable?.photo ?? "") ||
                            `https://ui-avatars.com/api/?name=${String(
                                mention.mentionable?.first_name ?? ""
                            )}+${String(mention.mentionable?.last_name ?? "")}`
                        }
                        alt="avatar"
                    />
                </Avatar>
                <MediaBody ml="15px">
                    <StyledTop>
                        <Text as="b" fontSize="12px">
                            @
                            {[
                                mention?.mentionable?.first_name,
                                mention?.mentionable?.last_name,
                            ].join(" ")}
                        </Text>
                        <Text as="span" fontSize="12px" marginLeft="auto">
                            mentioned you at
                        </Text>
                        <Text as="span" fontSize="11px" marginLeft="5px">
                            {moment(mention.created_at).format("HH:mm")}
                        </Text>
                    </StyledTop>
                    <MentionsHighlightDisplay value={mention.note.note ?? ""} />
                </MediaBody>
            </StyledItemWrap>
            {!mention.note.audits?.find(
                (i) => i.auditable_id === mention.note_id
            ) && (
                <StyledMarkSeenWrap onClick={(e) => e.stopPropagation()}>
                    <Button
                        disabled={status === "loading"}
                        variant="texted"
                        color="info"
                        onClick={onMarkSeen}
                    >
                        Mark Seen
                    </Button>
                </StyledMarkSeenWrap>
            )}
            {status === "loading" && noteIsSeen === mention.note_id && (
                <StyledLoading>
                    <Spinner />
                </StyledLoading>
            )}
        </StyledMedia>
    );
};

export default GroupItem;
