import { Avatar, AvatarInitial, Media, Text } from "@doar/components";
import { FC } from "react";
import { StyledMediaBody, StyledSender } from "./style";

interface IProps {
    color?: string;
    image?: string;
    name: string;
    time?: string;
    fileName?: string;
    className?: string;
}

const Sender: FC<IProps> = ({
    color,
    image,
    name,
    time,
    fileName,
    className,
}) => {
    return (
        <Media alignItems="center">
            <Avatar size="sm">
                {!image && (
                    <AvatarInitial bg={color}>
                        {name.substring(0, 1)}
                    </AvatarInitial>
                )}
                {image && <img src={image} alt={name} />}
            </Avatar>
            <StyledMediaBody ml="10px" className={className}>
                {fileName ? (
                    <>
                        <StyledSender>{fileName}</StyledSender>
                        <Text fontSize="11px" color="text3">
                            Shared by {name} on {time}
                        </Text>
                    </>
                ) : (
                    <>
                        <StyledSender>{name}</StyledSender>
                        <Text fontSize="11px" color="text3">
                            {time}
                        </Text>
                    </>
                )}
            </StyledMediaBody>
        </Media>
    );
};

export default Sender;
