/* eslint-disable @typescript-eslint/no-unused-vars */
import { Select } from "@doar/components";
import { doUpdateLead } from "src/redux/slices/contacts/lead";
import { FC, useEffect, useState } from "react";
import { toastError, toastSuccess } from "src/utils/toast";
import classNames from "classnames";
import { formatLowerCaseString } from "src/helpers/stringHelpers";
import { STAGE } from "src/helpers/leads/constant";
import { Lead } from "src/types/api/ticket";
import { useAppDispatch } from "src/redux/hooks";
import CustomDropdown from "../../main-view/personal-info/custom-dropdown";
import { StyledWrapSelect, StyledWrapDropdown, StyledMargin } from "./style";

interface IProps {
    lead: Lead | null;
}

const TIME_TO_DELAY_EDIT = 2;

const UPDATE_STAGE = [
    {
        id: 1,
        name: STAGE.new,
    },
    {
        id: 2,
        name: STAGE.followup,
    },
    {
        id: 3,
        name: STAGE.consultBooked,
    },
    {
        id: 4,
        name: STAGE.consultComplete,
    },
    {
        id: 5,
        name: STAGE.quoteSent,
    },
    {
        id: 6,
        name: STAGE.depositPaid,
    },
    {
        id: 7,
        name: STAGE.surgeryBooked,
    },
    {
        id: 8,
        name: STAGE.surgeryComplete,
    },
    {
        id: 9,
        name: STAGE.lost,
    },
    {
        id: 10,
        name: STAGE.notQualified,
    },
];

const DropdownStage: FC<IProps> = ({ lead }) => {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const [value, setValue] = useState("");

    useEffect(() => {
        if (lead) {
            setValue(lead.stage);
        }
    }, [lead]);

    const handleChangeEditStage = (name: string) => {
        setLoading(true);
        dispatch(
            doUpdateLead({
                id: lead?.id || 0,
                form: { stage: name },
                onSuccess: () => {
                    toastSuccess("Lead updated successfully");
                    setLoading(false);
                },
                onFail: (error) => {
                    toastError(error);
                    setLoading(false);
                },
            })
        );
    };
    return (
        <StyledWrapSelect
            className={classNames({
                "is-loading": isLoading,
            })}
        >
            <StyledWrapDropdown>
                <StyledMargin>
                    <CustomDropdown
                        value={
                            value
                                ? formatLowerCaseString(value)
                                : "Select Stage Lead"
                        }
                        items={UPDATE_STAGE}
                        onChange={(item) => {
                            const formatValue = item.name
                                .split(" ")
                                .map((i) => i.toLowerCase())
                                .join("_");
                            setValue(formatValue);
                            handleChangeEditStage(formatValue);
                        }}
                        isConfirm
                        showSearch
                        displayTextCenter
                        timeToDelayEdit={TIME_TO_DELAY_EDIT}
                    />
                </StyledMargin>
            </StyledWrapDropdown>
        </StyledWrapSelect>
    );
};

export default DropdownStage;
