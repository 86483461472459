import {
    Button,
    ModalBody,
    ModalClose,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    Spinner,
} from "@doar/components";
import Modal from "src/components/custom-modal";
import { FC, useState } from "react";
import { X } from "react-feather";
import PhoneCountriesInput from "src/components/phone-countries-input";
import { useAppDispatch } from "src/redux/hooks";
import { doUpdateLead } from "src/redux/slices/contacts/lead";
import { StyledInputWrap } from "./style";

interface IProps {
    show: boolean;
    onClose: () => void;
    leadId: number;
}

const MissingPhoneModal: FC<IProps> = ({ show, onClose, leadId }) => {
    const [value, setValue] = useState("");
    const [error, setError] = useState("");
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);

    const onSubmit = () => {
        setLoading(true);
        dispatch(
            doUpdateLead({
                id: leadId,
                form: {
                    phone: value,
                },
                onSuccess: () => {
                    setLoading(false);
                    onClose();
                    setError("");
                },
                onFail: (err) => {
                    setLoading(false);
                    setError(err);
                },
            })
        );
    };

    return (
        <Modal show={show} onClose={onClose} size="sm">
            <ModalHeader>
                <ModalTitle>This contact is missing a phone number</ModalTitle>
                <ModalClose onClose={onClose}>
                    <X />
                </ModalClose>
            </ModalHeader>
            <ModalBody>
                <StyledInputWrap $hasError={!!error}>
                    <PhoneCountriesInput
                        id="add-missing-phone-number"
                        value={value}
                        onChange={(val) => setValue(val)}
                        errorMessage={error}
                        onSetErrorMessage={(err) => setError(err)}
                    />
                </StyledInputWrap>
            </ModalBody>
            <ModalFooter>
                <Button variant="contained" color="light" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    disabled={!!error || loading || !value}
                    onClick={onSubmit}
                >
                    {loading ? <Spinner size="xs" color="white" /> : "Add"}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default MissingPhoneModal;
