import styled, { device, themeGet } from "@doar/shared/styled";
import {
    ModalClose,
    ModalFooter,
    Input,
    ModalBody,
    ModalHeader,
} from "@doar/components";
import Modal from "src/components/custom-modal";

export const StyledWrapDate = styled.div`
    width: 55%;
`;

export const StyledWrapTime = styled.div`
    width: 45%;
`;

export const StyledError = styled.p`
    margin: 0 10px;
    color: ${themeGet("colors.danger")};
    font-size: 10px;
    display: inline-block;
    position: relative;
    left: -10px;
    top: 7px;
`;

export const StyledClose = styled(({ ...rest }) => <ModalClose {...rest} />)`
    font-weight: 300;
    font-size: 28px;
    line-height: 0.87;
    margin-left: auto;
    color: ${themeGet("colors.text3")};
`;

export const StyledInput = styled(({ ...rest }) => <Input {...rest} />)``;

export const StyledModalBody = styled(({ ...rest }) => <ModalBody {...rest} />)`
    display: grid;
    grid-template-columns: auto;
    padding: 17px 32px 10px 32px;
`;

export const StyledModal = styled(({ ...rest }) => <Modal {...rest} />)`
    & .modal-dialog {
        justify-content: center;
    }
    & .modal-header {
        ${device.large} {
            width: 89%;
        }
        width: 100%;
    }
    & .modal-content {
        ${device.large} {
            width: 75%;
        }

        width: 100%;
        max-height: calc(100vh - 50px);
        overflow: auto;
    }
`;

export const StyledModalHeader = styled(({ ...rest }) => (
    <ModalHeader {...rest} />
))`
    padding: 0;
    align-items: center;
    padding-bottom: 10px;
    margin-left: 31px;
    margin-top: 16px;
`;

export const StyledActionButtons = styled.div`
    position: relative;
    margin-left: auto;
    height: 38px;
    width: 130px;
    text-align: left;
    ${device.small} {
        width: 150px;
    }
`;
export const StyledItemDuplicate = styled.div`
    display: grid;
    padding: 15px 27px 18px 27px;
    grid-template-columns: 45px auto 90px;
    align-items: center;
`;
export const StyledBodyItem = styled.div`
    &.lead {
        margin-top: 30px;
    }

    &.assign {
    }
`;

export const StyledSwitchModal = styled.div`
    display: flex;
    margin: 1px auto 0 0;
`;

export const StyledItemSwitch = styled.div`
    display: flex;
    font-size: 11px;
    border-radius: 3px;
    margin-top: -5px;
    padding: 8px;
    cursor: pointer;
    &.is-step-1 {
        font-weight: 400;
        background : ${themeGet("colors.light")};
        color: : ${themeGet("colors.primary")};
    }
    &.is-step-2 {
        font-weight: 400;
    }
    &.is-done-step-1 {
        background : ${themeGet("colors.light")};
        color: : ${themeGet("colors.primary")};
    }
    &.is-disabled {
        cursor: not-allowed;
        pointer-events: none;
        color: ${themeGet("colors.text3")};
    }
`;

export const StyledTextHeader = styled.div`
    font-weight: 500;
`;

export const StyledButtonDuplicate = styled.div`
    cursor: pointer;
    margin: 10px 0 auto auto;
`;

export const StyledTitleDuplicate = styled.p`
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    max-width: 200px;
    overflow: hidden;
`;

export const StyledWrapLabelDuplicate = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-top: 5px;
    justify-content: space-between;
    & .label {
        font-size: 13px;
        p {
            .label {
                color: ${themeGet("colors.gray400")};
            }
        }
    }
`;

export const StyledWrapLeadDuplicate = styled.div`
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 550px;
    & .duplicate-item {
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            background-color: ${themeGet("colors.light")};
        }
    }
`;

export const StyledBodyRow = styled.div`
    & .assign {
        margin-top: 10px;
    }

    & .resCalendar {
        & .dropdownMenu {
            top: -295px;
        }
    }

    & .resReminder {
        & .dropdownMenu {
            top: -184px;
        }
    }

    & .resTime {
        & .dropdownMenu {
            top: -295px;
            max-height: 286px;
        }
    }

    & .reminder {
        margin-top: 15px;
    }

    & .description {
        margin-top: 15px;
    }
    display: grid;
    width: 100%;
    grid-row-gap: 12px;
    --itemSize: none;
    .region {
        grid-area: auto;
    }

    .btnDelete {
        grid-area: 2 / 3 /1 / 4;
        margin-top: 30px;
    }
    margin-bottom: 20px;

    ${device.medium} {
        --itemSize: 1fr;

        .btnDelete {
            grid-area: 2 / 3 /1 / 4;
            margin-top: 30px;
        }
    }
`;

export const StyledHiddenInput = styled.input`
    display: none;
`;

export const StyledBodyItemTitle = styled.div`
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 14px;
    overflow: hidden;

    & div {
        display: flex;
        justify-content: space-between;
        height: 25px;
    }
`;

export const StyledLogo = styled.div`
    padding-top: 0.33594rem;
    padding-bottom: 0.33594rem;
    margin-right: 1rem;
    font-size: 1.09375rem;
    line-height: inherit;
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding-left: 15px;
    margin-right: 0;
    order: 1;
    ${device.large} {
        width: 240px;
        padding: 0 0 0 20px;
    }
`;

export const StyledBodyItemInput = styled.div`
    width: 100%;
    &.chooseDateandTime {
        display: flex;
        gap: 10px;
    }
    & .desc {
        resize: none;
        min-height: 70px !important;
    }

    &.reminder {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        & div {
            display: flex;
            flex-direction: column;
        }
    }

    & .modal-filter {
        width: 100%;

        & .dropdownToggle {
            height: 55px !important;
        }
    }

    height: 48px;
    min-height: 48px;
    margin-top: 3px;
    ${device.small} {
        min-width: 212px;
    }
    & .disabled {
        pointer-events: none;
        background-color: #f5f6fa;
    }

    & .lead {
        margin-top: 30px;
    }
`;

export const StyledTitle = styled.h6`
    letter-spacing: 0.5px;
    margin: 0 15px 0 0;
    font-weight: 500;
    font-size: 14px;
`;

export const StyledFooter = styled(({ ...rest }) => <ModalFooter {...rest} />)`
    padding: 0;
    margin-bottom: 16px;
    margin-right: 24px;
    padding-top: 27px;
    & .show {
        disable: none;
    }
    &.is-has-error {
        margin-top: 20px;
    }
    & .cancelBtn {
        color: ${themeGet("colors.primary")};
    }
`;
