/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import { Spinner } from "@doar/components";
import classNames from "classnames";
import { FC, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { Copy } from "react-feather";
import ReactTooltip from "react-tooltip";
import { classic } from "@doar/shared/styled/colors";
import CopyToClipboard from "react-copy-to-clipboard";
import { useAppDispatch } from "src/redux/hooks";
import { startMeeting } from "src/redux/slices/ticket/daily";
import { Lead } from "src/types/api/ticket";
import { createCallApi } from "src/api/call/call";
import { doCreateCall } from "src/redux/slices/lead/message";
import { toastError } from "src/utils/toast";
import { doSetSelectedTab } from "src/redux/slices/contacts/lead";
import {
    StyledMenu,
    StyledDropdown,
    StyledMenuItem,
    StyledCopyBtn,
} from "../style";

interface Props {
    lead: Lead | null;
    show: boolean;
    onClose: () => void;
}
const HandleViewPhone: FC<Props> = ({ lead, show, onClose }) => {
    const dispatch = useAppDispatch();
    const [copied, setCopied] = useState(false);
    const [showPhone, setShowPhone] = useState(false);
    const ref = useOnclickOutside(() => {
        onClose();
        setShowPhone(false);
    });

    const viewPhone = () => {
        setShowPhone(true);
        dispatch(doSetSelectedTab("conversations"));
        if (lead) {
            dispatch(
                doCreateCall({
                    form: {
                        to_phone_number: lead.phone,
                        direction: "outbound",
                        status: "initiated",
                        lead_id: lead.id,
                    },
                    recipient: lead,
                    onFail: (err) => toastError(err),
                })
            );
        }
    };

    return (
        <StyledDropdown $show={show} ref={ref}>
            <StyledMenu
                className={classNames("dropdownOutSide", {
                    "view-phone": showPhone,
                })}
            >
                {!showPhone && (
                    <StyledMenuItem variant="texted" onClick={viewPhone}>
                        View Phone Number
                    </StyledMenuItem>
                )}
                {showPhone && (
                    <div className="leadPhone">
                        <div className="phone">{lead?.phone}</div>{" "}
                        <CopyToClipboard
                            text={lead?.phone || ""}
                            onCopy={() => setCopied(true)}
                        >
                            <StyledCopyBtn
                                data-tip={copied ? "Copied!" : "Copy"}
                                data-for="copy-status"
                                className="copyBtn"
                            >
                                <Copy
                                    color={classic.gray700}
                                    size={18}
                                    strokeWidth={2.5}
                                />
                                <ReactTooltip place="top" id="copy-status" />
                            </StyledCopyBtn>
                        </CopyToClipboard>
                    </div>
                )}
            </StyledMenu>
        </StyledDropdown>
    );
};

export default HandleViewPhone;
