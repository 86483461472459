import { hasKey } from "@doar/shared/methods";
import { Button, Spinner } from "@doar/components";
import { yupResolver } from "@hookform/resolvers/yup";
import { parse } from "query-string";
import { FC, KeyboardEvent, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Option } from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import * as yup from "yup";
import SmsIcon from "@doar/shared/images/smsicon.svg";
import { doSendMessage } from "src/redux/slices/lead/message";
import { CustomOption, handleSearchLead, Menu, selectStyle } from "../common";
import {
    StyledForm,
    StyledInputGroup,
    StyledSmsIcon,
    StyledTextarea,
    StyledToLabel,
} from "./style";

interface Props {
    handleModal: () => void;
}

type FormValues = {
    channel: Option;
    to: Option;
    message: string;
};

const schema = yup.object().shape({
    channel: yup.object().required(),
    to: yup.object().required(),
    message: yup.string().required().label("Message"),
});

const SmsForm: FC<Props> = ({ handleModal }) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { location } = history;
    const queryParams = parse(location.search);
    const { channelData, leadMessageTo } = useAppSelector((store) => ({
        channelData: store.ticket.channel.data,
        leadMessageTo: store.lead.message.leadMessageTo,
    }));
    const [isFocusTo, setFocusTo] = useState(false);
    const [phoneReceiver, setPhoneReceiver] = useState("");
    const [loading, setLoading] = useState(false);

    const initChannel = channelData?.data.find(
        (c) =>
            String(c.id) === String(queryParams.channel) ||
            c.channelable_type === "App\\Models\\SmsChannel"
    );

    const methods = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            channel: initChannel
                ? {
                      label: initChannel
                          ? `${[
                                initChannel.name,
                                initChannel.channelable?.phone_number,
                            ]
                                .filter((i) => !!i)
                                .join(" • ")}`
                          : "",
                      value: String(initChannel.id),
                  }
                : undefined,
            to: {
                label: leadMessageTo.name,
                value: String(leadMessageTo.id),
            },
        },
    });
    const {
        handleSubmit,
        control,
        clearErrors,
        formState: { errors },
        watch,
        reset,
        getValues,
    } = methods;

    useEffect(() => {
        reset({
            ...getValues(),
            to: {
                label: leadMessageTo.name,
                value: String(leadMessageTo.id),
            },
        });
    }, [reset, leadMessageTo, getValues]);

    const channelOptions =
        channelData?.data
            .filter((c) => c.channelable_type === "App\\Models\\SmsChannel")
            .map((c) => {
                return {
                    label: `${[c.name, c.channelable?.phone_number]
                        .filter((i) => !!i)
                        .join(" • ")}`,
                    value: String(c.id),
                    icon: SmsIcon,
                };
            }) || [];

    const onSubmit: SubmitHandler<FormValues> = (values) => {
        setLoading(true);
        dispatch(
            doSendMessage({
                form: {
                    message: values.message,
                    channel_id: Number(values.channel.value),
                    to: values.to.value
                        ? [{ lead_id: Number(values.to.value) }]
                        : [{ phone: values.to.label }],
                },
                leadId: leadMessageTo.id,
                onSuccess: () => {
                    reset();
                    clearErrors();
                    setLoading(false);
                    handleModal();
                },
            })
        );
    };

    function onPressEscape<T>(e: KeyboardEvent<T>) {
        if (e.key === "Escape") {
            handleModal();
        }
    }

    const onBlurMessageTo = () => {
        setFocusTo(false);

        if (!isNaN(Number(phoneReceiver))) {
            reset({
                ...getValues(),
                to: { label: phoneReceiver, value: "" },
            });
        }
    };

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <StyledInputGroup>
                {isFocusTo || (!isFocusTo && !!watch("to")) ? (
                    <StyledToLabel>To:</StyledToLabel>
                ) : (
                    ""
                )}
                <Controller
                    control={control}
                    name="to"
                    render={({ field }) => (
                        <AsyncCreatableSelect
                            cacheOptions
                            loadOptions={handleSearchLead}
                            styles={selectStyle(
                                hasKey(errors, "to"),
                                isFocusTo || (!isFocusTo && !!watch("to"))
                                    ? "30px"
                                    : "0"
                            )}
                            isClearable={false}
                            components={{
                                IndicatorSeparator: null,
                                DropdownIndicator: null,
                                Menu,
                            }}
                            placeholder="Recipients"
                            {...field}
                            onFocus={() => setFocusTo(true)}
                            onBlur={onBlurMessageTo}
                            onKeyDown={(e: KeyboardEvent<HTMLDivElement>) =>
                                onPressEscape<HTMLDivElement>(e)
                            }
                            onInputChange={(val) => setPhoneReceiver(val)}
                        />
                    )}
                />
            </StyledInputGroup>
            <StyledInputGroup>
                <StyledToLabel>From:</StyledToLabel>
                <StyledSmsIcon src={SmsIcon} alt="" />
                <Controller
                    control={control}
                    name="channel"
                    render={({ field }) => (
                        <AsyncSelect
                            cacheOptions
                            defaultOptions={channelOptions}
                            styles={selectStyle(
                                hasKey(errors, "channel"),
                                "75px"
                            )}
                            isClearable={false}
                            isSearchable={false}
                            components={{
                                IndicatorSeparator: null,
                                DropdownIndicator: null,
                                Option: CustomOption,
                                Menu,
                            }}
                            placeholder="From"
                            {...field}
                            onKeyDown={(e: KeyboardEvent<HTMLDivElement>) =>
                                onPressEscape<HTMLDivElement>(e)
                            }
                        />
                    )}
                />
            </StyledInputGroup>

            <Controller
                control={control}
                name="message"
                render={({ field }) => (
                    <StyledTextarea
                        type="text"
                        {...field}
                        feedbackText={errors?.message?.message}
                        state={hasKey(errors, "message") ? "error" : "success"}
                        showState={!!hasKey(errors, "message")}
                        customStyle="noborder"
                        $hasError={!!hasKey(errors, "message")}
                        onKeyDown={(
                            e: KeyboardEvent<
                                HTMLInputElement | HTMLTextAreaElement
                            >
                        ) =>
                            onPressEscape<
                                HTMLInputElement | HTMLTextAreaElement
                            >(e)
                        }
                    />
                )}
            />

            <div>
                <Button type="submit" mt="10px">
                    {loading ? <Spinner size="xs" color="white" /> : "Send sms"}
                </Button>
            </div>
        </StyledForm>
    );
};

export default SmsForm;
