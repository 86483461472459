import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import chartsReducer from './charts';

const persistConfig = {
    key: 'filter',
    storage,
    whitelist: [],
}

const reportsReducer = combineReducers({
    charts: persistReducer(persistConfig, chartsReducer),
});

export default reportsReducer;
