import { Spinner } from "@doar/components";
import { classic } from "@doar/shared/styled/colors";
import { FC, useState } from "react";
import { Check, PhoneIncoming, PhoneMissed, X } from "react-feather";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { doUpdateCall } from "src/redux/slices/lead/message";
import { CallStatus } from "src/types/api/call";
import { Message, MessageType } from "src/types/api/ticket";
import {
    StyledButton,
    StyledButtonWrap,
    StyledCallIcon,
    StyledCallWrap,
    StyledCard,
    StyledContentWrap,
    StyledDirection,
    StyledRecordingWrap,
    StyledStatus,
} from "./style";
import Recording from "./recording";

interface IProps {
    message: Message;
}

const CallMessage: FC<IProps> = ({ message }) => {
    const DANGER_COLOR = "#f56d7a";
    const dispatch = useAppDispatch();
    const loading = useAppSelector(
        (store) => store.lead.message.loadingUpdateCall
    );
    const [buttonIsLoading, setButtonIsLoading] = useState<
        "answered" | "missed" | null
    >(null);

    const onUpdateCall = (status: CallStatus) => {
        setButtonIsLoading(status === "completed" ? "answered" : "missed");
        dispatch(
            doUpdateCall({
                id: message.messageable_id,
                leadId: message.messageable.lead_id,
                form: {
                    status,
                },
                onSuccess: () => setButtonIsLoading(null),
            })
        );
    };

    const renderStatus = (status: CallStatus | undefined) => {
        if (status === "completed") {
            return "Answered";
        }
        if (status === "no-answer") {
            return "Missed Call";
        }
        return "";
    };
    return (
        <StyledCard isOutbound={message.type === MessageType.Outbound}>
            <StyledCallWrap>
                <StyledCallIcon
                    $isDarkTheme={message.type === MessageType.Outbound}
                >
                    {message.messageable.status &&
                        (message.messageable.status === "no-answer" ||
                        message.messageable.status === "busy" ? (
                            <PhoneMissed
                                color={DANGER_COLOR}
                                strokeWidth={2}
                                size={20}
                            />
                        ) : (
                            <PhoneIncoming
                                color={classic.success}
                                strokeWidth={2}
                                size={20}
                            />
                        ))}
                </StyledCallIcon>
                <StyledContentWrap>
                    <StyledDirection
                        $isDarkTheme={message.type === MessageType.Outbound}
                    >
                        {message.type === MessageType.Inbound
                            ? "Inbound Call"
                            : "Outbound Call"}
                    </StyledDirection>
                    <StyledStatus>
                        <div>{renderStatus(message.messageable.status)}</div>
                    </StyledStatus>
                </StyledContentWrap>
            </StyledCallWrap>
            {message.messageable.status === "initiated" && (
                <StyledButtonWrap>
                    <StyledButton
                        $isDarkTheme={message.type === MessageType.Outbound}
                        onClick={() => onUpdateCall("completed")}
                    >
                        {loading && buttonIsLoading === "answered" ? (
                            <div style={{ height: "18px" }}>
                                <Spinner
                                    size="xs"
                                    color={
                                        message.type === MessageType.Outbound
                                            ? "light"
                                            : "primary"
                                    }
                                />
                            </div>
                        ) : (
                            <>
                                <Check
                                    color={classic.success}
                                    strokeWidth={1.5}
                                    size={20}
                                    style={{ marginRight: "5px" }}
                                />
                                Answered
                            </>
                        )}
                    </StyledButton>
                    <StyledButton
                        $isDarkTheme={message.type === MessageType.Outbound}
                        onClick={() => onUpdateCall("no-answer")}
                    >
                        {loading && buttonIsLoading === "missed" ? (
                            <div style={{ height: "18px" }}>
                                <Spinner
                                    size="xs"
                                    color={
                                        message.type === MessageType.Outbound
                                            ? "light"
                                            : "primary"
                                    }
                                />
                            </div>
                        ) : (
                            <>
                                <X
                                    color={DANGER_COLOR}
                                    strokeWidth={1.5}
                                    size={20}
                                    style={{ marginRight: "5px" }}
                                />
                                Missed call
                            </>
                        )}
                    </StyledButton>
                </StyledButtonWrap>
            )}
            {message.messageable.recording && (
                <StyledRecordingWrap
                    $isDarkTheme={message.type === MessageType.Outbound}
                >
                    <Recording
                        url={message.messageable.recording}
                        isDarkTheme={message.type === MessageType.Outbound}
                    />
                </StyledRecordingWrap>
            )}
        </StyledCard>
    );
};

export default CallMessage;
