import { classic } from "@doar/shared/styled/colors";
import { parse, stringify } from "query-string";
import { FC, useEffect, useState } from "react";
import { Mail, Video, MessageSquare, Phone } from "react-feather";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { doSetSelectedTab } from "src/redux/slices/contacts/lead";
import { startMeeting } from "src/redux/slices/ticket/daily";
import { Lead } from "src/types/api/ticket";
import HandleAddPhone from "../dropdown-add-phone-number";
import HandleAddEmail from "../dropdown-add-email";
import HandleVideoCall from "../dropdown-video-call";
import HandleViewPhone from "../dropdown-view-phone";
import { StyledButtonMedia, StyledMedia, StyledMediaWrap } from "../style";

interface Props {
    lead: Lead | null;
}

const MediaButton: FC<Props> = ({ lead }) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { errorMessage, urlRoom } = useAppSelector((store) => ({
        errorMessage: store.ticket.daily.errorMessage,
        urlRoom: store.ticket.daily.room,
    }));
    const [buttonIsHandling, setButtonIsHandling] = useState<
        "call" | "text" | "video" | "email" | null
    >(null);
    const [handleType, setHandleType] = useState<
        "viewPhone" | "addPhone" | "callVideo" | "addEmail" | null
    >(null);

    useEffect(() => {
        if (errorMessage) {
            setHandleType("callVideo");
        }
        if (urlRoom?.url) {
            setHandleType(null);
        }
    }, [errorMessage, urlRoom]);

    const changeTab = (form: "sms" | "email") => {
        if (lead) {
            dispatch(doSetSelectedTab("conversations"));
            history.push({
                search: stringify({
                    ...parse(history.location.search),
                    compose: form,
                }),
            });
        }
    };

    const openSmsForm = () => {
        changeTab("sms");
    };
    const onOpenTextCompose = () => {
        setButtonIsHandling("text");
        if (!lead?.phone) {
            setHandleType("addPhone");
            return;
        }
        openSmsForm();
    };

    const onOpenCallCompose = () => {
        if (!lead?.phone) {
            setHandleType("addPhone");
        } else {
            setHandleType("viewPhone");
        }
        setButtonIsHandling("call");
    };

    const onOpenEmailForm = () => {
        changeTab("email");
        setButtonIsHandling(null);
    };

    const onOpenEmailCompose = () => {
        setButtonIsHandling("email");
        if (!lead?.email) {
            setHandleType("addEmail");
            return;
        }
        onOpenEmailForm();
    };

    const onClickVideo = () => {
        if (!lead?.phone) {
            setHandleType("addPhone");
        } else {
            setHandleType("callVideo");
        }
        setButtonIsHandling("video");
    };

    const onCloseDropdownVideo = (type: "addPhone" | "callVideo") => {
        if (buttonIsHandling === "video" && type === handleType) {
            setButtonIsHandling(null);
        }
    };

    const onCloseDropdownText = () => {
        if (buttonIsHandling === "text") {
            setButtonIsHandling(null);
        }
    };

    const onCloseDropdownEmail = () => {
        if (buttonIsHandling === "email") {
            setButtonIsHandling(null);
        }
    };

    const onCloseDropdownCall = (type: "addPhone" | "viewPhone") => {
        if (buttonIsHandling === "call" && type === handleType) {
            setButtonIsHandling(null);
        }
    };

    return (
        <StyledMediaWrap>
            <StyledMedia>
                <StyledButtonMedia
                    $color={classic.primary}
                    onClick={onOpenTextCompose}
                >
                    <div className="icon">
                        <MessageSquare
                            strokeWidth={1.7}
                            color="#fff"
                            size={20}
                        />
                    </div>
                </StyledButtonMedia>
                <div className="textMedia">Text</div>
                <HandleAddPhone
                    lead={lead}
                    show={
                        !!(
                            buttonIsHandling === "text" &&
                            handleType === "addPhone"
                        )
                    }
                    onClose={onCloseDropdownText}
                    onAddPhoneSuccess={() => openSmsForm()}
                    onAddPhoneFail={() => setHandleType("addPhone")}
                />
            </StyledMedia>
            <StyledMedia>
                <StyledButtonMedia
                    $color={classic.teal}
                    onClick={onOpenCallCompose}
                >
                    <div className="icon">
                        <Phone strokeWidth={1.7} color="#fff" size={20} />
                    </div>
                </StyledButtonMedia>
                <div className="textMedia">Call</div>
                <HandleAddPhone
                    lead={lead}
                    show={
                        !!(
                            buttonIsHandling === "call" &&
                            handleType === "addPhone"
                        )
                    }
                    onClose={() => onCloseDropdownCall("addPhone")}
                    onAddPhoneSuccess={() => {
                        setHandleType("viewPhone");
                        setButtonIsHandling("call");
                    }}
                    onAddPhoneFail={() => setHandleType("addPhone")}
                />
                <HandleViewPhone
                    lead={lead}
                    show={
                        !!(
                            buttonIsHandling === "call" &&
                            handleType === "viewPhone"
                        )
                    }
                    onClose={() => onCloseDropdownCall("viewPhone")}
                />
            </StyledMedia>
            <StyledMedia>
                <StyledButtonMedia
                    $color={classic.pink}
                    role="button"
                    tabIndex={0}
                    onClick={onClickVideo}
                >
                    <div className="icon">
                        <Video strokeWidth={1.7} color="#fff" size={20} />
                    </div>
                </StyledButtonMedia>
                <div className="textMedia">Video</div>
                <HandleAddPhone
                    lead={lead}
                    show={
                        !!(
                            buttonIsHandling === "video" &&
                            handleType === "addPhone"
                        )
                    }
                    onClose={() => onCloseDropdownVideo("addPhone")}
                    onAddPhoneSuccess={(leadId) =>
                        leadId && dispatch(startMeeting({ leadId }))
                    }
                    onAddPhoneFail={() => setHandleType("addPhone")}
                />
                <HandleVideoCall
                    lead={lead}
                    show={
                        !!(
                            buttonIsHandling === "video" &&
                            handleType === "callVideo"
                        )
                    }
                    onClose={() => onCloseDropdownVideo("callVideo")}
                />
            </StyledMedia>
            <StyledMedia>
                <StyledButtonMedia
                    $color={classic.orange}
                    onClick={onOpenEmailCompose}
                >
                    <div className="icon">
                        <Mail strokeWidth={1.7} color="#fff" size={20} />
                    </div>
                </StyledButtonMedia>
                <div className="textMedia">Email</div>
                <HandleAddEmail
                    lead={lead}
                    show={
                        !!(
                            buttonIsHandling === "email" &&
                            handleType === "addEmail"
                        )
                    }
                    onClose={onCloseDropdownEmail}
                    onAddEmailSuccess={onOpenEmailForm}
                    onAddEmailFail={() => setHandleType("addEmail")}
                />
            </StyledMedia>
        </StyledMediaWrap>
    );
};

export default MediaButton;
