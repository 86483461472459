import classnames from "classnames";
import { FC, useState, ChangeEvent } from "react";
import {
    Button,
    Text,
    Input,
    Textarea,
    Row,
    Col,
    Spinner,
} from "@doar/components";
import SimpleSwitch from "src/components/apps/settings/services/procedures-table/simple-switch";
import moment from "moment";
/* Type */
import { X } from "react-feather";
import { Staff } from "src/types/api/staff";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "src/redux/hooks";
import * as yup from "yup";
import { hasKey } from "@doar/shared/methods";
import { create as createTask } from "src/redux/slices/ticket/task";
import { start as startStatistic } from "src/redux/slices/ticket/statistic";
import { useWindowSize } from "@doar/shared/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import ConfirmBox from "src/components/apps/contacts/filters/filter-leads/confirm-box/index";
import { Lead } from "src/types/api/ticket";
import StaffFilter from "./staff-filter";
import LeadFilter from "./lead-filter";
import ReminderSelection from "./reminder-selection";
import DateInModalTask from "./date";
import Timer from "./timer";
/* Style */
import {
    StyledModalBody,
    StyledClose,
    StyledFooter,
    StyledBodyItemInput,
    StyledBodyItemTitle,
    StyledTextHeader,
    StyledModalHeader,
    StyledModal,
    StyledWrapDate,
    StyledWrapTime,
    StyledBodyRow,
    StyledError,
} from "./style";

interface IProps {
    show: boolean;
    onClose: () => void;
}

const schema = yup.object().shape({
    name: yup.string().required().trim(),
});

interface IFormValues {
    name: string;
    description?: string;
    lead_id?: number;
    staff_id?: number;
    has_reminder?: boolean;
    reminder_date?: number;
}

const ModalNewTask: FC<IProps> = ({ show, onClose }) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const { width } = useWindowSize();
    const {
        register,
        setValue,
        reset,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
        },
    });

    const [showDateRange, setShowDateRange] = useState(false);
    const [isHasReminder, setHasReminder] = useState(false);
    const [selectStaff, setSelectStaff] = useState<Staff | null>();
    const [description, setDescription] = useState("");
    const [selectLead, setSelectLead] = useState<Lead | null>();
    const [openConfirmBox, setOpenConfirmBox] = useState(false);
    const [error, setErr] = useState("");

    const onResetData = () => {
        onClose();
        reset();
        setHasReminder(false);
        setSelectStaff(null);
        setSelectLead(null);
        setDescription("");
        setShowDateRange(false);
        setOpenConfirmBox(false);
        setErr("");
    };

    const onSave = (data: IFormValues) => {
        const values = data;
        if (!hasKey(data, "has_reminder")) {
            values.has_reminder = false;
        }
        if (values.lead_id === 0) {
            delete values.lead_id;
        }
        if (values.staff_id === 0) {
            delete values.staff_id;
        }
        if (values.staff_id === 0) {
            delete values.staff_id;
        }
        // if description === "" not pass description in createTask
        setLoading(true);
        if (description === "") {
            dispatch(
                createTask({
                    form: {
                        name: values.name,
                        staff_id: values.staff_id,
                        lead_id: values.lead_id,
                        has_reminder: values.has_reminder,
                        reminder_date: values.reminder_date,
                    },
                    onSuccess: () => {
                        setLoading(false);
                        dispatch(startStatistic());
                        onResetData();
                    },
                    onFail: () => {
                        setErr(
                            "The reminder date field is required when has reminder is true."
                        );
                        setLoading(false);
                    },
                })
            );
        } else {
            dispatch(
                createTask({
                    form: {
                        name: values.name,
                        staff_id: values.staff_id,
                        lead_id: values.lead_id,
                        has_reminder: values.has_reminder,
                        reminder_date: values.reminder_date,
                        description,
                    },
                    onSuccess: () => {
                        setLoading(false);
                        dispatch(startStatistic());
                        onResetData();
                    },
                    onFail: () => {
                        setErr(
                            "The reminder date field is required when has reminder is true."
                        );
                        setLoading(false);
                    },
                })
            );
        }
    };
    const onClearValues = () => {
        if (
            getValues("name") ||
            description.length !== 0 ||
            getValues("staff_id") ||
            getValues("lead_id") ||
            getValues("reminder_date")
        ) {
            setOpenConfirmBox(true);
        } else onClose();
    };

    const onChangeReminder = (name: string) => {
        switch (name) {
            case "In 1 day":
                setValue("reminder_date", moment().add(1, "day").unix());
                setShowDateRange(false);
                break;
            case "In 2 days":
                setValue("reminder_date", moment().add(2, "days").unix());
                setShowDateRange(false);
                break;
            case "Next week":
                setValue("reminder_date", moment().add(1, "week").unix());
                setShowDateRange(false);
                break;
            case "Next month":
                setValue("reminder_date", moment().add(1, "month").unix());
                setShowDateRange(false);
                break;
            case "Custom":
                setShowDateRange(true);
                break;
            default:
                break;
        }
    };

    const OnChangeDate = (name: Date) => {
        const date = moment(name).startOf("day").unix();
        setValue("reminder_date", date);
    };

    const OnChangeTime = (time: string) => {
        const values = getValues();
        if (values.reminder_date) {
            const date = moment(time, "HH:mm A").unix();
            setValue("reminder_date", date);
            return;
        }

        setValue("reminder_date", moment().unix());
    };
    const getValueDescription = (
        e: ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        setDescription(e.target.value);
    };
    return (
        <>
            <StyledModal show={show} onClose={onClearValues} size="lg">
                <StyledModalHeader>
                    <StyledTextHeader>Create a task</StyledTextHeader>
                    <StyledClose onClick={onClearValues}>
                        <X size={20} />
                    </StyledClose>
                </StyledModalHeader>
                <form
                    action="#"
                    onSubmit={handleSubmit(onSave)}
                    autoComplete="off"
                >
                    <StyledModalBody>
                        <StyledBodyRow>
                            <Row className="row">
                                <Col>
                                    <StyledBodyItemTitle>
                                        <div>
                                            <Text color="black">Name</Text>
                                            <Text color="#8392A5">
                                                Required
                                            </Text>
                                        </div>
                                    </StyledBodyItemTitle>
                                    <StyledBodyItemInput>
                                        <Input
                                            type="text"
                                            id="name"
                                            placeholder="Enter task name"
                                            feedbackText={errors?.name?.message}
                                            state={
                                                hasKey(errors, "name")
                                                    ? "error"
                                                    : "success"
                                            }
                                            showState={!!hasKey(errors, "name")}
                                            {...register("name")}
                                        />
                                    </StyledBodyItemInput>
                                </Col>
                            </Row>
                            <Row className="row lead">
                                <Col>
                                    <StyledBodyItemTitle>
                                        <Text color="black">Contact</Text>
                                    </StyledBodyItemTitle>
                                    <StyledBodyItemInput>
                                        <LeadFilter
                                            isSearch
                                            selectedLead={selectLead || null}
                                            onChange={(lead) => {
                                                setSelectLead(lead);
                                                setValue("lead_id", 0);
                                                if (lead) {
                                                    setValue(
                                                        "lead_id",
                                                        lead.id || 0
                                                    );
                                                }
                                            }}
                                        />
                                    </StyledBodyItemInput>
                                </Col>
                            </Row>
                            <Row className="row assign">
                                <Col>
                                    <StyledBodyItemTitle>
                                        <Text color="black">Assigned to</Text>
                                    </StyledBodyItemTitle>
                                    <StyledBodyItemInput>
                                        <StaffFilter
                                            isSearch
                                            selectedStaff={selectStaff || null}
                                            onChange={(staff) => {
                                                setSelectStaff(staff);
                                                setValue("staff_id", 0);
                                                if (staff) {
                                                    setValue(
                                                        "staff_id",
                                                        staff.id || 0
                                                    );
                                                }
                                            }}
                                        />
                                    </StyledBodyItemInput>
                                </Col>
                            </Row>
                            <Row className="row description">
                                <Col>
                                    <StyledBodyItemTitle>
                                        <Text color="black">Description</Text>
                                    </StyledBodyItemTitle>
                                    <StyledBodyItemInput>
                                        <Textarea
                                            className="desc"
                                            id="taskDescription"
                                            name="description"
                                            placeholder="Enter task description"
                                            value={description}
                                            onChange={getValueDescription}
                                        />
                                    </StyledBodyItemInput>
                                </Col>
                            </Row>
                            <Row className="row reminder">
                                <Col>
                                    <StyledBodyItemInput className="reminder">
                                        <div>
                                            <StyledTextHeader>
                                                Add Reminder
                                            </StyledTextHeader>
                                            <Text as="span" color="#8392A5">
                                                Choose the payment method that
                                                the user can use for their
                                                payment
                                            </Text>
                                        </div>
                                        <span>
                                            <SimpleSwitch
                                                state={
                                                    isHasReminder ? "on" : "off"
                                                }
                                                onSwitch={(state) => {
                                                    setValue(
                                                        "has_reminder",
                                                        state
                                                    );
                                                    if (state === false) {
                                                        setShowDateRange(false);
                                                        setErr("");
                                                    }
                                                    setHasReminder(state);
                                                }}
                                            />
                                        </span>
                                    </StyledBodyItemInput>
                                </Col>
                            </Row>
                            {isHasReminder && (
                                <Row className="row">
                                    <Col>
                                        <ReminderSelection
                                            onChange={onChangeReminder}
                                        />
                                    </Col>
                                </Row>
                            )}

                            {showDateRange && (
                                <Row className="row">
                                    <Col>
                                        <StyledBodyItemInput className="chooseDateandTime">
                                            <StyledWrapDate
                                                className={classnames({
                                                    resCalendar:
                                                        width && width < 2400,
                                                })}
                                            >
                                                <DateInModalTask
                                                    onChange={OnChangeDate}
                                                />
                                            </StyledWrapDate>
                                            <StyledWrapTime
                                                className={classnames({
                                                    resTime:
                                                        width && width < 2400,
                                                })}
                                            >
                                                <Timer
                                                    onChange={OnChangeTime}
                                                />
                                            </StyledWrapTime>
                                        </StyledBodyItemInput>
                                    </Col>
                                </Row>
                            )}
                            {error && <StyledError>{error}</StyledError>}
                        </StyledBodyRow>
                    </StyledModalBody>

                    <StyledFooter>
                        <Button
                            color="white"
                            fontSize="13px"
                            onClick={onClearValues}
                            height="45px"
                            className="cancelBtn"
                        >
                            Cancel
                        </Button>
                        <Button fontSize="13px" height="45px" type="submit">
                            {loading ? <Spinner /> : "Add new"}
                        </Button>
                    </StyledFooter>
                </form>
                <ConfirmBox
                    show={openConfirmBox}
                    onClose={() => setOpenConfirmBox((prev) => !prev)}
                    onExit={onResetData}
                    message="Are you sure, you want to exit?"
                />
            </StyledModal>
        </>
    );
};

export default ModalNewTask;
