import { useClickOutside } from "@doar/shared/hooks";
import React, { FC } from "react";
import classNames from "classnames";
import { CheckCircle, Circle } from "react-feather";
import { CSSTransition } from "react-transition-group";
import {
    StyledDropdown,
    StyledMenu,
    StyledMenuItem,
    StyledNavbar,
    StyledNavbarNav,
    StyledNavItem,
    StyledIcon,
    StyledOption,
} from "./style";

interface Prop {
    chooseStatus: (status: string) => void;
}

export const DropdownMenuEmailOrSmsForm: FC<Prop> = ({ chooseStatus }) => {
    return (
        <StyledDropdown>
            <CSSTransition in timeout={0} unmountOnExit>
                <StyledMenu>
                    <StyledMenuItem
                        variant="texted"
                        onClick={() => chooseStatus("send-keep-open")}
                    >
                        <StyledIcon>
                            <Circle size="20" color="rgb(253,126,20)" />
                        </StyledIcon>
                        <StyledOption className="open">
                            Send and keep open
                        </StyledOption>
                    </StyledMenuItem>
                    <StyledMenuItem
                        variant="texted"
                        onClick={() => chooseStatus("send-close-ticket")}
                    >
                        <StyledIcon>
                            <CheckCircle size="20" color="green" />
                        </StyledIcon>

                        <StyledOption>Send and close ticket</StyledOption>
                    </StyledMenuItem>
                </StyledMenu>
            </CSSTransition>
        </StyledDropdown>
    );
};

interface Props {
    setShowConfirmDropdown: (show: boolean) => void;
    isShowConfirmDropdown: boolean;
    chooseStatus: (status: string) => void;
    isConfirmSms?: boolean;
}

export const DropdownConfirm: FC<Props> = ({
    isShowConfirmDropdown,
    setShowConfirmDropdown,
    isConfirmSms,
    chooseStatus,
}) => {
    const onClose = () => {
        setShowConfirmDropdown(false);
    };
    const containerRef = useClickOutside<HTMLUListElement>(onClose);
    return (
        <StyledNavbar
            className={classNames({
                "sms-confirm": isConfirmSms,
            })}
        >
            <StyledNavbarNav ref={containerRef}>
                <StyledNavItem>
                    {isShowConfirmDropdown && (
                        <DropdownMenuEmailOrSmsForm
                            chooseStatus={chooseStatus}
                        />
                    )}
                </StyledNavItem>
            </StyledNavbarNav>
        </StyledNavbar>
    );
};

DropdownMenuEmailOrSmsForm.displayName = "DropdownMenuEmailOrSmsForm";
