import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    LeadsConsultAndSurgery,
    LeadsNewLeads,
    LeadsConversion,
    LeadsStage,
    TopProcedures,
    ConversionsPerCampaign,
    OpenTickets,
    ReportingCards,
    LeadsFilterDateRange,
} from "../../../types/api/report";

export interface ChartsState {
    leadsNewLeads: LeadsNewLeads | null;
    leadsConsultsAndSurgeries: LeadsConsultAndSurgery | null;
    leadsConversions: LeadsConversion | null;
    leadsStages: LeadsStage | null;
    topProcedures: TopProcedures | null;
    conversionsPerCampaign: ConversionsPerCampaign | null;
    openTickets: OpenTickets | null;
    reportingCards: ReportingCards | null;
    loading: boolean;
    filterDateRange: LeadsFilterDateRange | null;
}

const initialState: ChartsState = {
    leadsNewLeads: null,
    leadsConsultsAndSurgeries: null,
    leadsConversions: null,
    leadsStages: null,
    topProcedures: null,
    conversionsPerCampaign: null,
    openTickets: null,
    reportingCards: null,
    loading: false,
    filterDateRange: null,
};

const chartsSlice = createSlice({
    name: "reports/charts",
    initialState,
    reducers: {
        doSetLeadsNewLeads(
            state,
            action: PayloadAction<{
                leadsNewLeads: LeadsNewLeads | null;
                loading: boolean;
            }>
        ) {
            state.leadsNewLeads = action.payload.leadsNewLeads;
            state.loading = action.payload.loading;
        },
        doSetLeadsConsultsAndSurgeries(
            state,
            action: PayloadAction<{
                leadsConsultsAndSurgeries: LeadsConsultAndSurgery | null;
                loading: boolean;
            }>
        ) {
            state.leadsConsultsAndSurgeries = action.payload.leadsConsultsAndSurgeries;
            state.loading = action.payload.loading;
        },
        doSetLeadsConversions(
            state,
            action: PayloadAction<{
                leadsConversions: LeadsConversion | null;
                loading: boolean;
            }>
        ) {
            state.leadsConversions = action.payload.leadsConversions;
            state.loading = action.payload.loading;
        },
        doSetLeadsStages(
            state,
            action: PayloadAction<{
                leadsStages: LeadsStage | null;
                loading: boolean;
            }>
        ) {
            state.leadsStages = action.payload.leadsStages;
            state.loading = action.payload.loading;
        },
        doSetTopProcedures(
            state,
            action: PayloadAction<{
                topProcedures: TopProcedures | null;
                loading: boolean;
            }>
        ) {
            state.topProcedures = action.payload.topProcedures;
            state.loading = action.payload.loading;
        },
        doSetFilterDateRange(
            state,
            action: PayloadAction<{
                filterDateRange: LeadsFilterDateRange | null;
            }>
        ) {
            state.filterDateRange = action.payload.filterDateRange;
        },
        doSetConversionsPerCampaign(
            state,
            action: PayloadAction<{
                conversionsPerCampaign: ConversionsPerCampaign | null;
                loading: boolean;
            }>
        ) {
            state.conversionsPerCampaign = action.payload.conversionsPerCampaign;
            state.loading = action.payload.loading;
        },
        doSetOpenTickets(
            state,
            action: PayloadAction<{
                openTickets: OpenTickets | null;
                loading: boolean;
            }>
        ) {
            state.openTickets = action.payload.openTickets;
            state.loading = action.payload.loading;
        },
        doSetReportingCards(
            state,
            action: PayloadAction<{
                reportingCards: ReportingCards | null;
                loading: boolean;
            }>
        ) {
            state.reportingCards = action.payload.reportingCards;
            state.loading = action.payload.loading;
        },
    },
});

export const {
    doSetLeadsNewLeads,
    doSetLeadsConsultsAndSurgeries,
    doSetLeadsConversions,
    doSetLeadsStages,
    doSetTopProcedures,
    doSetFilterDateRange,
    doSetConversionsPerCampaign,
    doSetOpenTickets,
    doSetReportingCards,
} = chartsSlice.actions;

export default chartsSlice.reducer;
