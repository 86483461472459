/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { createCallApi, updateCallApi } from "src/api/call/call";
import { createFileApi } from "src/api/file/file";
import { getDetailLeadMessageApi } from "src/api/lead/message";
import {
    getMessageApi,
    getMessagesApi,
    sendMessageApi,
} from "src/api/message/message";
import { getStaffsApi } from "src/api/staff/staff";
import {
    doCreateCall,
    doGetLeadMessageFail,
    doGetLeadMessages,
    doGetLeadMessagesSuccess,
    doGetMessage,
    doGetMessageSuccess,
    doSendMessage,
    doSendMessageFail,
    doSendMessageSuccess,
    doSetMessages,
    doUpdateCall,
    doUpdateCallFail,
    doUpdateCallSuccess,
    doUploadFilesMessage,
    doUploadFilesMessageFail,
    doUploadFilesMessageSuccess,
} from "src/redux/slices/lead/message";
import { Messageable, ReplyToMessage } from "src/types/api/ticket";
import { all, call, put, takeEvery, takeLatest } from "typed-redux-saga";

function* getLeadMessageSaga(action: ReturnType<typeof doGetLeadMessages>) {
    try {
        const response = yield* call(getDetailLeadMessageApi, action.payload);
        yield* put(doGetLeadMessagesSuccess(response.data));
    } catch (error) {
        yield* put(doGetLeadMessageFail());
    }
}

function* sendMessageSaga(action: ReturnType<typeof doSendMessage>) {
    try {
        const { files } = action.payload;

        const message = yield* call(sendMessageApi, {
            ...action.payload.form,
        });

        const staffs = yield* call(getStaffsApi, null);

        const staff =
            staffs.data?.find((i) => i.id === message.staff_id) || null;

        if (action.payload.onSuccess) {
            yield* call(action.payload.onSuccess);
        }

        yield* put(
            doSendMessageSuccess({
                ...message,
                html_temp: action.payload.form.email_message?.html || "",
                subject_temp: action.payload.form.email_message?.subject || "",
                files: files || [],
                ...(staff && { staff }),
            })
        );
    } catch (err) {
        yield* put(doSendMessageFail());
    }
}

function* uploadFilesMessageSaga(
    action: ReturnType<typeof doUploadFilesMessage>
) {
    try {
        const files = action.payload;
        for (let i = 0; i < files.length; i += 1) {
            if (!files[i].file) {
                const file = files[i].preview;

                const formData = new FormData();
                formData.append(
                    "original_name",
                    file.name.substring(0, file.name.lastIndexOf("."))
                );
                formData.append("fileable_type", "App\\Models\\Message");
                formData.append("file", file);

                const response = yield* call(createFileApi, formData);
                yield* put(
                    doUploadFilesMessageSuccess({
                        index: i,
                        file: response,
                        preview: file,
                    })
                );
            } else
                yield* put(
                    doUploadFilesMessageSuccess({
                        index: i,
                        file: files[i].file,
                        preview: files[i].preview,
                    })
                );
        }
    } catch (err) {
        yield* put(doUploadFilesMessageFail());
    }
}

function* createCallSaga(action: ReturnType<typeof doCreateCall>) {
    try {
        yield* call(createCallApi, action.payload.form);
        const response = yield* call(getMessagesApi, {
            lead_id: action.payload.form.lead_id,
        });

        yield* put(doSetMessages(response.data));
    } catch (err) {
        if (action.payload.onFail) {
            yield* call(
                action.payload.onFail,
                JSON.parse(JSON.stringify(err))?.data?.message
            );
        }
    }
}

function* getMessageSaga(action: ReturnType<typeof doGetMessage>) {
    try {
        const response = yield* call(getMessageApi, action.payload);

        const message: ReplyToMessage = response.reply_to as ReplyToMessage;

        yield* put(
            doGetMessageSuccess({
                replyToMessage: message,
                messageId: action.payload,
            })
        );

        // eslint-disable-next-line no-empty
    } catch (err) {}
}

function* updateCallSaga(action: ReturnType<typeof doUpdateCall>) {
    try {
        const response = yield* call(updateCallApi, {
            id: action.payload.id,
            form: action.payload.form,
        });

        if (action.payload.onSuccess) {
            yield* call(action.payload.onSuccess);
        }

        const callMessageable = response as Messageable;

        yield* put(
            doUpdateCallSuccess({
                id: action.payload.id,
                messageable: callMessageable,
            })
        );

        if (action.payload.leadId) {
            const res = yield* call(getMessagesApi, {
                lead_id: action.payload.leadId,
            });

            yield* put(doSetMessages(res.data));
        }
    } catch (err) {
        yield* put(doUpdateCallFail());

        if (action.payload.onFail) {
            yield* call(
                action.payload.onFail,
                JSON.parse(JSON.stringify(err))?.data?.message
            );
        }
    }
}

export function* messageSaga(): Generator {
    yield* all([
        takeLatest(doSendMessage, sendMessageSaga),
        takeLatest(doGetLeadMessages, getLeadMessageSaga),
        takeLatest(doGetLeadMessages, getLeadMessageSaga),
        takeEvery(doUploadFilesMessage, uploadFilesMessageSaga),
        takeEvery(doCreateCall, createCallSaga),
        takeEvery(doGetMessage, getMessageSaga),
        takeEvery(doUpdateCall, updateCallSaga),
    ]);
}
