import { FC } from "react";
import { MoreVertical, Download } from "react-feather";
import { DropdownToggle, DropdownMenu } from "@doar/components";
import { StyledDropdown, StyledItem } from "./style";

interface IProps {
    handleDownload?: () => void;
}

const FileDropdown: FC<IProps> = ({ handleDownload }) => {
    return (
        <StyledDropdown direction="down">
            <DropdownToggle variant="texted">
                <MoreVertical />
            </DropdownToggle>
            <DropdownMenu>
                <StyledItem onClick={handleDownload}>
                    <Download /> Download
                </StyledItem>
            </DropdownMenu>
        </StyledDropdown>
    );
};

export default FileDropdown;
