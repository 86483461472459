import { FC, useEffect, useState } from "react";
import ChatForm from "src/components/apps/ticket/chat-form";
import { ArrowLeft, MoreVertical } from "react-feather";
import moment from "moment";
import ChatLoading from "src/components/chat-loading";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import Note from "../../note";
import {
    StyledBody,
    StyledContent,
    StyledMain,
    StyledNoteWrapper,
    StyledHeader,
    StyledOptionsBtn,
    StyledRightHeaderWrapper,
    StyledToggleBtn,
    StyledSenderWrap,
} from "./style";
import Sender from "../../../../../components/apps/ticket/sender";
import { generateLeadImageColor } from "../../../../../helpers/generateLeadImageColor";
import MailBody from "../../../../../components/apps/ticket/ticket-body";
import { toggleBody } from "../../../../../redux/slices/ui";
import MessageTypeSelection from "../../message-type-selection";

const Main: FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const bodyHandler = () => {
        dispatch(toggleBody());
    };
    const { lead, loading } = useAppSelector((state) => ({
        lead: state.contact.lead.lead,
        loading: state.contact.lead.loadingDetails,
    }));
    const { messageId } = useAppSelector((store) => ({
        messageId: store.ticket.detail.messageId,
    }));
    const meetingRoom = useAppSelector((store) => store.ticket.daily.room);
    const pathRoom = useAppSelector((store) => store.ticket.ui.path);
    const [showRoom, setShowRoom] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);

    useEffect(() => {
        if (meetingRoom?.url) {
            setShowRoom(true);
        }

        if (!meetingRoom?.url && pathRoom) {
            setShowRoom(false);
        }
    }, [meetingRoom?.url, pathRoom]);

    const sidebarHandler = () => {
        setSidebarOpen((prev) => !prev);
    };

    return (
        <>
            {messageId && (
                <StyledMain $rightSidebar={sidebarOpen}>
                    <StyledContent>
                        {loading && <ChatLoading />}
                        {!loading && (
                            <>
                                <StyledHeader>
                                    <StyledToggleBtn
                                        type="button"
                                        onClick={bodyHandler}
                                    >
                                        <ArrowLeft />
                                    </StyledToggleBtn>
                                    {lead && (
                                        <StyledSenderWrap
                                            onClick={() =>
                                                history.push(
                                                    `/leads/${lead.id}`
                                                )
                                            }
                                        >
                                            <Sender
                                                color={generateLeadImageColor(
                                                    lead.id
                                                )}
                                                name={[
                                                    lead.first_name,
                                                    lead.last_name,
                                                ].join(" ")}
                                                time={moment(
                                                    lead.created_at
                                                ).format(
                                                    "dddd, DD MMMM, yyyy - hh:mm A"
                                                )}
                                            />
                                        </StyledSenderWrap>
                                    )}
                                    <StyledRightHeaderWrapper>
                                        <StyledOptionsBtn
                                            onClick={sidebarHandler}
                                        >
                                            <MoreVertical />
                                        </StyledOptionsBtn>
                                    </StyledRightHeaderWrapper>
                                    <MessageTypeSelection />
                                </StyledHeader>
                                <StyledBody>
                                    <MailBody />
                                    <ChatForm />
                                </StyledBody>
                            </>
                        )}
                    </StyledContent>

                    <StyledNoteWrapper
                        $rightSidebar={sidebarOpen}
                        $hasData={!!lead}
                    >
                        {!showRoom && <Note isShowProfileInfo lead={lead} />}
                    </StyledNoteWrapper>
                </StyledMain>
            )}
        </>
    );
};

export default Main;
