import { PayloadAction } from "@reduxjs/toolkit";
import { CreateTaskRequestParams, UpdateTaskForm } from "src/api/task/task";
import createGenericSlice, {
    GenericState,
} from "src/helpers/createGenericSlice";
import { CommonPagination } from "src/types/api/common";
import { Task } from "../../../types/api/ticket";

const initialState: GenericState<CommonPagination<Task> | null> = {
    data: null,
    status: "finished",
};

const taskSlice = createGenericSlice({
    name: "ticket/task",
    initialState,
    reducers: {
        start(state) {
            state.status = "loading";
        },
        success(state, action: PayloadAction<CommonPagination<Task>>) {
            state.status = "finished";
            state.data = action.payload;
        },
        fail(state) {
            state.status = "error";
        },
        create(
            state,
            action: PayloadAction<{
                form: CreateTaskRequestParams;
                onSuccess: () => void;
                onFail: () => void;
            }>
        ) {},
        createSuccess(state, action: PayloadAction<Task>) {
            if (state.data?.data) {
                state.data.data = [action.payload, ...state.data.data];
            }
        },
        createFail() {},
        doUpdate(
            state,
            action: PayloadAction<{
                id: number;
                form: UpdateTaskForm;
                onSuccess: (id: number) => void;
                onFail: (error: string) => void;
            }>
        ) {
            state.status = "loading";
        },
        doUpdateSuccess(state, action: PayloadAction<Task>) {
            state.status = "finished";
            if (state.data?.data) {
                state.data.data = [action.payload, ...state.data.data];
            }
        },
        doUpdateFail(state) {
            state.status = "error";
        },
        doDelete(
            state,
            action: PayloadAction<{
                id: number;
                onSuccess: () => void;
                onFail: (error: string) => void;
            }>
        ) {
            state.status = "loading";
        },
        deleteSuccess(state, action: PayloadAction<number>) {
            state.status = "finished";
            if (state.data?.data) {
                state.data.data = state.data.data.filter(
                    (task) => task.id !== action.payload
                );
            }
        },
        deleteFail(state) {
            state.status = "error";
        },
    },
});

export const {
    start,
    success,
    fail,
    create,
    createSuccess,
    createFail,
    doDelete,
    deleteSuccess,
    deleteFail,
    doUpdate,
    doUpdateSuccess,
    doUpdateFail,
} = taskSlice.actions;

export default taskSlice.reducer;
