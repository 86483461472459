import { FC } from "react";
import ComposeBtn from "../../../../components/apps/ticket/compose-btn";
import MainNav from "../../../../components/apps/ticket/main-nav";
import Scrollbar from "../../../../components/scrollbar";
import { StyledBody, StyledSidebar } from "./style";
import LabelNav from "../../../../components/apps/ticket/label-nav";

const Sidebar: FC = () => {
    return (
        <StyledSidebar className="sidebar">
            <Scrollbar top="0px">
                <StyledBody>
                    <ComposeBtn />
                    <MainNav />
                    <LabelNav />
                </StyledBody>
            </Scrollbar>
        </StyledSidebar>
    );
};

export default Sidebar;
