/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dropdown, DropdownMenu, DropdownToggle } from "@doar/components";
import { ChangeEvent, FC, useState, useEffect } from "react";
import { ChevronDown, ChevronUp, Info } from "react-feather";
import classNames from "classnames";
import {
    StyledDropdownItem,
    StyledWrap,
    StyledText,
    StyledConfirm,
    StyledTextConfirm,
} from "./style";

interface IProps {
    items: { id: number; name: string }[];
    value: string;
    showSearch?: boolean;
    displayTextCenter?: boolean;
    onChange: (item: { id: number; name: string }) => void;
    isConfirm?: boolean;
    timeToDelayEdit?: number;
}
const CustomDropdown: FC<IProps> = ({
    items,
    onChange,
    isConfirm,
    value,
    showSearch,
    displayTextCenter,
    timeToDelayEdit,
}) => {
    const [show, setShow] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [values, setValues] = useState(items);
    const [clickToConfirm, setClickToConfirm] = useState(false);
    const [saveIdToEdit, setSaveIdToEdit] = useState(-1);
    const [timeLeft, setTimeLeft] = useState<null | number | undefined>(null);

    const onChangeInput = (
        e: ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ): void => {
        setSearchValue(e.target.value);
        setValues(
            items.filter((i) =>
                i.name.toLowerCase().includes(e.target.value.toLowerCase())
            )
        );
    };
    useEffect(() => {
        if (timeLeft === 0) {
            setClickToConfirm(false);
            setTimeLeft(null);
        }

        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);
    return (
        <StyledWrap>
            <Dropdown
                direction="down"
                isShow={show}
                getState={(state) => setShow(state)}
            >
                <DropdownToggle>
                    {displayTextCenter ? (
                        <StyledText>{value}</StyledText>
                    ) : (
                        value
                    )}
                    <div className="arrow">
                        {show ? (
                            <ChevronUp size={18} />
                        ) : (
                            <ChevronDown size={18} />
                        )}
                    </div>
                </DropdownToggle>
                <DropdownMenu show={show}>
                    {showSearch ? (
                        <div style={{ position: "relative" }}>
                            <input
                                autoComplete="off"
                                id="search-stage-input"
                                placeholder="Search Stage"
                                onChange={onChangeInput}
                                value={searchValue}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                    {values.map((item) => {
                        return (
                            <StyledDropdownItem
                                className={classNames({
                                    "is-active": item.name === value,
                                })}
                                key={item.id}
                                onClick={
                                    isConfirm
                                        ? () => {
                                              setClickToConfirm(true);
                                              setSaveIdToEdit(item.id);
                                              setTimeLeft(
                                                  timeToDelayEdit || undefined
                                              );
                                          }
                                        : () => {
                                              setShow(false);
                                              onChange(item);
                                          }
                                }
                            >
                                {isConfirm &&
                                clickToConfirm &&
                                saveIdToEdit === item.id ? (
                                    <StyledConfirm
                                        onClick={() => {
                                            setShow(false);
                                            onChange(item);
                                        }}
                                    >
                                        <Info size={18} />
                                        <StyledTextConfirm>
                                            Click to confirm
                                        </StyledTextConfirm>
                                    </StyledConfirm>
                                ) : (
                                    item.name
                                )}
                            </StyledDropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
        </StyledWrap>
    );
};

export default CustomDropdown;
