/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable react/no-danger */
import { Input, InputGroup } from "@doar/components";
import { hasKey } from "@doar/shared/methods";
import { yupResolver } from "@hookform/resolvers/yup";
import debounce from "debounce-promise";
import { updateTicketApi } from "src/api/ticket/ticket";
import { toastError } from "src/utils/toast";
import { uniqBy } from "lodash-es";
import { parse } from "query-string";
import { FC, KeyboardEvent, useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import ReactQuill, { Quill } from "react-quill";
import DOMPurify from "dompurify";
import "react-quill/dist/quill.snow.css";
import { useHistory, useParams } from "react-router-dom";
import { Option } from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { getLeadsApi } from "src/api/lead/lead";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import GmailIcon from "src/assets/svg/gmail.svg";
import * as yup from "yup";
import { FilePreview, FormValues } from "src/types/api/message";
import Dropzone from "react-dropzone";
import { updateStatus } from "src/redux/slices/ticket/detail";
import { start } from "src/redux/slices/ticket/statistic";
import { File as FileType } from "src/types/api/ticket";
import {
    doClearFilesAttachment,
    doSendMessage,
    doUploadFilesMessage,
} from "src/redux/slices/lead/message";

import { CustomOption, CustomSingleValue, Menu, selectStyle } from "../common";
import CustomToolbar from "./custom-toolbar";
import { DropdownConfirm } from "../dropdown";
import {
    StyledBlockQuote,
    StyledDropFileOverlay,
    StyledForm,
    StyledGmailIcon,
    StyledInput,
    StyledInputGroup,
    StyledTextWrap,
    StyledToLabel,
    StyledWarning,
    StyledWrap,
} from "./style";
import FilesAttachment from "./files-attachment";

var DirectionAttribute = Quill.import("attributors/attribute/direction");
Quill.register(DirectionAttribute, true);

var AlignClass = Quill.import("attributors/class/align");
Quill.register(AlignClass, true);

var BackgroundClass = Quill.import("attributors/class/background");
Quill.register(BackgroundClass, true);

var ColorClass = Quill.import("attributors/class/color");
Quill.register(ColorClass, true);

var DirectionClass = Quill.import("attributors/class/direction");
Quill.register(DirectionClass, true);

var FontClass = Quill.import("attributors/class/font");
Quill.register(FontClass, true);

var SizeClass = Quill.import("attributors/class/size");
Quill.register(SizeClass, true);

var AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);

var BackgroundStyle = Quill.import("attributors/style/background");
Quill.register(BackgroundStyle, true);

var ColorStyle = Quill.import("attributors/style/color");
Quill.register(ColorStyle, true);

var DirectionStyle = Quill.import("attributors/style/direction");
Quill.register(DirectionStyle, true);

var FontStyle = Quill.import("attributors/style/font");
Quill.register(FontStyle, true);

var SizeStyle = Quill.import("attributors/style/size");
Quill.register(SizeStyle, true);

const modules = {
    toolbar: {
        container: "#toolbar",
    },
};

const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "align",
    "background",
    "code",
    "direction",
    "formula",
    "indent",
];

const schema = yup.object().shape({
    subject: yup.string().required().trim(),
    toEmails: yup.array().required().min(1),
    channel: yup.object().required(),
    html: yup.string(),
    files: yup.mixed(),
});

interface Props {
    handleModal: () => void;
}
const EmailForm: FC<Props> = ({ handleModal }) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { location } = history;
    const queryParams = parse(location.search);
    const { id } = useParams<{ id?: string }>();

    const {
        channelData,
        staff,
        project,
        messages,
        lead,
        loading,
        filesUploaded,
    } = useAppSelector((store) => ({
        channelData: store.ticket.channel.data,
        messages: store.lead.message.messages,
        lead: store.contact.lead.lead,
        staff: store.authentication.user?.staff,
        project: store.authentication.user?.project,
        loading: store.lead.message.loadingSendMessage,
        filesUploaded: store.lead.message.filesUploaded,
    }));
    const [dragOver, setDragOver] = useState(false);
    const [isFocusTo, setFocusTo] = useState(false);
    const [isShowConfirmDropdown, setShowConfirmDropdown] = useState(false);
    const [filesAttachment, setFilesAttachment] = useState<FilePreview[]>([]);
    const [showWarning, setShowWarning] = useState(false);

    const channelOptions =
        channelData?.data
            .filter((c) => c.channelable_type === "App\\Models\\EmailChannel")
            .map((c) => {
                return {
                    label: c?.api_integration?.username || "",
                    value: String(c.id),
                    icon: GmailIcon,
                    error: c?.api_integration?.error || false,
                };
            }) || [];

    const initChannel = channelData?.data.find(
        (c) =>
            String(c.id) === String(queryParams.channel) ||
            c.channelable_type === "App\\Models\\EmailChannel"
    );

    const renderSenderName = (val: string) => {
        if (val) {
            switch (val) {
                case "&lt;":
                    return "<";
                case "&amp;nbsp;":
                case "&nbsp;":
                    return " ";
                case "&gt;":
                    return ">";
                case "{Business name}":
                    return project?.name || "";
                case "{Staff email}":
                case "[{Staff email}]":
                    return project?.email || "";
                case "[{Staff first name}]":
                case "{Staff first name}":
                    return staff?.first_name || "";
                case "[{Staff last name}]":
                case "{Staff last name}":
                    return staff?.last_name || "";
                case "{Staff first name} {Staff last name}":
                    return `${staff?.first_name || ""} ${
                        staff?.last_name || ""
                    }`;
                default:
                    return "";
            }
        }

        return "";
    };

    const renderSignature = (signature: string) => {
        if (signature) {
            return signature.replace(
                /\{Business name\}|\{Staff first name\}|\[\{Staff email\}\]|\[\{Staff last name\}\]|\[\{Staff first name\}\]|\{Staff last name\}|&amp;nbsp;|&lt;|&gt;|&nbsp;|\{Staff first name} {Staff last name\}/g,
                (match) => {
                    return renderSenderName(match);
                }
            );
        }
        return "";
    };

    const selectedMessage = queryParams?.message_id
        ? messages?.find((i) => i.id === Number(queryParams?.message_id))
        : null;

    const methods = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            channel: initChannel
                ? {
                      label:
                          initChannel && initChannel.channelable.sender_name
                              ? renderSenderName(
                                    initChannel.channelable.sender_name
                                )
                              : initChannel?.api_integration?.username,
                      value: String(initChannel.id),
                      error: initChannel?.api_integration?.error || false,
                  }
                : undefined,
            toEmails: [
                {
                    label:
                        selectedMessage?.messageable?.reply_to ||
                        lead?.email ||
                        "",
                    value: String(lead?.id || 0),
                },
            ],
            html: "",
            subject: selectedMessage?.messageable?.subject,
            threadId: selectedMessage?.messageable?.thread_id,
        },
    });
    const {
        handleSubmit,
        control,
        formState: { errors },
        watch,
        reset,
        getValues,
        setError,
        clearErrors,
    } = methods;

    useEffect(() => {
        reset({
            ...getValues(),
            toEmails: [
                {
                    label:
                        selectedMessage?.messageable?.reply_to ||
                        lead?.email ||
                        "",
                    value: String(lead?.id || 0),
                },
            ],
        });
    }, [reset, lead, getValues, selectedMessage?.messageable?.reply_to]);

    useEffect(() => {
        setFilesAttachment(filesUploaded);
    }, [filesUploaded]);

    useEffect(() => {
        if (showWarning) {
            setTimeout(() => {
                setShowWarning(false);
            }, 1000);
        }
    }, [showWarning]);

    const promiseSearchLead = (inputValue: string): Promise<Option[]> =>
        new Promise((resolve, reject) => {
            getLeadsApi({ keyword: inputValue, limit: 5 })
                .then((r) =>
                    resolve(
                        uniqBy(r.data, "email").map((i) => ({
                            label: i.email,
                            value: String(i.id),
                            meta: {
                                avatar:
                                    i.staff?.photo ||
                                    `https://ui-avatars.com/api/?name=${String(
                                        i.first_name
                                    )}+${String(i.last_name)}`,
                            },
                        })) || []
                    )
                )
                .catch((e) => reject(e));
        });
    const handleSearchLead = debounce(promiseSearchLead, 700);

    const convertFilesAttachment = (files: FilePreview[]) => {
        const result: FileType[] = [];
        files.forEach((i) => {
            if (i.file) {
                result.push(i.file);
            }
        });
        return result;
    };

    const convertHtml = (valueHTML: string) => {
        return valueHTML.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
    };

    const onSubmit: SubmitHandler<FormValues> = (values) => {
        /* Check file is not uploaded before send message */
        if (filesAttachment.find((i) => !i.file)) {
            setShowWarning(true);
            return;
        }
        setShowWarning(false);
        if (
            window.location.pathname.includes("tickets") &&
            !isShowConfirmDropdown
        ) {
            setShowConfirmDropdown(true);
            return;
        }
        const data = {
            message:
                convertHtml(
                    renderSignature(
                        `${values.html} ${
                            initChannel?.channelable?.signature !== null
                                ? initChannel?.channelable?.signature
                                : ""
                        }`
                    )
                ) || "",
            channel_id: Number(values.channel.value),
            to: values.toEmails.map((i) => {
                if (i.__isNew__) {
                    return {
                        email: i.label,
                    };
                }
                return {
                    lead_id: Number(i.value),
                };
            }),
            email_message: {
                html:
                    convertHtml(
                        renderSignature(
                            `${values.html} ${
                                initChannel?.channelable?.signature !== null
                                    ? initChannel?.channelable?.signature
                                    : ""
                            }`
                        )
                    ) || "",
                subject: values.subject,
                thread_id: values?.threadId,
            },
            ...(queryParams?.message_id && {
                reply_to: Number(queryParams.message_id),
            }),
            ...(filesAttachment.length && {
                attachments: filesAttachment.map((i) => i.file?.id || 0),
            }),
        };
        if (!values.html && !filesAttachment.length) {
            setError("html", {
                type: "manually",
                message: "Message is required field",
            });
            return;
        }

        clearErrors();
        if (lead) {
            dispatch(
                doSendMessage({
                    form: data,
                    ...(filesAttachment.length && {
                        files: convertFilesAttachment(filesAttachment),
                    }),
                    leadId: lead.id,
                    onSuccess: () => {
                        dispatch(doClearFilesAttachment());
                        reset({
                            ...getValues(),
                            subject: "",
                            html: "",
                        });
                        handleModal();
                    },
                })
            );
        }

        setShowConfirmDropdown(false);
    };

    const onDragOver = useCallback((e: React.SyntheticEvent) => {
        e.preventDefault();
        setDragOver(true);
    }, []);

    const onDragLeave = useCallback(() => {
        setDragOver(false);
    }, []);

    const uploadFile = (fileArray: FilePreview[]) => {
        dispatch(doUploadFilesMessage(fileArray));
    };

    const onDrop = (files: File[]) => {
        setDragOver(false);
        if (files) {
            const temp: FilePreview[] = files.map((i) => ({
                file: null,
                preview: i,
            }));

            const newFileArray: FilePreview[] = [...filesAttachment, ...temp];
            setFilesAttachment(newFileArray);
            uploadFile(newFileArray);
        }
    };

    function onPressEscape<T>(e: KeyboardEvent<T>) {
        if (e.key === "Escape") {
            handleModal();
            dispatch(doClearFilesAttachment());
        }
    }

    const onChangeFiles = (files: FilePreview[]) => {
        setFilesAttachment(files);
        uploadFile(files);
    };
    const chooseStatus = (option: string) => {
        const valuesAll = getValues();
        if (id) {
            switch (option) {
                case "send-close-ticket":
                    updateTicketApi(+id, {
                        status: "closed",
                    })
                        .then(() => {
                            dispatch(updateStatus("closed"));
                            dispatch(start());
                        })
                        .catch((e) => {
                            toastError(e);
                        });
                    onSubmit(valuesAll);
                    setShowConfirmDropdown(false);
                    break;
                case "send-keep-open":
                    updateTicketApi(+id, {
                        status: "open",
                    })
                        .then(() => {
                            dispatch(updateStatus("open"));
                            dispatch(start());
                        })
                        .catch((e) => {
                            toastError(e);
                        });
                    onSubmit(valuesAll);
                    setShowConfirmDropdown(false);
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <>
            <Dropzone
                onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                noClick
            >
                {({ getRootProps }) => (
                    <div {...getRootProps()}>
                        <StyledForm onSubmit={handleSubmit(onSubmit)}>
                            <StyledInputGroup mt="10px">
                                {isFocusTo ||
                                (!isFocusTo && !!watch("toEmails")?.length) ? (
                                    <StyledToLabel>To:</StyledToLabel>
                                ) : (
                                    ""
                                )}
                                <Controller
                                    control={control}
                                    name="toEmails"
                                    render={({ field }) => (
                                        <AsyncCreatableSelect
                                            cacheOptions
                                            loadOptions={handleSearchLead}
                                            isMulti
                                            styles={selectStyle(
                                                hasKey(errors, "toEmails"),
                                                isFocusTo ||
                                                    (!isFocusTo &&
                                                        !!watch("toEmails")
                                                            ?.length)
                                                    ? "30px"
                                                    : "0"
                                            )}
                                            isClearable={false}
                                            components={{
                                                IndicatorSeparator: null,
                                                DropdownIndicator: null,
                                                Menu,
                                                Option: CustomOption,
                                            }}
                                            placeholder={
                                                !isFocusTo ? "Recipients" : ""
                                            }
                                            {...field}
                                            onFocus={() => setFocusTo(true)}
                                            onBlur={() => setFocusTo(false)}
                                            onKeyDown={(
                                                e: KeyboardEvent<HTMLDivElement>
                                            ) =>
                                                onPressEscape<HTMLDivElement>(e)
                                            }
                                        />
                                    )}
                                />
                            </StyledInputGroup>
                            <StyledInputGroup mt="10px">
                                <StyledToLabel>From:</StyledToLabel>
                                <StyledGmailIcon src={GmailIcon} alt="" />
                                <Controller
                                    control={control}
                                    name="channel"
                                    render={({ field }) => (
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions={channelOptions}
                                            styles={selectStyle(
                                                hasKey(errors, "channel"),
                                                "75px"
                                            )}
                                            isClearable={false}
                                            isSearchable={false}
                                            components={{
                                                IndicatorSeparator: null,
                                                DropdownIndicator: null,
                                                Option: CustomOption,
                                                Menu,
                                                SingleValue: CustomSingleValue,
                                            }}
                                            placeholder="From"
                                            {...field}
                                            onKeyDown={(
                                                e: KeyboardEvent<HTMLDivElement>
                                            ) =>
                                                onPressEscape<HTMLDivElement>(e)
                                            }
                                        />
                                    )}
                                />
                            </StyledInputGroup>
                            <InputGroup mt="10px">
                                <Controller
                                    control={control}
                                    name="subject"
                                    render={({ field }) => (
                                        <StyledInput
                                            type="text"
                                            {...field}
                                            placeholder="Subject"
                                            showErrorOnly
                                            state={
                                                hasKey(errors, "subject")
                                                    ? "error"
                                                    : "success"
                                            }
                                            showState={
                                                !!hasKey(errors, "subject")
                                            }
                                            customStyle="noborder"
                                            onKeyDown={(
                                                e: KeyboardEvent<
                                                    | HTMLInputElement
                                                    | HTMLTextAreaElement
                                                >
                                            ) =>
                                                onPressEscape<
                                                    | HTMLInputElement
                                                    | HTMLTextAreaElement
                                                >(e)
                                            }
                                        />
                                    )}
                                />
                            </InputGroup>
                            <Controller
                                control={control}
                                name="html"
                                render={({ field }) => (
                                    <StyledWrap
                                        hasError={hasKey(errors, "html")}
                                        hasThread={
                                            !!selectedMessage?.messageable?.html
                                        }
                                    >
                                        <StyledTextWrap className="email-editor">
                                            <ReactQuill
                                                {...field}
                                                modules={modules}
                                                formats={formats}
                                                theme="snow"
                                                onKeyDown={(
                                                    e: KeyboardEvent<HTMLDivElement>
                                                ) =>
                                                    onPressEscape<HTMLDivElement>(
                                                        e
                                                    )
                                                }
                                                id="editor-container"
                                                bounds=".email-editor"
                                            />

                                            {dragOver && (
                                                <StyledDropFileOverlay>
                                                    Drop file here
                                                </StyledDropFileOverlay>
                                            )}

                                            {initChannel?.channelable
                                                ?.signature &&
                                                !selectedMessage && (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: DOMPurify.sanitize(
                                                                renderSignature(
                                                                    initChannel
                                                                        ?.channelable
                                                                        ?.signature ||
                                                                        ""
                                                                )
                                                            ),
                                                        }}
                                                    />
                                                )}

                                            {selectedMessage &&
                                            selectedMessage?.messageable
                                                ?.html ? (
                                                <StyledBlockQuote>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: selectedMessage
                                                                .messageable
                                                                .html,
                                                        }}
                                                    />
                                                </StyledBlockQuote>
                                            ) : (
                                                ""
                                            )}
                                            <FilesAttachment
                                                files={filesAttachment}
                                                onChangeFiles={(newFiles) => {
                                                    setFilesAttachment(
                                                        newFiles
                                                    );
                                                    dispatch(
                                                        doUploadFilesMessage(
                                                            newFiles
                                                        )
                                                    );
                                                }}
                                            />
                                        </StyledTextWrap>
                                        {showWarning && (
                                            <StyledWarning>
                                                Uploading... Please wait.
                                            </StyledWarning>
                                        )}
                                        <CustomToolbar
                                            files={filesAttachment}
                                            onChangeFiles={onChangeFiles}
                                            loading={loading}
                                        />
                                    </StyledWrap>
                                )}
                            />
                            <Input
                                type="hidden"
                                id="threadId"
                                name="threadId"
                            />
                        </StyledForm>
                    </div>
                )}
            </Dropzone>
            {isShowConfirmDropdown && (
                <DropdownConfirm
                    isShowConfirmDropdown={isShowConfirmDropdown}
                    setShowConfirmDropdown={setShowConfirmDropdown}
                    chooseStatus={chooseStatus}
                />
            )}
        </>
    );
};

export default EmailForm;
