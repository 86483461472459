import { CommonPagination } from "src/types/api/common";
import { File } from "src/types/api/ticket";
import request from "src/utils/request";

export const getFilesApi = (
    params: { limit?: number; page?: number; lead_id?: number } | null = null
): Promise<CommonPagination<File>> =>
    request.get<CommonPagination<File>, CommonPagination<File>>("/api/files", {
        params,
    });

export const createFileApi = (form: FormData): Promise<File> =>
    request.post<File, File>("api/files", form);
