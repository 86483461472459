import styled, { css, themeGet } from "@doar/shared/styled";

export const StyledInputWrapper = styled.div<{ $hasError?: boolean }>`
    padding: 0.5rem;
    position: relative;
    ${({ $hasError }) =>
        $hasError &&
        css`
            margin-bottom: 13px;
        `}
`;

export const StyledError = styled.p`
    color: ${themeGet("colors.danger")};
    margin: 0;
    margin-top: 2px;
    font-weight: 400;
    font-size: 11px;
`;

export const StyledSpinnerWrapper = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    height: 17px;
`;

export const StyledInput = styled.input<{ inputLoading: boolean }>`
    padding: 0.46875rem 0.625rem;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;

    ${({ inputLoading }) =>
        inputLoading &&
        css`
            padding-right: 30px;
        `}
`;

export const StyledWrapper = styled.div`
    padding: 5px;
    button {
        font-weight: 600;
        font-size: 15;
        margin: 5px 10px;
    }
    input {
        padding: 0.46875rem 0.625rem;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        -moz-appearance: textfield;

        &.is-loading {
            padding-right: 30px;
        }
    }
`;
