import { Avatar, DropdownMenu } from "@doar/components";
import styled, { css, space, themeGet } from "@doar/shared/styled";

export const StyledButton = styled.div``;

export const StyledText = styled.p`
    margin-left: 10px;
`;

export const StyledWrap = styled.div`
    padding: 20px;
    button {
        width: 100%;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-family: ${themeGet("fonts.interUi")};
        font-weight: 500;
    }
`;

export const StyledDropMenu = styled(({ ...rest }) => (
    <DropdownMenu {...rest} />
))`
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: ${themeGet("colors.border")};
    width: 180px;
    margin-top: 11.5px;
    box-shadow: none;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    margin-right: -10px;
    right: 0;
    ${(props) =>
        props.theme.name === "cool" &&
        css`
            margin-top: 13.5px;
        `}
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            &::before {
                opacity: 0;
            }
            &::after {
                border-bottom-color: ${themeGet("colors.darklighten5")};
            }
            margin-top: 14px;
        `}
`;

export const StyledAvatar = styled(({ ...rest }) => <Avatar {...rest} />)`
    transition: all 0.3s;
    &:hover {
        border: 2px solid ${themeGet("colors.gray300")};
        ${({ theme }) =>
            theme.name === "dark" &&
            css`
                border-color: ${themeGet("colors.gray800")};
            `}
    }
`;

StyledDropMenu.displayName = "DropdownMenu";

export const StyledNameWrap = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px;
    margin-top: 15px;
`;

export const StyledAuthorName = styled.h6`
    font-weight: 600;
    margin: 0;
    margin-right: 10px;
`;

export const StyledAuthorRole = styled.p`
    font-size: 12px;
    color: ${themeGet("colors.text3")};
    margin-bottom: 25px;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledDropItem = styled(({ mt, ...rest }) => <div {...rest} />)`
    svg {
        width: 15px;
    }
    padding: 10px 0 10px 10px;
    display: flex;
    align-items: center;
    color: ${themeGet("colors.text2")};
    border-radius: 0.25rem;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-weight: 400;
    width: 100%;
    font-size: 13px;
    &:hover {
        background-color: ${themeGet("colors.gray100")};
        cursor: pointer;
    }
    ${({ theme }) =>
        theme.name === "dark" &&
        css`
            color: ${themeGet("colors.gray500")};
        `}
    ${space}
`;
