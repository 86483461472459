import { FC } from "react";
import SkeletonLoading from "src/components/skeleton-loading";
import { StyledInfo, StyledItem } from "./style";

const TicketLoading: FC = () => {
    const styles = {
        borderRadius: "20px",
    };
    return (
        <>
            {new Array(10).fill(Math.random()).map((i, index) => (
                <StyledItem key={`${i}-${String(index)}`}>
                    <SkeletonLoading width="38px" height="38px" circle />
                    <StyledInfo>
                        <SkeletonLoading
                            height="30px"
                            style={{ ...styles, marginBottom: "10px" }}
                            width="80%"
                        />
                        {new Array(3).fill(Math.random()).map((j, idx) => (
                            <SkeletonLoading
                                key={`${j}-${String(idx)}`}
                                height="20px"
                                style={styles}
                                width="100%"
                            />
                        ))}
                    </StyledInfo>
                </StyledItem>
            ))}
        </>
    );
};

export default TicketLoading;
