import React, { FC, useState } from "react";
import { Lead } from "src/types/api/ticket";
import { CheckCircle, HelpCircle, Copy, ArrowLeftCircle } from "react-feather";
import { classic } from "@doar/shared/styled/colors";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import CopyToClipboard from "react-copy-to-clipboard";

import SkeletonLoading from "src/components/skeleton-loading";
import MediaButton from "./media-button";
import DropdownStage from "./dropdown-stage";
import {
    StyledCopyBtn,
    StyledHeader,
    StyledLoading,
    StyledWrap,
} from "./style";

interface IProps {
    lead: Lead | null;
    isShowBack?: boolean;
}

const LeadHeader: FC<IProps> = ({ lead, isShowBack }) => {
    const history = useHistory();
    const [copied, setCopied] = useState(false);

    return (
        <StyledWrap $disabled={!lead}>
            <StyledHeader>
                <div className="leadInfo">
                    {isShowBack && (
                        <div className="backArrow">
                            <ArrowLeftCircle
                                size={25}
                                strokeWidth={1.2}
                                color={classic.text2}
                                onClick={() => history.push("/contacts")}
                            />
                        </div>
                    )}

                    {!lead ? (
                        <StyledLoading>
                            <SkeletonLoading width="50%" height="25px" />
                            <SkeletonLoading width="60%" height="20px" />
                        </StyledLoading>
                    ) : (
                        <>
                            <div className="leadName">
                                <div>
                                    {lead?.first_name} {lead?.last_name}
                                </div>
                                <div style={{ margin: "2px 0 0 4px" }}>
                                    {lead?.is_qualified && (
                                        <>
                                            <CheckCircle
                                                size={18}
                                                data-tip="Contact is qualified"
                                                color="green"
                                            />

                                            <ReactTooltip
                                                place="bottom"
                                                effect="solid"
                                            />
                                        </>
                                    )}{" "}
                                    {lead?.is_qualified === false && (
                                        <>
                                            <HelpCircle
                                                size={18}
                                                data-tip="Contact failed qualification, needs a manual review"
                                                color="orange"
                                            />

                                            <ReactTooltip
                                                place="bottom"
                                                effect="solid"
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="leadEmail">
                                <div className="email">{lead?.email}</div>{" "}
                                <CopyToClipboard
                                    text={lead?.email || ""}
                                    onCopy={() => setCopied(true)}
                                >
                                    <StyledCopyBtn
                                        data-tip={copied ? "Copied!" : "Copy"}
                                        data-for="copy-status"
                                        className="copyBtn"
                                    >
                                        <Copy
                                            color={classic.gray700}
                                            size={18}
                                            strokeWidth={2.5}
                                        />
                                        <ReactTooltip
                                            place="top"
                                            id="copy-status"
                                        />
                                    </StyledCopyBtn>
                                </CopyToClipboard>
                            </div>
                        </>
                    )}
                </div>
            </StyledHeader>
            <DropdownStage lead={lead || null} />
            <MediaButton lead={lead || null} />
        </StyledWrap>
    );
};

export default LeadHeader;
