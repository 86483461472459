/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { Input } from "@doar/components";
import { FC, KeyboardEvent, useEffect, useState } from "react";
import classNames from "classnames";
import parsePhoneNumberFromString, {
    getCountries,
    getCountryCallingCode,
    isValidPhoneNumber,
    CountryCode,
    parsePhoneNumber,
} from "libphonenumber-js";
import { List } from "react-virtualized";
import useOnclickOutside from "react-cool-onclickoutside";
import ArrowIcon from "@doar/shared/images/down-arrow.svg";
import Feedback from "@doar/components/src/forms/form-elements/feedback";
import {
    StyledCountriesDropdown,
    StyledCountriesDropdownItem,
    StyledCountriesDropdownMenu,
    StyledCountriesDropdownToggle,
    StyledCountryFlag,
    StyledDownIcon,
    StyledErrorMsg,
    StyledWrap,
} from "./style";
import { countriesNotSupported, countriesPrioritized } from "./constants";

interface IProps {
    id: string;
    loading?: boolean;
    errorMessage?: string;
    value: string;
    onChange: (number: string) => void;
    onSetErrorMessage?: (error: string) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
}
const PhoneCountriesInput: FC<IProps> = ({
    id,
    value,
    loading,
    errorMessage,
    onChange,
    onSetErrorMessage,
    onKeyDown,
}) => {
    const [number, setNumber] = useState("");
    const [selectCountry, setSelectCountry] = useState({
        name: "US",
        code: "1",
    });
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (value) {
            setNumber(parsePhoneNumber(value).formatNational());
        }
    }, [value]);

    const sortedCountries = () => {
        const countries = getCountries();
        const result: { name: string; code: string }[] = [];
        countries.forEach((i) => {
            if (!countriesNotSupported.includes(i)) {
                const code = getCountryCallingCode(i);

                const key = i as string;
                if (countriesPrioritized.includes(i)) {
                    result.unshift({
                        code,
                        name: key,
                    });
                } else {
                    result.push({
                        code,
                        name: key,
                    });
                }
            }
        });

        return result;
    };

    const checkPhoneNumber = (phoneNumber: string, code: CountryCode) => {
        if (isValidPhoneNumber(phoneNumber, code)) {
            onChange(
                parsePhoneNumberFromString(phoneNumber, code)?.number || ""
            );
            if (onSetErrorMessage) {
                onSetErrorMessage("");
            }
        } else if (onSetErrorMessage) {
            onSetErrorMessage("Invalid phone number");
        }
    };

    const onChangeNumber = (val: string) => {
        const selectCode = selectCountry.name as CountryCode;
        setNumber(val);
        checkPhoneNumber(val, selectCode);
    };

    const onSelectCountry = (name: string, code: string) => {
        setSelectCountry({
            name,
            code,
        });
        const countryName = name as CountryCode;
        checkPhoneNumber(number, countryName);
        setShow(false);
    };

    const rowRenderer = ({
        index,
        key,
        style,
    }: {
        index: number;
        key: string;
        style: React.CSSProperties;
    }) => {
        return (
            <StyledCountriesDropdownItem
                key={key}
                style={style}
                onClick={() =>
                    onSelectCountry(
                        sortedCountries()[index].name,
                        sortedCountries()[index].code
                    )
                }
            >
                <StyledCountryFlag
                    src={
                        require(`@doar/shared/images/countries/${sortedCountries()[
                            index
                        ].name.toLowerCase()}.svg`)?.default
                    }
                    alt=""
                />
                +{sortedCountries()[index].code}
            </StyledCountriesDropdownItem>
        );
    };

    const ref = useOnclickOutside(() => setShow(false));

    return (
        <>
            <StyledWrap $hasError={!!errorMessage} $disabled={loading}>
                <StyledCountriesDropdown ref={ref}>
                    <StyledCountriesDropdownToggle
                        onClick={() => setShow(!show)}
                    >
                        <StyledCountryFlag
                            src={
                                require(`@doar/shared/images/countries/${selectCountry.name.toLowerCase()}.svg`)
                                    ?.default
                            }
                            alt=""
                        />
                        +{selectCountry.code}
                        <StyledDownIcon src={ArrowIcon} alt="" />
                    </StyledCountriesDropdownToggle>
                    <StyledCountriesDropdownMenu $show={show}>
                        <List
                            width={68}
                            height={200}
                            rowCount={sortedCountries()?.length || 0}
                            rowHeight={38}
                            rowRenderer={rowRenderer}
                        />
                    </StyledCountriesDropdownMenu>
                </StyledCountriesDropdown>
                <Input
                    id={id}
                    name={id}
                    placeholder="+1 650 504 2307"
                    className={classNames({ "is-loading": loading })}
                    value={number}
                    disabled={loading}
                    onChange={(e) => onChangeNumber(e.target.value)}
                    onKeyDown={onKeyDown}
                />
            </StyledWrap>
            {errorMessage && (
                <StyledErrorMsg>
                    <Feedback state="error" showErrorOnly showState>
                        {errorMessage}
                    </Feedback>
                </StyledErrorMsg>
            )}
        </>
    );
};

export default PhoneCountriesInput;
