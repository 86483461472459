/* eslint-disable react/no-unescaped-entities */
import { FC } from "react";
import NoConversationsImg from "@doar/shared/images/Illustration-No-Conversations-Yet.svg";
import { Lead } from "src/types/api/ticket";
import { useWindowSize } from "@doar/shared/hooks";
import { StyledDescription, StyledTitle, StyledWrap } from "./style";

interface IProps {
    lead: Lead | null;
}
const EmptyConversation: FC<IProps> = ({ lead }) => {
    const { height } = useWindowSize();
    return (
        <StyledWrap $screenHeight={height || 200}>
            <img src={NoConversationsImg} alt="" />
            <br />
            <StyledTitle>No Conversations Yet</StyledTitle>
            <StyledDescription>
                <b>
                    {lead?.first_name} {lead?.last_name}
                </b>{" "}
                hasn't communicated with your <br /> practice yet, send out an
                email or text!
            </StyledDescription>
        </StyledWrap>
    );
};
export default EmptyConversation;
