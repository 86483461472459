import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Staff } from "src/types/api/staff";
import { Ticket } from "src/types/api/ticket";
import { TicketStatus } from "../../../types/api/ticket";

interface DetailsState {
    data: Ticket | null;
    status: "loading" | "finished" | "error";
    detailId: number;
    detailLeadId: number;
    messageId: number;
}
const initialState: DetailsState = {
    data: null,
    status: "finished",
    detailId: 0,
    detailLeadId: 0,
    messageId: 0,
};

const detailTicketSlice = createSlice({
    name: "ticket/detail",
    initialState,
    reducers: {
        fail(state) {
            state.status = "error";
        },
        clear(state) {
            state.data = null;
        },
        unAssignStaff(state) {
            if (state.data?.staff) {
                state.data.staff = undefined;
                state.data.staff_id = null;
                state.data.status = "open";
            }
        },
        assignStaff(state, action: PayloadAction<Staff>) {
            if (state.data) {
                state.data.staff = action.payload;
                state.data.staff_id = action.payload.id;
                state.data.status = "assigned";
            }
        },
        updateStatus(state, action: PayloadAction<TicketStatus>) {
            if (state.data) {
                state.data.status = action.payload;
            }
        },
        updateDetail(
            state,
            action: PayloadAction<{
                data: Ticket | null;
                status: "loading" | "finished";
            }>
        ) {
            state.data = action.payload.data;
            state.status = action.payload.status;
        },
        setDetailId(state, action: PayloadAction<number>) {
            state.detailId = action.payload;
        },
        setDetailLeadId(state, action: PayloadAction<number>) {
            state.detailLeadId = action.payload;
        },
        setMessageId(state, action: PayloadAction<number>) {
            state.messageId = action.payload;
        },
    },
});

export const {
    fail,
    assignStaff,
    updateStatus,
    clear,
    unAssignStaff,
    updateDetail,
    setDetailId,
    setDetailLeadId,
    setMessageId,
} = detailTicketSlice.actions;

export default detailTicketSlice.reducer;
