import { Dropdown, DropdownMenu, DropdownToggle } from "@doar/components";
import { FC, useState } from "react";
import { ChevronDown, ChevronUp, Clock } from "react-feather";
import { StyledDropdownItem, StyledDropdownWrap } from "./style";
import { items } from "./hour";

interface Props {
    onChange: (name: string) => void;
    disabled?: boolean;
}

const Timer: FC<Props> = ({ onChange, disabled }) => {
    const [show, setShow] = useState(false);
    const [value, setValue] = useState("");
    const onSelectOption = (name: string) => {
        setShow(false);
        setValue(name);
        onChange(name);
    };

    return (
        <StyledDropdownWrap>
            <Dropdown
                direction="down"
                isShow={show}
                getState={(state) => setShow(state)}
            >
                <DropdownToggle
                    variant="outlined"
                    className="dropdownToggle"
                    disabled={disabled}
                >
                    <div className="value">
                        <span>
                            <Clock size={20} />
                        </span>
                        <p style={{ color: "#1b2e4b" }}>{value || "Time"}</p>
                    </div>
                    <div className="selectionArrow">
                        {show ? <ChevronUp /> : <ChevronDown />}
                    </div>
                </DropdownToggle>
                <DropdownMenu show className="dropdownMenu">
                    {items.map((item) => {
                        return (
                            <StyledDropdownItem
                                key={item.id}
                                onClick={() => onSelectOption(item.name || "")}
                            >
                                {item.name}
                            </StyledDropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
        </StyledDropdownWrap>
    );
};

export default Timer;
