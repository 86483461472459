import { groupBy, map } from "lodash-es";
import moment from "moment";
import { FC, Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { getMessagesApi } from "src/api/message/message";
import EmptyConversation from "src/components/apps/leads/main-view/conversations/empty-conversation";
import ChatLoading from "src/components/chat-loading";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
    doSetLimitDefault,
    doSetMessages,
    doSetTotal,
} from "src/redux/slices/lead/message";
import { Ticket } from "src/types/api/ticket";
import { Lead, Message } from "../../../../../types/api/ticket";
import Divider from "./divider";
import Item from "./item";
import { StyledGroup, StyledOpenTicket } from "./style";

interface Props {
    lead: Lead | null;
    ticket?: Ticket;
    limit?: number;
    setFetching?: () => void;
    setFetched?: () => void;
}

const ChatGroup: FC<Props> = ({
    lead,
    ticket,
    limit,
    setFetching,
    setFetched,
}) => {
    const { leadMessages, leadIdFromUrl, selectedTab, messageId } =
        useAppSelector((store) => ({
            leadMessages: store.lead.message.messages,
            leadIdFromUrl: store.contact.lead.leadIdFromUrl,
            selectedTab: store.contact.lead.selectedTab,
            messageId: store.ticket.detail.messageId,
        }));

    const history = useHistory();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(true);
    const containerRef = useRef<HTMLDivElement>(null);

    const getLeadId = () => {
        if (history.location.pathname.includes("leads")) {
            return leadIdFromUrl || lead?.id || 0;
        }
        return lead?.id || 0;
    };

    const { isLoading, data } = useQuery(
        ["fetchMessageByLeadId", { lead_id: getLeadId(), limit }],
        () =>
            getLeadId()
                ? getMessagesApi({ lead_id: getLeadId(), limit })
                : null,
        { cacheTime: 60 * 1000 }
    );

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current
                .querySelector(".ticket-select")
                ?.scrollIntoView();

            containerRef.current
                .querySelector(".message-select")
                ?.scrollIntoView();
        }
    }, []);
    useEffect(() => {
        if (!isLoading) {
            setLoading(false);
        }
    }, [isLoading]);
    useEffect(() => {
        if (isLoading && setFetching) {
            setFetching();
        }
        if (!isLoading && setFetched) {
            setFetched();
        }
    }, [isLoading, setFetched, setFetching]);
    useEffect(() => {
        if (data) {
            dispatch(doSetMessages(data.data));
            dispatch(doSetTotal(data.total));
        }
    }, [data, dispatch]);
    useEffect(() => {
        dispatch(doSetLimitDefault());
    }, [dispatch]);
    const dayName = (item: Message) =>
        moment(item.created_at).format("DD/MM/YYYY");
    const displayedMessages = useMemo(() => {
        if (!leadMessages) {
            return [];
        }

        let messages = leadMessages;

        if (selectedTab === "conversations") {
            messages = messages.filter((item) => item.is_conversation === true);
        }

        return map(groupBy(messages, dayName), (val, key) => ({
            date: key,
            messages: val,
        }));
    }, [leadMessages, selectedTab]);

    return (
        <>
            {loading ? (
                <ChatLoading />
            ) : (
                <StyledGroup ref={containerRef}>
                    {displayedMessages.length && lead ? (
                        displayedMessages.map(({ date, messages: v }) => {
                            return (
                                <Fragment key={date}>
                                    <Divider>
                                        {moment(date, "DD/MM/YYYY").calendar(
                                            null,
                                            {
                                                sameDay: "[Today]",
                                                nextDay: "[Tomorrow]",
                                                nextWeek: "dddd, MMM DD yyyy",
                                                lastDay: "[Yesterday]",
                                                lastWeek: "dddd, MMM DD yyyy",
                                                sameElse: "dddd, MMM DD yyyy",
                                            }
                                        )}
                                    </Divider>

                                    {v.map((i) => (
                                        <>
                                            {((ticket?.message_id === i.id &&
                                                history.location.pathname.includes(
                                                    "tickets"
                                                )) ||
                                                (messageId === i.id &&
                                                    history.location.pathname.includes(
                                                        "messages"
                                                    ))) &&
                                                ticket?.status === "open" && (
                                                    <StyledOpenTicket>
                                                        New
                                                    </StyledOpenTicket>
                                                )}
                                            <Item
                                                key={i.id}
                                                message={i}
                                                lead={lead}
                                                ticket={ticket}
                                            />
                                        </>
                                    ))}
                                </Fragment>
                            );
                        })
                    ) : (
                        <EmptyConversation lead={lead} />
                    )}
                </StyledGroup>
            )}
        </>
    );
};

export default ChatGroup;
