import { Widget } from "src/types/api/form";
import request from "src/utils/request";

export interface CreateWidgetForm {
    color_theme: string;
    widget_options: {
        name: string;
        url?: string;
        type?: string;
        book_appointment_type?: string;
    }[];
}

export interface UpdateWidgetForm {
    color_theme: string;
    widget_options: {
        name: string;
        url?: string;
        type?: string;
        book_appointment_type?: string;
    }[];
}

export const createWidgetApi = (form: CreateWidgetForm): Promise<Widget> =>
    request.post<Widget, Widget>(`/api/widgets`, form);

export const getWidgetApi = (id: number): Promise<Widget> =>
    request.get<Widget, Widget>(`api/widgets/${id}`);

export const updateWidgetApi = (
    id: number,
    form: UpdateWidgetForm
): Promise<Widget> => request.put<Widget, Widget>(`api/widgets/${id}`, form);
