import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { File as FileType } from "src/types/api/ticket";

interface FileState {
    files: FileType[] | null;
    loading: boolean;
    displayMode: "list" | "grid";
    filterType: string;
    filterDate: number;
    loadingForUpload: boolean;
}

const initialState: FileState = {
    files: null,
    loading: false,
    displayMode: "list",
    filterType: "",
    filterDate: 0,
    loadingForUpload: false,
};

const fileSlices = createSlice({
    name: "lead/file",
    initialState,
    reducers: {
        doGetFiles(
            state,
            action: PayloadAction<{
                lead_id?: number;
                page?: number;
                limit?: number;
            } | null>
        ) {
            state.loading = true;
        },
        doGetFilesSuccess(state, action: PayloadAction<FileType[]>) {
            state.loading = false;
            state.files = action.payload;
        },
        doGetFilesFail(state) {
            state.loading = false;
        },
        doSetDisplayMode(state, action: PayloadAction<"list" | "grid">) {
            state.displayMode = action.payload;
        },
        doSetFilterType(state, action: PayloadAction<string>) {
            state.filterType = action.payload;
        },
        doSetFiles(
            state,
            action: PayloadAction<{
                files: FileType[] | null;
                loading: boolean;
            }>
        ) {
            state.files = action.payload.files;
            state.loading = action.payload.loading;
        },
        doSetFilterDate(state, action: PayloadAction<number>) {
            state.filterDate = action.payload;
        },
        doCreateFile(
            state,
            action: PayloadAction<{
                form: File[];
                leadId: number;
                onSuccess?: () => void;
                onFail?: (err: string) => void;
            }>
        ) {
            state.loadingForUpload = true;
        },
        doCreateFileSuccess(state, action: PayloadAction<FileType[]>) {
            state.loadingForUpload = false;
            const oldFiles = state.files;
            if (oldFiles) {
                state.files = [...oldFiles, ...action.payload];
            } else state.files = action.payload;
        },
        doCreateFileFail(state) {
            state.loadingForUpload = false;
        },
    },
});

export default fileSlices.reducer;

export const {
    doGetFiles,
    doGetFilesFail,
    doGetFilesSuccess,
    doSetDisplayMode,
    doSetFilterType,
    doSetFiles,
    doSetFilterDate,
    doCreateFile,
    doCreateFileFail,
    doCreateFileSuccess,
} = fileSlices.actions;
