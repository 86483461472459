import styled, {
    themeGet,
    device,
    css,
    ColorProps,
    color,
} from "@doar/shared/styled";
import { Card } from "@doar/components";
import { Mention } from "react-mentions";

export const StyledCard = styled(({ ...rest }) => <Card {...rest} />)`
    background: #fff;
    box-shadow: none;
    border: none;
    border-radius: 8px;
    margin: 0 25px 20px 25px;
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            background-color: ${themeGet("colors.gray900")};
        `}
`;
export const StyledCardFooter = styled.div`
    margin-top: 5px;
    display: flex;
    align-items: center;
`;
export const StyledLeftFooter = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    .tooltip {
        width: fit-content;
    }
    .avatar {
        cursor: pointer;
    }
`;

export const StyledCardContent = styled(({ ...rest }) => <Card {...rest} />)`
    background-color: #fef6d7;
    box-shadow: none;
    border: none;
    border-radius: 8px;
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            background-color: ${themeGet("colors.gray900")};
        `}

    &:hover {
        .popover {
            display: block;
        }
    }
`;

export const StyledRole = styled.span`
    display: block;
    color: ${themeGet("colors.text3")};
    font-size: 13px;
`;

export const StyledTime = styled.span`
    font-size: 12px;
    color: ${themeGet("colors.text3")};
    align-self: center;
    margin-right: 7px;
    display: none;
    ${device.small} {
        display: block;
    }
`;

export const StyledGrayBox = styled.div`
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 1px solid ${themeGet("colors.border")};
    background-color: ${themeGet("colors.gray50")};
    ${device.small} {
        padding-left: 20px;
        padding-right: 20px;
    }
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            background-color: ${themeGet("colors.dark")};
        `}
`;

export const StyledControl = styled.div`
    display: flex;
    margin-left: auto;
    align-items: center;
    .dropdown {
        height: 18px;
    }
`;

export const StyledWrap = styled.form`
    background-color: #fff;
    /* border-radius: ${themeGet("radii.rounded")}; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    padding: 10px;

    ${(props) =>
        props.theme.name === "dark" &&
        css`
            background-color: ${themeGet("colors.gray900")};
        `}
`;

export const StyledEditNoteInput = styled.textarea`
    margin-left: 10px;
    background: transparent;
    padding: 0;
    border: none;
    padding-top: 11px;
    resize: none;
    border-bottom: 2px solid rgb(25, 118, 210);
`;
export const StyledSpinnerWrapper = styled.div`
    position: absolute;
    right: 25px;
`;
export const StyledMention = styled(Mention)`
    background: red;
`;

export const StyledFileWrap = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const StyledFileThumbIcon = styled(({ ...rest }) => (
    <div {...rest} />
))<ColorProps>`
    background-color: #fff;
    width: 40px;
    height: 40px;
    padding: 10px;
    ${color};
`;

export const StyledFileThumbImg = styled.img`
    width: 40px;
    height: 40px;
    object-fit: cover;
`;

export const StyledFileName = styled.div`
    font-weight: 500;
    margin-left: 10px;
    overflow: hidden;
    max-width: 60%;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: default;
`;

export const StyledDropdownItem = styled.div`
    display: flex;
    align-items: center;
    .staffName {
        margin-left: 10px;
    }
    width: 100%;
    padding: 6px 15px;
    clear: both;
    font-weight: 400;
    color: ${themeGet("colors.gray900")};
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: ${themeGet("colors.gray200")};
    }

    &.is-active {
        background: ${themeGet("colors.gray200")};
        pointer-events: none;
    }
`;

export const StyledNoteWrap = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const StyledPopover = styled.div`
    position: absolute;
    top: calc(100% + 9px);
    display: none;
    min-width: 100px;
    background: #fff;
    min-height: 35px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    &:after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: calc(50% - 7px);
        border-width: 9px;
        border-style: solid;
        border-color: transparent transparent white transparent;
    }
    .spinner {
        height: 18px;
    }
`;

export const StyledEditableWrap = styled.div`
    width: 100%;
    .loading {
        height: 18px;
    }
`;

export const StyledMentionWrap = styled.div`
    background: #fff;
`;
