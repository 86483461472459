import styled, { css, themeGet } from "@doar/shared/styled";

export const StyledToggle = styled.div`
    border-radius: 30px;
    border: 1px solid ${themeGet("colors.border")};
    background: ${themeGet("colors.gray200")};
    width: fit-content;
    padding: 1px 10px;
    display: flex;
    margin-top: 10px;
`;

export const StyledDots = styled.img`
    width: 20px;
`;

export const StyledMessage = styled.div<{ $open: boolean; $height: number }>`
    height: 0;
    transition: 0.5s height;
    overflow: hidden;
    margin-top: 10px;
    ${({ $open, $height }) =>
        $open &&
        css`
            height: ${$height}px;
        `}
`;

export const StyledContentMessage = styled.div`
    width: 100%;
    overflow: auto;
    border: none;
`;

export const StyledLoadingWrap = styled.div`
    height: 300px;
    background: #fff;
    padding: 10px;
`;
