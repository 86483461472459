import { AxiosResponse } from "axios";
import { Project, Token } from "src/types/api/authentication";
import request from "src/utils/request";

export type GetNotification = {
    id: number | null;
    data: {
        message: string;
    };
    type: string;
} & { token?: Token;  project?: Project }

export const getNotificationsApi = (): Promise<GetNotification[]> =>
    request.get<GetNotification[], GetNotification[]>(
        "/api/project/notifications"
    );

export const markNotificationAsReadApi = (notificationId: number): Promise<AxiosResponse> =>
    request.put(`/api/notifications/${notificationId}/markAsRead`);
