import { PayloadAction } from "@reduxjs/toolkit";
import createGenericSlice, {
    GenericState,
} from "src/helpers/createGenericSlice";
import { CommonPagination } from "../../../types/api/common";
import { Note } from "../../../types/api/ticket";

const initialState: GenericState<
    | (CommonPagination<Note> & {
          loadingForCreateNote?: boolean;
          loadingForDeleteNote?: boolean;
      })
    | null
> = {
    data: null,
    status: "finished",
};

const noteSlice = createGenericSlice({
    name: "ticket/note",
    initialState,
    reducers: {
        start(state, action: PayloadAction<{ page?: number; id: number }>) {
            state.status = "loading";
            state.data = null;
        },
        success(state, action: PayloadAction<CommonPagination<Note>>) {
            state.status = "finished";
            state.data = action.payload;
        },
        update(
            state,
            action: PayloadAction<{
                id: number;
                note: string;
                onSuccess: () => void;
                onFail: () => void;
            }>
        ) {},
        updateSuccess(state, action: PayloadAction<Note>) {
            if (state?.data) {
                const index = state.data.data.findIndex(
                    (i) => i.id === action.payload.id
                );

                if (index) {
                    state.data.data.splice(index, 1, action.payload);
                }
            }
        },
        doSetLeadNote(
            state,
            action: PayloadAction<{
                data: CommonPagination<Note> | null;
                status: "loading" | "finished" | "error";
            }>
        ) {
            const reverseArray = (array: Note[]) => {
                const result: Note[] = [];
                for (let i = 0; i < array.length; i += 1) {
                    result.unshift(array[i]);
                }
                return result;
            };
            if (action.payload.data) {
                state.data = {
                    ...action.payload.data,
                    data: reverseArray(action.payload.data.data),
                };
            } else state.data = action.payload.data;
            state.status = action.payload.status;
        },
        doCreateNote(
            state,
            action: PayloadAction<{
                form: {
                    note: string | null;
                    lead_id: number;
                    ticket_id?: number;
                    files?: File[];
                };
                onSuccess: () => void;
                onFail: (err: string) => void;
            }>
        ) {
            if (state.data) {
                state.data.loadingForCreateNote = true;
            }
        },
        doCreateNoteSuccess(state, action: PayloadAction<Note>) {
            if (state.data) {
                state.data.loadingForCreateNote = false;
                const oldData = state.data.data;
                state.data.data = [...oldData, action.payload];
            }
        },
        doCreateNoteHasFileFail(state) {
            if (state.data) {
                state.data.loadingForCreateNote = false;
            }
        },
        doDeleteNote(
            state,
            action: PayloadAction<{
                id: number;
                onSuccess?: () => void;
                onFail?: (err: string) => void;
            }>
        ) {
            if (state.data) {
                state.data.loadingForDeleteNote = true;
            }
        },
        doDeleteNoteSuccess(state, action: PayloadAction<number>) {
            if (state.data) {
                state.data.loadingForDeleteNote = false;
                state.data.data = state.data.data.filter(
                    (i) => i.id !== action.payload
                );
            }
        },
        doDeleteNoteFail(state) {
            if (state.data) {
                state.data.loadingForDeleteNote = false;
            }
        },
    },
});

export const {
    start,
    success,
    fail,
    update,
    updateSuccess,
    doSetLeadNote,
    doCreateNoteHasFileFail,
    doCreateNote,
    doCreateNoteSuccess,
    doDeleteNote,
    doDeleteNoteFail,
    doDeleteNoteSuccess,
} = noteSlice.actions;

export default noteSlice.reducer;
