import { Avatar, Text } from "@doar/components";
import { classic } from "@doar/shared/styled/colors";
import debounce from "debounce-promise";
import { uniqBy } from "lodash-es";
import { AlertCircle } from "react-feather";
import {
    components,
    GroupBase,
    MenuProps,
    Option,
    OptionProps,
    SingleValueProps,
    StylesConfig,
} from "react-select";
import { getChannelsApi } from "src/api/channel/channel";
import { getLeadsApi } from "src/api/lead/lead";
import { getPhoneNumbersApi } from "src/api/phone-number/phoneNumber";
import { StyledOptionIcon } from "./style";

export const promiseSearchEmailChannel = (
    inputValue: string
): Promise<Option[]> =>
    new Promise((resolve, reject) => {
        getChannelsApi({ name: inputValue })
            .then((r) =>
                resolve(
                    uniqBy(r.data, "name")
                        .filter(
                            (c) =>
                                c.channelable_type ===
                                "App\\Models\\EmailChannel"
                        )
                        .map((c) => {
                            return {
                                label: c?.api_integration?.username || "",
                                value: String(c.id),
                            };
                        }) || []
                )
            )
            .catch((e) => reject(e));
    });

export const promiseSearchSmsChannel = (
    inputValue: string
): Promise<Option[]> =>
    new Promise((resolve, reject) => {
        getChannelsApi({ name: inputValue })
            .then((r) =>
                resolve(
                    uniqBy(r.data, "name")
                        .filter(
                            (c) =>
                                c.channelable_type === "App\\Models\\SmsChannel"
                        )
                        .map((c) => {
                            return {
                                label: `${[c.name, c?.api_integration?.username]
                                    .filter((i) => !!i)
                                    .join(" • ")}`,
                                value: String(c.id),
                            };
                        }) || []
                )
            )
            .catch((e) => reject(e));
    });

export const promiseSearchPhoneNumber = (
    inputValue: string
): Promise<Option[]> =>
    new Promise((resolve, reject) => {
        getPhoneNumbersApi({ phone: inputValue })
            .then((r) =>
                resolve(
                    uniqBy(r.data, "name").map((i) => ({
                        label: i.phone,
                        value: String(i.id),
                    })) || []
                )
            )
            .catch((e) => reject(e));
    });

const promiseSearchLead = (inputValue: string): Promise<Option[]> =>
    new Promise((resolve, reject) => {
        getLeadsApi({ keyword: inputValue, limit: 5 })
            .then((r) =>
                resolve(
                    uniqBy(r.data, "email").map((i) => ({
                        label: `${[i.first_name, i.last_name].join(" ")}${
                            i.phone ? ` • ${i.phone}` : ""
                        }`,
                        value: i.email,
                        meta: {
                            avatar:
                                i.staff?.photo ||
                                `https://ui-avatars.com/api/?name=${String(
                                    i.first_name
                                )}+${String(i.last_name)}`,
                            lead_id: i.id,
                        },
                    })) || []
                )
            )
            .catch((e) => reject(e));
    });
export const handleSearchLead = debounce(promiseSearchLead, 700);
export const handleSearchPhoneNumber = debounce(promiseSearchPhoneNumber, 500);
export const handleSearchEmailChannel = debounce(
    promiseSearchEmailChannel,
    500
);
export const handleSearchSmsChannel = debounce(promiseSearchSmsChannel, 500);

const getOptionBackgroundColor = (
    state: OptionProps<Option, true, GroupBase<Option>>
) => {
    if (state.isSelected) {
        return classic.gray700;
    }
    if (state.isFocused) {
        return classic.gray200;
    }
    return classic.white;
};

export const selectStyle: (
    hasError: boolean,
    paddingLeft?: string
) => StylesConfig<Option, true> = (hasError, paddingLeft = "0") => ({
    valueContainer: (base) => ({
        ...base,
        padding: "0",
    }),
    container: (base) => ({
        ...base,
        flexGrow: 1,
        // borderBottom: `1px solid`,
        // borderBottomColor: String(themeGet("colors.gray200")),
    }),
    control: (base) => ({
        ...base,
        ...(hasError
            ? {
                  border: "none",
                  boxShadow: "none",
                  borderBottom: "1px solid #dc3545",
              }
            : {
                  border: "none",
                  boxShadow: "none",
                  borderBottom: `1px solid #e3e7ed`,
              }),
        ...(paddingLeft && {
            paddingLeft,
        }),
    }),
    input: (base) => ({
        ...base,
        margin: 0,
        padding: 0,
    }),
    noOptionsMessage: () => ({
        display: "none",
    }),
    menu: (base) => ({
        ...base,
        zIndex: 11,
    }),
    option: (base, state) => ({
        ...base,
        background: getOptionBackgroundColor(state),
        cursor: "pointer",
    }),
});

export const Menu = (props: MenuProps<Option, true>): JSX.Element => {
    const { options, children } = props;

    if (options.length === 0) {
        return <></>;
    }
    return <components.Menu {...props}>{children}</components.Menu>;
};

export const CustomOption = (props: OptionProps<Option, true>): JSX.Element => {
    const { children, data } = props;

    return (
        <components.Option {...props}>
            <div style={{ display: "flex", alignItems: "center" }}>
                {data.meta?.avatar && (
                    <Avatar size="xs">
                        <img src={String(data.meta?.avatar)} alt="avatar" />
                    </Avatar>
                )}
                {data.icon && (
                    <StyledOptionIcon src={data.icon} alt="channel" />
                )}
                <Text
                    as="span"
                    ml={data.meta?.avatar ? "5px" : "0px"}
                    width="100%"
                >
                    {children}
                </Text>
            </div>
        </components.Option>
    );
};
export const CustomSingleValue = (
    props: SingleValueProps<Option, true>
): JSX.Element => {
    const { children, data } = props;

    return (
        <components.SingleValue {...props}>
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                {children}
                {data.error && (
                    <AlertCircle
                        width={16}
                        height={16}
                        color={classic.danger}
                        style={{ margin: "0 0 2px 5px" }}
                    />
                )}
            </div>
        </components.SingleValue>
    );
};
