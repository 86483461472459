import styled from "@doar/shared/styled";

export const StyledSearch = styled.span`
    position: absolute;
    top: 19px;
    left: 12px;
`;

export const StyledItem = styled.div;

export const StyledWrap = styled.div`
    width: 230px;

    & .dropdownCustom {
        width: 95%;
        min-height: 39px;
        max-height: 200px;
        overflow: auto;
        position: absolute;
        z-index: 1000;
        color: #001737;
        background-color: #fff;
        border: 1px solid rgba(131, 146, 165, 0.27);
        border-radius: 0.25rem;
        box-shadow: 0 0 8px 2px rgb(28 39 60 / 4%);
        display: block;
        margin-top: 0.125rem;
        & .dropdownItem {
            &:hover {
                background: #e3e7ed;
                cursor: pointer;
            }
        }
    }
`;

export const StyledWrapInput = styled.div`
    padding: 10px 0;
    position: relative;

    & #lead_id {
        padding-left: 37px;
    }
`;

export const StyledLoading = styled.div`
    min-width: 30px;
    position: absolute;
    right: 10px;
    top: 30px;
`;

export const StyledNameWrap = styled.div`
    display: flex;
    align-items: center;
    & .name {
        margin-left: 10px;
        text-align: left;
    }

    & .lname {
        color: #d1d9e4;
        margin-left: -5px;
        text-align: left;
    }
`;
