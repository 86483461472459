import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GenericState } from "src/helpers/createGenericSlice";
import { Audit } from "src/types/api/ticket";

const initialState: GenericState<Audit | null> & {
    noteIsSeen: number;
} = {
    data: null,
    status: "finished",
    noteIsSeen: 0,
};

const auditAppSlice = createSlice({
    name: "ticket/audits",
    initialState,
    reducers: {
        create(
            state,
            action: PayloadAction<{
                type: "read" | "ticket_reopen" | "ticket_closed";
                ticketId?: number;
                noteId?: number;
            }>
        ) {
            state.status = "loading";
            if (action.payload.noteId) {
                state.noteIsSeen = action.payload.noteId;
            }
        },
        createSuccess(state) {
            state.status = "finished";
            state.noteIsSeen = 0;
        },
        fail(state) {
            state.status = "error";
        },
    },
});

export const { create, fail, createSuccess } = auditAppSlice.actions;

export default auditAppSlice.reducer;
