import { ApiIntegration, IApiIntegration } from "src/types/api/app";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: IApiIntegration = {
    integration: {
        project_id: 0,
        app_id: 0,
        username: "",
        error: false,
        error_message: "",
        updated_at: new Date(),
        created_at: new Date(),
        id: 0,
        app: {
            api_integrations: [],
            id: 0,
            name: "",
            redirect_url: "",
            description: "",
            image_url: "",
            created_at: new Date(),
            updated_at: new Date(),
        },
        config: {
            customer_id: 0,
            request_consultation_action_id: 0,
            request_quote_action_id: 0,
            customers: [],
        },
    },
    config: {
        customer_id: 0,
    },
};

const integrationSlice = createSlice({
    name: "channels/googleAdsIntegration",
    initialState,
    reducers: {
        doUpdateIntegration(
            state,
            action: PayloadAction<{
                id: number;
                data: IApiIntegration;
                onSuccess?: (response: ApiIntegration) => void;
                onFail?: (errors: string) => void;
            }>
        ) {},
        doUpdateIntegrationSuccess(
            state,
            action: PayloadAction<ApiIntegration>
        ) {
            if (action.payload) {
                state.integration = action.payload;
            }
        },
    },
});
export const { doUpdateIntegration, doUpdateIntegrationSuccess } =
    integrationSlice.actions;

export default integrationSlice.reducer;
