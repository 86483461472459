import { createFileApi, getFilesApi } from "src/api/file/file";
import {
    doCreateFile,
    doCreateFileFail,
    doCreateFileSuccess,
    doGetFiles,
    doGetFilesFail,
    doGetFilesSuccess,
} from "src/redux/slices/lead/file";
import { File } from "src/types/api/ticket";
import { all, takeLatest, call, put, takeEvery } from "typed-redux-saga";

function* getFilesSaga(action: ReturnType<typeof doGetFiles>) {
    try {
        const response = yield* call(
            getFilesApi,
            action.payload ? action.payload : null
        );

        yield* put(doGetFilesSuccess(response.data));
    } catch (err) {
        yield* put(doGetFilesFail());
    }
}

function* createFileSaga(action: ReturnType<typeof doCreateFile>) {
    const { form, leadId, onSuccess, onFail } = action.payload;
    try {
        const result: File[] = [];

        for (let i = 0; i < form.length; i += 1) {
            const file = form[i];
            const formData = new FormData();
            formData.append(
                "original_name",
                file.name.substring(0, file.name.lastIndexOf("."))
            );
            formData.append("fileable_id", String(leadId));
            formData.append("fileable_type", "App\\Models\\Lead");
            formData.append("file", file);

            const response = yield* call(createFileApi, formData);

            result.push(response);
        }

        yield* put(doCreateFileSuccess(result));
        if (onSuccess) {
            yield* call(onSuccess);
        }
    } catch (err) {
        const error = JSON.parse(JSON.stringify(err));
        yield* put(doCreateFileFail());
        if (onFail) {
            yield* call(onFail, error?.data?.message);
        }
    }
}

export function* fileSaga(): Generator {
    yield all([
        takeLatest(doGetFiles, getFilesSaga),
        takeEvery(doCreateFile, createFileSaga),
    ]);
}
