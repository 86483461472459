import { Nav, NavLink } from "@doar/components";
import { parse } from "query-string";
import { FC, useEffect } from "react";
import { AtSign, User } from "react-feather";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "src/redux/hooks";
import { shallowEqual } from "react-redux";
import { mentionIsRead } from "src/containers/apps/ticket/mention/filter";
import useFetchMention from "src/containers/apps/ticket/hook/useFetchMention";
import Label from "../label";
import { StyledBadge, StyledWrap } from "./style";

const LabelNav: FC = () => {
    const location = useLocation();
    const parsed = parse(location.search);
    const data = useAppSelector((store) => store.ticket.statistic.data);

    const [fetchMention] = useFetchMention();

    const { data: mentionData } = useAppSelector(
        (state) => ({
            data: state.ticket.mention.data,
        }),
        shallowEqual
    );

    useEffect(() => {
        fetchMention();
    }, [fetchMention]);

    return (
        <StyledWrap>
            <Label pl="10px">PERSONAL</Label>
            <Nav customStyle="sidebar" fontSize="13px">
                <NavLink
                    path="/tickets?is_me=true"
                    active={String(parsed.is_me) === "true"}
                >
                    <User />
                    <span>Assigned to me</span>
                    {!!data?.assigned_to_me && data?.assigned_to_me !== 0 && (
                        <StyledBadge color="danger" shape="circle" pill>
                            {data?.assigned_to_me > 9
                                ? "9+"
                                : data?.assigned_to_me}
                        </StyledBadge>
                    )}
                </NavLink>
                <NavLink
                    path="/mentions"
                    active={location.pathname === "/mentions"}
                >
                    <AtSign />
                    <span>Mentioned</span>
                    {!!mentionData?.data &&
                        mentionData?.data.length !== 0 &&
                        mentionData?.data.filter((i) => !mentionIsRead(i))
                            .length > 0 && (
                            <StyledBadge color="danger" shape="circle" pill>
                                {mentionData?.data.filter(
                                    (i) => !mentionIsRead(i)
                                ).length > 9
                                    ? "9+"
                                    : mentionData?.data.filter(
                                          (i) => !mentionIsRead(i)
                                      ).length}
                            </StyledBadge>
                        )}
                </NavLink>
            </Nav>
        </StyledWrap>
    );
};

export default LabelNav;
