import React, { FC } from "react";
import FilePreview from "src/components/apps/file-manager/file-preview";
import { Lead, Message, File } from "src/types/api/ticket";
import { StyledWrap } from "./style";

type Props = {
    message: Message;
    lead: Lead;
};
const FileMessage: FC<Props> = ({ message, lead }) => {
    const renderFile = (file: File) => {
        switch (file.mime_type) {
            case "application/pdf": {
                return (
                    <FilePreview
                        icon="far fa-file-pdf"
                        color="danger"
                        title={file.name || ''}
                        link="#!"
                        size={`${(file.size / 1024).toFixed(2)} KB` || ''}
                        type="PDF File"
                        lead={lead}
                        file={file}
                    />
                )
                break;
            }
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            case "application/msword": {
                return (
                    <FilePreview
                        icon="far fa-file-word"
                        color="primary"
                        title={file.name || ''}
                        link="#!"
                        size={`${(file.size / 1024).toFixed(2)} KB` || ''}
                        type="Word Document"
                        lead={lead}
                        file={file}

                    />
                )
                break;
            }
            default: {
                return (
                    <FilePreview
                        icon="far fa-file-image"
                        color="indigo"
                        title={file.name || ''}
                        link="#!"
                        size={`${(file.size / 1024).toFixed(2)} KB` || ''}
                        lead={lead}
                        file={file}
                    />
                )
                break;
            }
        }
    }

    return (
        <StyledWrap >
            {message.messageable.files?.map((vF) => renderFile(vF))}
        </StyledWrap>
    );
};

export default FileMessage;
