import { CommonPagination } from "src/types/api/common";
import { Message } from "src/types/api/ticket";
import request from "src/utils/request";

export type SendMessageParams = {
    message: string | null;
    channel_id: number;
    to: { lead_id?: number; email?: string; phone?: string }[];
    email_message?: {
        html: string | null;
        subject: string;
        cc?: { lead_id?: number; email?: string }[];
        bcc?: { lead_id?: number; email?: string }[];
        thread_id?: number;
    };
    sms_message?: { to: { lead_id?: number; phone?: string }[] };
    reply_to?: number;
    attachments?: number[];
};

export const sendMessageApi = (form: SendMessageParams): Promise<Message> =>
    request.post("api/messages", form);

type GetMessagesParams = {
    lead_id?: number;
    type?: string;
    has?: string;
    with?: string;
    limit?: number;
    page?: number;
};

export const getMessagesApi = (
    params: GetMessagesParams
): Promise<CommonPagination<Message>> =>
    request.get("api/messages", {
        params,
    });

export const getMessageApi = (id: number): Promise<Message> =>
    request.get(`api/messages/${id}`);
