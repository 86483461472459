import {
    Button,
    DropdownMenu,
    DropdownToggle,
    Spinner,
} from "@doar/components";
import { useWindowSize } from "@doar/shared/hooks";
import { toastError } from "src/utils/toast";
import { omit } from "lodash-es";
import { parse, stringify } from "query-string";
import { updateStatus } from "src/redux/slices/ticket/detail";
import { start } from "src/redux/slices/ticket/statistic";
import { updateTicketApi } from "src/api/ticket/ticket";
import React, { FC, useCallback, useEffect, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { ChevronRight, X } from "react-feather";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
    doSetLeadMessageTo,
    doSendMessage,
    doClearFilesAttachment,
} from "src/redux/slices/lead/message";
import SendIcon from "@doar/shared/images/send-icon.svg";
import classNames from "classnames";
import EmailForm from "./email-form";
import SmsForm from "./sms-form";
import MinimizeForm from "./minimize-form";
import { DropdownConfirm } from "./dropdown";
import {
    StyledCardBody,
    StyledCardClose,
    StyledCardTitle,
    StyledChatFormCard,
    StyledChatFormCardHeader,
    StyledDropdown,
    StyledDropdownItem,
    StyledForm,
    StyledInput,
    StyledSendSmsButton,
    StyledSendSmsIcon,
} from "./style";
import EmptyChannelModal from "./empty-channel-modal";
import MissingPhoneModal from "./missing-phone-modal";

type ChannelType = "email" | "sms";

const ChatForm: FC = () => {
    const history = useHistory();
    const { location } = history;
    const queryParams = parse(location.search);
    const { width } = useWindowSize();
    const { id } = useParams<{ id?: string }>();
    const { channels, lead, ticketLead, channelData, loading, leadIdFromUrl } =
        useAppSelector((store) => ({
            channels: store.ticket.channel.data?.data,
            lead: store.contact.lead.lead,
            ticketLead: store.ticket.detail.data?.lead,
            channelData: store.ticket.channel.data,
            loading: store.lead.message.loadingSendMessage,
            leadIdFromUrl: store.contact.lead.leadIdFromUrl,
        }));
    const dispatch = useAppDispatch();
    const initChannel = channelData?.data.find(
        (c) =>
            String(c.id) === String(queryParams.channel) ||
            c.channelable_type === "App\\Models\\SmsChannel"
    );
    const [channel, setChannel] = useState<ChannelType>("email");
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [showEmptyChannelModal, setShowEmptyChannelModal] = useState(false);
    const [showMinimize, setShowMinimize] = useState(false);
    const [smsMessage, setSmsMessage] = useState("");
    const [isShowChannelDropdown, setShowChannelDropdown] = useState(false);
    const [isShowMissingPhone, setShowMissingPhone] = useState(false);
    const [isShowConfirmDropdown, setShowConfirmDropdown] = useState(false);

    const setToField = useCallback(() => {
        /* Check what page are we working on and save lead info to store */
        if (history.location.pathname.includes("tickets") && ticketLead) {
            dispatch(
                doSetLeadMessageTo({
                    id: ticketLead.id,
                    email: ticketLead.email,
                    name: [ticketLead.first_name, ticketLead.last_name].join(
                        " "
                    ),
                })
            );
        }
    }, [dispatch, history.location.pathname, ticketLead]);

    const checkEmptyChannel = useCallback(
        (channelType: "email" | "sms") => {
            if (
                channelType === "email" &&
                !channels?.find((i) => i.channel_app?.type === "email")
            ) {
                setShowEmptyChannelModal(true);
                return false;
            }
            if (
                channelType === "sms" &&
                !channels?.find((i) => i.channel_app?.type === "sms")
            ) {
                setShowEmptyChannelModal(true);
                return false;
            }
            return true;
        },
        [channels]
    );

    const handleOpenComposeMessage = useCallback(
        (c: "sms" | "email", openSms = false) => {
            setChannel(c);
            setToField();
            if (!checkEmptyChannel(c)) {
                return;
            }
            if (c === "email") {
                setShowMessageModal(true);
            }
            if (c === "sms" && openSms) {
                setShowMessageModal(true);
            }
        },
        [checkEmptyChannel, setToField]
    );

    useEffect(() => {
        if (channels && channels.find((i) => i.channel_app?.type === "sms")) {
            handleOpenComposeMessage("sms");
        }
    }, [channels, handleOpenComposeMessage]);

    useEffect(() => {
        if (
            history.location.pathname.includes("leads") &&
            leadIdFromUrl &&
            lead?.id !== leadIdFromUrl
        ) {
            return;
        }
        const searchParams = parse(history.location.search);

        if (searchParams.compose === "sms" && searchParams.modal === "open") {
            handleOpenComposeMessage("sms", true);
        } else if (searchParams.compose === "email") {
            handleOpenComposeMessage("email");
        }
    }, [
        handleOpenComposeMessage,
        history.location.pathname,
        history.location.search,
        lead?.id,
        leadIdFromUrl,
    ]);

    const clearHistoryParams = () => {
        history.push({
            search: stringify({
                ...omit(parse(history.location.search), [
                    "channel",
                    "message_id",
                    "compose",
                    "modal",
                ]),
            }),
        });
    };

    const onCloseEmptyModal = () => {
        setShowEmptyChannelModal(false);
        clearHistoryParams();
    };
    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const m = event.target.value;
        setSmsMessage(m);
    };

    const onSendMessage = () => {
        dispatch(
            doSendMessage({
                form: {
                    message: smsMessage,
                    channel_id: Number(initChannel?.id),
                    to: [
                        {
                            lead_id: Number(lead?.id || 0),
                        },
                    ],
                },
                leadId: lead?.id || 0,
                onSuccess: () => {
                    setSmsMessage("");
                },
            })
        );
    };
    const onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (
            window.location.pathname.includes("tickets") &&
            !isShowConfirmDropdown
        ) {
            setShowConfirmDropdown(true);
            return;
        }
        onSendMessage();
    };

    const chooseStatus = (option: string) => {
        if (id) {
            switch (option) {
                case "send-close-ticket":
                    updateTicketApi(+id, {
                        status: "closed",
                    })
                        .then(() => {
                            dispatch(updateStatus("closed"));
                            dispatch(start());
                        })
                        .catch((e) => {
                            toastError(e);
                        });
                    onSendMessage();
                    setShowConfirmDropdown(false);
                    break;
                case "send-keep-open":
                    updateTicketApi(+id, {
                        status: "open",
                    })
                        .then(() => {
                            dispatch(updateStatus("open"));
                            dispatch(start());
                        })
                        .catch((e) => {
                            toastError(e);
                        });
                    onSendMessage();
                    setShowConfirmDropdown(false);
                    break;
                default:
                    break;
            }
        }
    };

    const toggleMinimize = () => {
        if (showMessageModal) {
            setShowMessageModal(false);
            setShowMinimize(true);
            clearHistoryParams();
        } else {
            if (!checkEmptyChannel("email")) {
                return;
            }
            setShowMessageModal(true);
            setShowMinimize(false);
        }
    };

    const clearAllComposeModal = () => {
        setShowMessageModal(false);
        setShowMinimize(false);
        clearHistoryParams();
        dispatch(doClearFilesAttachment());
    };

    const formChatRef = useOnclickOutside(
        () => {
            if (showMessageModal) {
                toggleMinimize();
            }
        },
        {
            ignoreClass: ["form-control", "dropdown-toggle"],
        }
    );

    const onClickInput = () => {
        history.push({
            search: stringify({
                ...omit(parse(history.location.search), [
                    "channel",
                    "message_id",
                ]),
                compose: channel,
            }),
        });
        if (channel === "sms") {
            if (!lead?.phone) {
                setShowMissingPhone(true);
            }
            return;
        }
        toggleMinimize();
    };

    const closeEmailModal = () => {
        clearAllComposeModal();
    };

    const onChangeChannelInDropdown = (c: "email" | "sms") => {
        setToField();
        setChannel(c);
        handleOpenComposeMessage(c);
        setShowChannelDropdown(false);
        history.push({
            search: stringify({
                ...omit(parse(history.location.search), [
                    "channel",
                    "message_id",
                ]),
                compose: c,
            }),
        });
    };

    return (
        <>
            <StyledForm onSubmit={onSubmit}>
                <StyledDropdown
                    direction="up"
                    isShow={isShowChannelDropdown}
                    getState={(state: boolean) => setShowChannelDropdown(state)}
                >
                    <DropdownToggle
                        size="sm"
                        variant="contained"
                        color="light"
                        width="100%"
                        height="100%"
                        disabled={!lead}
                    >
                        {channel === "email" ? "By Email" : "By SMS"}
                        <ChevronRight width="16" height="16" />
                    </DropdownToggle>
                    <DropdownMenu show={isShowChannelDropdown}>
                        <StyledDropdownItem
                            active={channel === "email"}
                            onClick={() => onChangeChannelInDropdown("email")}
                        >
                            By Email
                        </StyledDropdownItem>
                        <StyledDropdownItem
                            active={channel === "sms"}
                            onClick={() => onChangeChannelInDropdown("sms")}
                        >
                            By SMS
                        </StyledDropdownItem>
                    </DropdownMenu>
                </StyledDropdown>

                <StyledInput
                    type="text"
                    id="message"
                    className={classNames({
                        disabled: channel === "email",
                    })}
                    name="message"
                    placeholder="Message"
                    value={smsMessage}
                    onChange={onChangeInput}
                    autoComplete="off"
                    onClick={onClickInput}
                    disabled={!lead}
                />
                {channel === "sms" && (
                    <StyledSendSmsButton>
                        <Button type="submit" disabled={!smsMessage || loading}>
                            {loading ? (
                                <div className="loading">
                                    <Spinner size="xs" color="white" />
                                </div>
                            ) : (
                                <StyledSendSmsIcon src={SendIcon} />
                            )}
                        </Button>
                        {isShowConfirmDropdown && (
                            <DropdownConfirm
                                isConfirmSms
                                isShowConfirmDropdown={isShowConfirmDropdown}
                                setShowConfirmDropdown={setShowConfirmDropdown}
                                chooseStatus={chooseStatus}
                            />
                        )}
                    </StyledSendSmsButton>
                )}
            </StyledForm>
            {channel === "email" && (
                <MinimizeForm
                    modalOpen={toggleMinimize}
                    minimizeShow={showMinimize}
                    handleMinimize={clearAllComposeModal}
                />
            )}
            {/* {channel === "email" && ( */}
            <StyledChatFormCard
                ref={formChatRef}
                $show={showMessageModal}
                $width={width || 300}
                $inLeadPage={!!history.location.pathname.includes("leads")}
            >
                <StyledChatFormCardHeader>
                    <StyledCardTitle>New message</StyledCardTitle>
                    <StyledCardClose onClick={clearAllComposeModal}>
                        <X />
                    </StyledCardClose>
                </StyledChatFormCardHeader>{" "}
                <StyledCardBody>
                    {channel === "email" ? (
                        <EmailForm handleModal={closeEmailModal} />
                    ) : (
                        <SmsForm handleModal={closeEmailModal} />
                    )}
                </StyledCardBody>
            </StyledChatFormCard>
            {/* )} */}

            <EmptyChannelModal
                show={showEmptyChannelModal}
                onClose={onCloseEmptyModal}
                channel={channel}
            />
            <MissingPhoneModal
                show={isShowMissingPhone}
                leadId={lead?.id || 0}
                onClose={() => setShowMissingPhone(false)}
            />
        </>
    );
};

export default ChatForm;
