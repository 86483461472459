/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useRef, useState } from "react";
import ThreeDots from "@doar/shared/images/3-dots.svg";
import { Message } from "src/types/api/ticket";
import DOMPurify from "dompurify";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { doGetMessage } from "src/redux/slices/lead/message";
import {
    StyledContentMessage,
    StyledDots,
    StyledMessage,
    StyledToggle,
} from "./style";
import HtmlLoading from "./loading";

interface IProps {
    message: Message;
}
const ReplyToHtml: FC<IProps> = ({ message }) => {
    const dispatch = useAppDispatch();
    const { loadingGetMessage } = useAppSelector((store) => store.lead.message);
    const messageRef = useRef<HTMLDivElement>(null);
    const loadingRef = useRef<HTMLDivElement>(null);
    const [showReplyToHtml, setShowReplyToHtml] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);

    useEffect(() => {
        if (messageRef?.current && messageRef.current.offsetHeight) {
            setContentHeight(messageRef.current.offsetHeight);
        } else if (
            loadingRef?.current &&
            loadingRef.current.offsetHeight &&
            loadingGetMessage
        ) {
            setContentHeight(loadingRef.current.offsetHeight);
        }
    }, [
        messageRef?.current?.offsetHeight,
        loadingRef?.current?.offsetHeight,
        loadingGetMessage,
    ]);

    const toggle = () => {
        setShowReplyToHtml(!showReplyToHtml);
        if (!message.reply_to_message) {
            dispatch(doGetMessage(message.id));
        }
    };

    return (
        <>
            <StyledToggle onClick={toggle}>
                <StyledDots src={ThreeDots} alt="" />
            </StyledToggle>
            <StyledMessage $height={contentHeight} $open={showReplyToHtml}>
                {loadingGetMessage ? (
                    <div ref={loadingRef}>
                        <HtmlLoading />
                    </div>
                ) : (
                    <>
                        {message.reply_to_message?.messageable.html && (
                            <StyledContentMessage
                                ref={messageRef}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        message.reply_to_message.messageable
                                            .html
                                    ),
                                }}
                            />
                        )}
                    </>
                )}
            </StyledMessage>
        </>
    );
};

export default ReplyToHtml;
