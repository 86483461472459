import { omit } from "lodash-es";
import { parse, stringify } from "query-string";
import { TicketStatus } from "src/types/api/ticket";
import history from "src/utils/history";
import { toastError } from "src/utils/toast";
import { all, call, fork, put, select, takeLatest } from "typed-redux-saga";
import {
    deleteTicketBulkApi,
    getCountOfTicketsApi,
    getTicketsApi,
    updateTicketBulkApi,
} from "../../api/ticket/ticket";
import { clearRoom } from "../slices/ticket/daily";
import {
    assignStaff,
    unAssignStaff,
    updateStatus,
} from "../slices/ticket/detail";
import {
    clearSelectAll,
    doSetDeleted,
    fail as getListTicketFail,
    getCountOfTicket,
    getCountOfTicketsFail,
    getCountOfTicketsSuccess,
    loadMore,
    loadMoreSuccess,
    setListTickets,
    success as getListTicketSuccess,
    updateBatched,
    updateList,
} from "../slices/ticket/list";
import { start as startTicketStatistic } from "../slices/ticket/statistic";
import {
    doCheckMeetingRoom,
    doCheckMeetingRoomFail,
    doCheckMeetingRoomSuccess,
} from "../slices/ticket/ui";
import { RootState } from "../store";
import { auditSaga } from "./ticket/audit";
import { channelSaga } from "./ticket/channel";
import { channelAppSaga } from "./ticket/channelApp";
import { dailyRoomSaga } from "./ticket/daily";
import { leadSaga } from "./ticket/lead";
import { mentionSaga } from "./ticket/mention";
import { messageSaga } from "./ticket/message";
import { noteSaga } from "./ticket/note";
import { statisticSaga } from "./ticket/statistic";
import { taskSaga } from "./ticket/task";

function* handleListTickets(action: ReturnType<typeof setListTickets>) {
    yield* put(
        doCheckMeetingRoomSuccess({
            path: window.location.pathname.split("/")[1],
        })
    );

    const getTicketsParams = yield* select(
        (store: RootState) => store.ticket.list.getTicketsParams
    );

    const listTickets = action.payload?.data?.data;

    if (
        listTickets &&
        listTickets.length > 0 &&
        !getTicketsParams.type &&
        !getTicketsParams.keyword &&
        history.location.pathname === "/tickets"
    ) {
        const firstTicketID = listTickets.find(
            (ticket) => ticket.type !== "reminder" && ticket.type !== "task"
        )?.id;
        history.push({
            pathname: `/tickets/${firstTicketID}`,
            search: stringify({
                ...omit(parse(history.location.search), [
                    "compose",
                    "channel",
                    "message_id",
                ]),
            }),
        });
    }
}
function* loadMoreListTicketSaga(action: ReturnType<typeof loadMore>) {
    try {
        const response = yield* call(getTicketsApi, {
            page: action.payload.page,
        });
        yield* put(loadMoreSuccess(response));
    } catch (error) {
        yield* put(getListTicketFail());
    }
}

function* updateStatusSaga(action: ReturnType<typeof updateStatus>) {
    const { detailTicket, ticketList } = yield* select((store: RootState) => ({
        detailTicket: store.ticket.detail.data,
        ticketList: store.ticket.list.data?.data,
    }));

    if (detailTicket && ticketList) {
        yield* put(
            updateList(
                ticketList.map((ticket) => {
                    if (ticket.id !== detailTicket.id) {
                        return ticket;
                    }

                    return {
                        ...ticket,
                        status: action.payload,
                    };
                })
            )
        );
    }
}

function* assignStaffSaga(action: ReturnType<typeof assignStaff>) {
    const { detailTicket, ticketList } = yield* select((store: RootState) => ({
        detailTicket: store.ticket.detail.data,
        ticketList: store.ticket.list.data?.data,
    }));

    if (detailTicket && ticketList) {
        yield* put(
            updateList(
                ticketList.map((ticket) => {
                    if (ticket.id !== detailTicket.id) {
                        return ticket;
                    }

                    return {
                        ...ticket,
                        staff_id: action.payload.id,
                        staff: action.payload,
                        status:
                            ticket.status === "open"
                                ? "assigned"
                                : ticket.status,
                    };
                })
            )
        );
    }
}
function* unAssignStaffSaga() {
    const { detailTicket, ticketList } = yield* select((store: RootState) => ({
        detailTicket: store.ticket.detail.data,
        ticketList: store.ticket.list.data?.data,
    }));

    if (detailTicket && ticketList) {
        const newTicket = updateList(
            ticketList.map((ticket) => {
                if (ticket.id !== detailTicket.id) {
                    return ticket;
                }

                return {
                    ...ticket,
                    staff_id: null,
                    staff: undefined,
                    status: "open",
                };
            })
        );
        yield* put(newTicket);
    }
}

function* updateBatchedSaga(action: ReturnType<typeof updateBatched>) {
    try {
        const selectedTicketIds = yield* select(
            (store: RootState) => store.ticket.list.selectedTicketIds
        );
        const user = yield* select(
            (store: RootState) => store.authentication.user
        );
        if (selectedTicketIds.length === 0) {
            return;
        }
        if (action.payload.type === "deleted") {
            yield* call(deleteTicketBulkApi, { ids: selectedTicketIds });
            yield* put(doSetDeleted());
        } else {
            yield* call(updateTicketBulkApi, {
                status: action.payload.type,
                ids: selectedTicketIds,
                staff_id: action.payload.staff_id || undefined,
            });
        }
        yield* put(clearSelectAll());
        yield* put(startTicketStatistic());

        const parsed = parse(history.location.search);
        let status: TicketStatus | undefined = "open";
        // eslint-disable-next-line @typescript-eslint/naming-convention
        let staff_id: number | undefined;
        const channelId: number | undefined = parsed.channel_id
            ? Number(parsed.channel_id)
            : undefined;
        if (parsed.status) {
            status = String(parsed.status) as TicketStatus;
        }
        if (parsed.is_me && String(parsed.is_me) === "true") {
            staff_id = user?.staff?.id;
            status = "assigned";
        }
        if (parsed.mention_me && String(parsed.mention_me) === "true") {
            staff_id = undefined;
            status = "mentioned";
        }
        const response = yield* call(getTicketsApi, {
            page: 1,
            status,
            staff_id,
            channel_id: channelId,
        });

        yield* put(getListTicketSuccess(response));
    } catch (error) {
        toastError(error);
    }
}

function* getCountOfTicketsSaga(action: ReturnType<typeof getCountOfTicket>) {
    try {
        const response = yield* call(getCountOfTicketsApi);
        yield* put(getCountOfTicketsSuccess(response));
    } catch (error) {
        yield* put(getCountOfTicketsFail());
    }
}

function* doCheckMeetingRoomSaga(
    action: ReturnType<typeof doCheckMeetingRoom>
) {
    try {
        if (action.payload.room) {
            yield* put(clearRoom());
        }
        yield* put(
            doCheckMeetingRoomSuccess({
                path: window.location.pathname.split("/")[1],
            })
        );
    } catch (error) {
        yield* put(doCheckMeetingRoomFail());
    }
}

export function* ticketSaga(): Generator {
    yield all([
        takeLatest(setListTickets, handleListTickets),
        takeLatest(loadMore, loadMoreListTicketSaga),
        takeLatest(updateStatus, updateStatusSaga),
        takeLatest(assignStaff, assignStaffSaga),
        takeLatest(unAssignStaff, unAssignStaffSaga),
        takeLatest(updateBatched, updateBatchedSaga),
        takeLatest(doCheckMeetingRoom, doCheckMeetingRoomSaga),
        takeLatest(getCountOfTicket, getCountOfTicketsSaga),
        fork(noteSaga),
        fork(auditSaga),
        fork(dailyRoomSaga),
        fork(taskSaga),
        fork(leadSaga),
        fork(mentionSaga),
        fork(messageSaga),
        fork(statisticSaga),
        fork(channelSaga),
        fork(channelAppSaga),
    ]);
}
