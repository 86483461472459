import { CommonPagination } from "src/types/api/common";
import request from "src/utils/request";
import { Task } from "../../types/api/ticket";

export const getTasksApi = (): Promise<CommonPagination<Task>> =>
    request.get<CommonPagination<Task>, CommonPagination<Task>>("/api/tasks");

export type CreateTaskRequestParams = {
    name?: string;
    has_reminder?: boolean;
    lead_id?: number;
    staff_id?: number;
    description?: string;
    reminder_date?: number;
    created_at?: Date;
    updated_at?: Date;
};

export type UpdateTaskForm = {
    name?: string;
    has_reminder?: boolean;
    lead_id?: number | null;
    staff_id?: number | null;
    description?: string;
    reminder_date?: number;
    created_at?: Date;
    updated_at?: Date;
};

export const createTaskApi = (form: CreateTaskRequestParams): Promise<Task> =>
    request.post<Task, Task>("/api/tasks", form);

export const updateTaskApi = (
    id: number,
    form: UpdateTaskForm
): Promise<Task> => request.put<Task, Task>(`/api/tasks/${id}`, form);

export const deleteTaskApi = (id: number): Promise<unknown> =>
    request.delete(`api/tasks/${id}`);
