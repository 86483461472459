/* eslint-disable @typescript-eslint/no-unused-vars */
import { Spinner } from "@doar/components";
import { FC, useCallback, useEffect, useState } from "react";
import { X } from "react-feather";
import { createFileApi } from "src/api/file/file";
import { formatBytes } from "src/helpers/fileHelpers";
import { FilePreview } from "src/types/api/message";
import { File as FileType } from "src/types/api/ticket";
import {
    StyledFileAttach,
    StyledFileAttachWrap,
    StyledFileName,
    StyledFileSize,
    StyledRemoveFile,
} from "./style";

interface IProps {
    files: FilePreview[];
    onChangeFiles: (files: FilePreview[]) => void;
}

const FilesAttachment: FC<IProps> = ({ files, onChangeFiles }) => {
    const onRemoveFile = (fileIndex: number) => {
        const newFileArray = files.filter((f, i) => i !== fileIndex);

        onChangeFiles(newFileArray);
    };

    return (
        <StyledFileAttachWrap>
            {files.map((f, index) => {
                const file = f.preview;
                return (
                    <StyledFileAttach key={String(index)}>
                        <StyledFileName>{file.name}</StyledFileName>
                        <StyledFileSize>
                            ({formatBytes(file.size)})
                        </StyledFileSize>
                        <StyledRemoveFile onClick={() => onRemoveFile(index)}>
                            {f.file ? (
                                <X size={15} />
                            ) : (
                                <Spinner size="xs" color="primary" />
                            )}
                        </StyledRemoveFile>
                    </StyledFileAttach>
                );
            })}
        </StyledFileAttachWrap>
    );
};

export default FilesAttachment;
