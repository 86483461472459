import styled, { themeGet } from "@doar/shared/styled";

export const StyledNote = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    &:focus-visible {
        outline: none;
    }
`;

export const StyledContent = styled.div`
    padding: 10px 25px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    &:focus-visible {
        outline: none;
    }
    .note-wrap {
        flex: 1;
    }
`;

export const StyledNoteWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: relative;
    overflow: hidden;
`;

export const StyledFormTitle = styled.div`
    font-weight: 600;
    font-size: 1rem;
    margin: 1rem;
`;

export const StyledHr = styled.hr`
    margin: 0;
`;

export const StyledBackgroundDraggable = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    opacity: 0.9;
    animation: 0.3s boundOut ease-out;
    pointer-events: none;
    z-index: 10;
    p {
        font-size: 2rem;
        color: ${themeGet("colors.gray600")};
        font-weight: 500;
        width: 100%;
        pointer-events: none;
        text-align: center;
    }
    @keyframes boundOut {
        0% {
            font-size: 1.5rem;
        }
        100% {
            font-size: 2rem;
        }
    }
`;
