/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useRef, useState } from "react";
import { PositionProps } from "@doar/shared/styled";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useAppSelector } from "src/redux/hooks";
import { StyledWrap } from "./style";

interface IProps extends PositionProps {
    childrenHeight?: number;
    fetchOldMessages?: () => void;
}
const ScrollBar: FC<IProps> = ({
    children,
    childrenHeight,
    fetchOldMessages,
    ...rest
}) => {
    const ref = useRef<any>(null);
    const { sent } = useAppSelector((state) => ({
        sent: state.lead.message.sent,
    }));
    const [oldHeight, setOldHeight] = useState<number | undefined>();
    const [isFirstFetch, setIsFirstFetch] = useState<boolean>(false);
    const [isFetchOldMessages, setIsFetchOldMessages] =
        useState<boolean>(false);
    useEffect(() => {
        if (!isFetchOldMessages) {
            ref.current.scrollTop = childrenHeight;
        }
    }, [childrenHeight, isFetchOldMessages, ref]);

    useEffect(() => {
        if (childrenHeight) {
            if (oldHeight !== undefined) {
                ref.current.scrollTop = childrenHeight - oldHeight;
            } else ref.current.scrollTop = childrenHeight;
            setOldHeight(0);
        }
        if (childrenHeight === ref.current.scrollTop) {
            setIsFirstFetch(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childrenHeight]);

    useEffect(() => {
        ref.current.scrollTop = childrenHeight;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sent]);
    const handleScroll = () => {
        if (ref.current.scrollTop === 0 && fetchOldMessages && isFirstFetch) {
            fetchOldMessages();
            setOldHeight(childrenHeight);
            setIsFetchOldMessages(true);
        }
    };
    return (
        <StyledWrap {...rest}>
            <PerfectScrollbar
                containerRef={(r) => {
                    ref.current = r;
                }}
                onScroll={handleScroll}
            >
                {children}
            </PerfectScrollbar>
        </StyledWrap>
    );
};

export default ScrollBar;
