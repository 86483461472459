import styled, { themeGet } from "@doar/shared/styled";
import Modal from "src/components/custom-modal";

export const StyledModal = styled(({ ...rest }) => <Modal {...rest} />)`
    & .modal-content {
        box-shadow: 0 0 20px 10px ${themeGet("colors.text4")};
        padding: 20px;
    }
`;

export const StyledWrapButtons = styled.div`
    display: flex;
    margin-left: auto;
    button {
        &.cancelBtn {
            color: ${themeGet("colors.primary")};
            margin-right: 5px;
        }
    }
`;
