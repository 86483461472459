import { Button, Spinner, Input } from "@doar/components";
import { FC, useState } from "react";
import { useAppDispatch } from "src/redux/hooks";
import * as yup from "yup";
import { Edit } from "react-feather";
import { useForm } from "react-hook-form";
import { hasKey } from "@doar/shared/methods";
import { yupResolver } from "@hookform/resolvers/yup";
import { Lead } from "src/types/api/ticket";
import { doUpdateLead } from "src/redux/slices/contacts/lead";
import { StyledInputWrapper, StyledWrapper } from "./style";

interface IProps {
    lead: Lead | null | undefined;
    onClose: () => void;
    onAddEmailSuccess?: (leadId?: number) => void;
    onAddEmailFail?: () => void;
}

interface IFormValues {
    email: string;
}

const schema = yup.object().shape({
    email: yup.string().required().trim().email(),
});

const AddNewEmailContainer: FC<IProps> = ({
    lead,
    onClose,
    onAddEmailSuccess,
    onAddEmailFail,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<IFormValues>({
        resolver: yupResolver(schema),
    });
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);

    const handleUpdateEmail = (data: IFormValues) => {
        setLoading(true);
        if (lead) {
            dispatch(
                doUpdateLead({
                    id: lead.id,
                    form: {
                        email: data.email,
                    },
                    onSuccess: () => {
                        setLoading(false);
                        onClose();
                        if (onAddEmailSuccess) {
                            onAddEmailSuccess(lead.id);
                        }
                    },
                    onFail: (err) => {
                        setLoading(false);
                        setError("email", {
                            type: "manual",
                            message: err,
                        });
                        if (onAddEmailFail) {
                            onAddEmailFail();
                        }
                    },
                })
            );
        }
    };

    return (
        <StyledWrapper>
            <Button type="submit" variant="texted">
                Add missing email
            </Button>
            <form
                onSubmit={handleSubmit(handleUpdateEmail)}
                style={{ display: "flex", height: "62px", marginBottom: "5px" }}
            >
                <StyledInputWrapper>
                    <Input
                        id="email"
                        type="email"
                        placeholder="Enter your email address"
                        feedbackText={errors?.email?.message}
                        state={hasKey(errors, "email") ? "error" : "success"}
                        showState={!!hasKey(errors, "email")}
                        {...register("email")}
                    />
                </StyledInputWrapper>
                <div style={{ padding: "0.5em 0.5em 0.5em 0" }}>
                    <Button
                        color="primary"
                        type="submit"
                        disabled={loading}
                        height={38}
                        width={38}
                        padding="0 0.5rem !important"
                        margin="0 !important"
                    >
                        {loading ? (
                            <Spinner size="xs" color="white" />
                        ) : (
                            <Edit size={20} color="white" />
                        )}
                    </Button>
                </div>
            </form>
        </StyledWrapper>
    );
};

export default AddNewEmailContainer;
