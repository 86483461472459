import { FC, useState } from "react";
import { X } from "react-feather";
import ModalViewSelectFile from "../apps/file-manager/modal-select-view";
import {
    StyledPreviewImage,
    StyledPreviewImgWrap,
    StyledRemoveFile,
} from "./style";

interface IProps {
    index: number;
    file: File;
    onRemoveImage: (index: number) => void;
}

const ImagePreview: FC<IProps> = ({ index, file, onRemoveImage }) => {
    const [showPreview, setShowPreview] = useState(false);

    return (
        <>
            <StyledPreviewImgWrap key={String(index)}>
                <StyledPreviewImage
                    src={URL.createObjectURL(file)}
                    alt=""
                    onClick={() => setShowPreview(true)}
                />
                <StyledRemoveFile
                    className="remove-btn"
                    onClick={() => onRemoveImage(index)}
                >
                    <X size={15} color="#fff" />
                </StyledRemoveFile>
            </StyledPreviewImgWrap>
            <ModalViewSelectFile
                show={showPreview}
                onClose={() => setShowPreview(false)}
                src={URL.createObjectURL(file)}
                type={file.type}
                name={file.name}
            />
        </>
    );
};

export default ImagePreview;
