import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GenericState } from "src/helpers/createGenericSlice";
import { CommonPagination } from "../../../types/api/common";
import { Message } from "../../../types/api/ticket";

type MessageState = GenericState<CommonPagination<Message> | null> & {
    details: Message | null;
};

const initialState: MessageState = {
    data: null,
    status: "finished",
    details: null,
};

const messagesSlice = createSlice({
    name: "ticket/message",
    initialState,
    reducers: {
        start(state, action: PayloadAction) {
            state.status = "loading";
            state.data = null;
        },
        loadMore(state, action: PayloadAction<{ page: number }>) {
            state.status = "loading";
        },
        success(state, action: PayloadAction<CommonPagination<Message>>) {
            state.status = "finished";
            state.data = action.payload;
        },
        loadMoreSuccess(
            state,
            action: PayloadAction<CommonPagination<Message>>
        ) {
            state.status = "finished";
            state.data = {
                ...state.data,
                ...action.payload,
                data: [...(state.data?.data || []), ...action.payload.data],
            };
        },
        setMessageDetails(state, action: PayloadAction<Message | null>) {
            state.details = action.payload;
        },
        setMessages(
            state,
            action: PayloadAction<{
                data: CommonPagination<Message> | null;
                status: "loading" | "finished";
            }>
        ) {
            const { data, status } = action.payload;
            state.data = data;
            state.status = status;
        },
    },
});

export const {
    start,
    success,
    loadMore,
    loadMoreSuccess,
    setMessageDetails,
    setMessages,
} = messagesSlice.actions;

export default messagesSlice.reducer;
