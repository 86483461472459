import styled, { themeGet } from "@doar/shared/styled";

export const StyledWrapSelect = styled.div`
    margin-bottom: 10px;
    margin-top: -18px;
    padding: 0 20%;
    cursor: pointer;
    &.is-loading {
        pointer-events: none;
        & .custom-select {
            background-color: ${themeGet("colors.light")};
        }
    }
`;

export const StyledWrapDropdown = styled.div`
    width: 192px;
    margin: 0 auto;
`;

export const StyledMargin = styled.div`
    margin: 10px 0 20px 0;
`;
