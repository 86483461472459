import {
    doCreateWidget,
    doCreateWidgetSuccess,
    doCreateWidgetFail,
    doGetWidget,
    doGetWidgetSuccess,
    doGetWidgetFail,
    doUpdateWidget,
    doUpdateWidgetSuccess,
} from "src/redux/slices/settings/channels/channel";
import { all, call, takeLatest, put } from "typed-redux-saga";
import {
    createWidgetApi,
    getWidgetApi,
    updateWidgetApi,
} from "src/api/widgets/widget";

function* createWidgetSaga(action: ReturnType<typeof doCreateWidget>) {
    try {
        const response = yield* call(createWidgetApi, action.payload.form);

        yield* put(doCreateWidgetSuccess(response));
    } catch (err) {
        yield* put(doCreateWidgetFail());
    }
}
function* getWidgetSaga(action: ReturnType<typeof doGetWidget>) {
    try {
        const response = yield* call(getWidgetApi, action.payload);

        yield* put(doGetWidgetSuccess(response));
    } catch (err) {
        yield* put(doGetWidgetFail());
    }
}
function* updateWidgetSaga(action: ReturnType<typeof doUpdateWidget>) {
    try {
        const response = yield* call(
            updateWidgetApi,
            action.payload.id,
            action.payload.form
        );

        yield* put(doUpdateWidgetSuccess(response));
    } catch (err) {
        yield* put(doGetWidgetFail());
    }
}

export function* widgetSaga(): Generator {
    yield all([
        takeLatest(doCreateWidget, createWidgetSaga),
        takeLatest(doGetWidget, getWidgetSaga),
        takeLatest(doUpdateWidget, updateWidgetSaga),
    ]);
}
