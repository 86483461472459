import { FC } from "react";
import { X } from "react-feather";
import { useHistory, matchPath } from "react-router-dom";
import {
    StyledMinimize,
    StyledParagraph,
    StyledMinimizeCard,
    StyledMinimizeClose,
} from "./style";

interface Props {
    modalOpen: () => void;
    minimizeShow?: boolean;
    handleMinimize: () => void;
}

const MinimizeForm: FC<Props> = ({
    modalOpen,
    minimizeShow,
    handleMinimize,
}) => {
    const history = useHistory();
    const path = matchPath(history.location.pathname, { path: "/leads/:id" });
    const isConversation = () => {
        if (path != null) {
            return true;
        }
        return false;
    };

    return (
        <StyledMinimizeCard
            $show={minimizeShow}
            $isConversation={isConversation()}
        >
            <StyledMinimize>
                <StyledParagraph onClick={() => modalOpen()}>
                    New Message
                </StyledParagraph>
                <StyledMinimizeClose onClick={handleMinimize}>
                    <X />
                </StyledMinimizeClose>
            </StyledMinimize>
        </StyledMinimizeCard>
    );
};

export default MinimizeForm;
