export const countriesNotSupported = [
    "AC",
    "BQ",
    "GP",
    "MF",
    "NC",
    "PM",
    "RE",
    "SH",
    "SJ",
    "TA",
    "WF",
    "YT",
];

export const countriesPrioritized = ["US", "GB", "BR", "FR", "MX"];
