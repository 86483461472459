import styled, { themeGet, css } from "@doar/shared/styled";

export const StyledWrap = styled.div<{
    $hasError?: boolean;
    $disabled?: boolean;
}>`
    display: flex;
    height: 38px;
    background: #fff;
    border: 1px solid ${themeGet("colors.text4")};
    border-radius: 5px;

    input {
        border: none;
        height: 36px;
        padding-left: 0;
        &:focus,
        &:focus-visible {
            box-shadow: none;
            outline: none;
        }
    }
    & .is-loading {
        pointer-events: none;
        background-color: #f5f6fa;
    }
    ${({ $hasError }) =>
        $hasError &&
        css`
            border-color: ${themeGet("colors.danger")};
        `}
    ${({ $disabled }) =>
        $disabled &&
        css`
            background: ${themeGet("colors.gray100")} !important;
        `}
`;

export const StyledCountriesDropdown = styled.div`
    position: relative;
    margin: 2px;
    height: calc(100% - 4px);
    min-width: 78px;
`;

export const StyledCountriesDropdownToggle = styled.div`
    display: flex;
    justify-content: flex-start;
    height: 100%;
    align-items: center;
    border-radius: 4px;
    padding: 4px 4px 4px 10px;
`;

export const StyledCountryFlag = styled.img`
    width: 20px;
    margin-right: 5px;
`;

export const StyledCountriesDropdownMenu = styled.div<{ $show?: boolean }>`
    position: absolute;
    top: calc(100% + 2px);
    background: #fff;
    width: 100%;
    border: 1px solid ${themeGet("colors.text4")};
    z-index: 10;
    padding: 4px;
    display: none;
    ${({ $show }) =>
        $show &&
        css`
            display: block;
        `}
    .ReactVirtualized__List {
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

export const StyledCountriesDropdownItem = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px;
    clear: both;
    font-weight: 400;
    color: #1c273c;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: ${themeGet("colors.gray200")};
    }
`;

export const StyledDownIcon = styled.img`
    width: 28px;
`;

export const StyledErrorMsg = styled.div`
    position: absolute;
    z-index: 1;
    font-size: 80%;
    div {
        font-size: inherit;
    }
`;
