import styled from "@doar/shared/styled";

export const StyledDotsLoading = styled.div`
    position: relative;
    width: 4px;
    height: 4px;
    border-radius: 5px;
    background-color: #212121;
    color: #212121;
    animation: dotFlashing 0.5s infinite linear alternate;
    animation-delay: 0.25s;
    &::before,
    &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
    }
    &::before {
        left: -9px;
        width: 4px;
        height: 4px;
        border-radius: 5px;
        background-color: #212121;
        color: #212121;
        animation: dotFlashing 0.5s infinite alternate;
        animation-delay: 0s;
    }
    &::after {
        left: 9px;
        width: 4px;
        height: 4px;
        border-radius: 5px;
        background-color: #212121;
        color: #212121;
        animation: dotFlashing 0.5s infinite alternate;
        animation-delay: 0.5s;
    }
    @keyframes dotFlashing {
        0% {
            background-color: #212121;
        }
        50% {
            background-color: #aaaaaa;
            opacity: 0.5;
        }
        100% {
            background-color: #aaaaaa;
            opacity: 0;
        }
    }
`;
