import styled from "@doar/shared/styled";

export const StyledLoadingWrapper = styled.div`
    padding: 0 10px;
    overflow: hidden;
    flex: 1;
`;

export const StyledInfo = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledAvt = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
`;
