import { Card } from "@doar/components";
import styled from "@doar/shared";
import { themeGet, css } from "@doar/shared/styled";

export const StyledCard = styled(({ ...rest }) => <Card {...rest} />)`
    box-shadow: none;
    max-width: 400px;
    position: relative;
    border-radius: 5px;
    width: fit-content;
    min-width: 200px;
    padding: 10px;
    ${(props) => {
        if (props.isOutbound) {
            return css`
                margin-left: auto;
                background-color: ${themeGet("colors.primary")};
                border-top-right-radius: 0;
                color: #fff;
                &:before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    z-index: 1;
                    border-color: ${themeGet("colors.primary")} transparent
                        transparent transparent;
                    position: absolute;
                    top: -1px;
                    border-width: 13px 16px 0 0;
                    right: -16px;
                }
                &:after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-color: ${themeGet("colors.border")} transparent
                        transparent transparent;
                    position: absolute;
                    top: -1px;
                    border-width: 15px 18px 0 0;
                    right: -18px;
                }
            `;
        }
        return css`
            border-top-left-radius: 0;
            &:before {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                z-index: 1;
                border-width: 0 13px 8px 0;
                border-color: transparent #fff transparent transparent;
                position: absolute;
                left: -13px;
                top: 0px;
            }
            &:after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 15px 10px 0;
                border-color: transparent ${themeGet("colors.border")}
                    transparent transparent;
                position: absolute;
                left: -15px;
                top: -1px;
            }
        `;
    }}
`;

export const StyledCallWrap = styled.div`
    display: flex;
`;

export const StyledCallIcon = styled.div<{ $isDarkTheme?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${themeGet("colors.background")};
    min-width: 35px;
    height: 35px;
    ${({ $isDarkTheme }) =>
        $isDarkTheme &&
        css`
            background: #255470;
        `}
`;
export const StyledContentWrap = styled.div`
    width: 100%;
`;
export const StyledDirection = styled.h6<{ $isDarkTheme?: boolean }>`
    color: ${themeGet("colors.text2")};
    margin-left: 10px;
    margin-bottom: 0;
    ${({ $isDarkTheme }) =>
        $isDarkTheme &&
        css`
            color: #fff;
        `}
`;

export const StyledStatus = styled.div`
    color: ${themeGet("colors.text3")};
    margin-left: 10px;
    font-size: 13px;
    margin-top: 2px;
    display: flex;
`;
export const StyledDuration = styled.div`
    margin-left: auto;
`;

export const StyledButtonWrap = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 240px;
`;
export const StyledButton = styled.div<{ $isDarkTheme?: boolean }>`
    background: ${themeGet("colors.background")};
    border-radius: 5px;
    width: 115px;
    padding: 7px 10px;
    display: inline-flex;
    align-items: center;
    color: ${themeGet("colors.text2")};
    justify-content: center;
    cursor: pointer;
    &:hover {
        filter: brightness(1.1);
    }
    ${({ $isDarkTheme }) =>
        $isDarkTheme &&
        css`
            background: #255470;
            color: #fff;
        `}
`;

export const StyledRecordingWrap = styled.div<{ $isDarkTheme?: boolean }>`
    margin-top: 10px;
    padding: 9px;

    border-radius: 5px;
    background: ${themeGet("colors.background")};
    ${({ $isDarkTheme }) =>
        $isDarkTheme &&
        css`
            background: #255470;
            color: #fff;
        `}
    audio {
        height: 35px;
    }
`;
