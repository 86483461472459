import styled, { css, themeGet } from "@doar/shared/styled";

export const StyledButton = styled.div`
    height: 20px;
    position: relative;
`;

export const StyledWrapper = styled.div`
    position: relative;
    width: 248px;
`;
export const StyledWaveWrapper = styled.div`
    flex-grow: 1;
`;

export const StyledContentWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 9px;
`;

export const StyledWave = styled.div``;

export const StyledInput = styled.input<{ $isDarkTheme?: boolean }>`
    position: absolute;
    right: -6px;
    bottom: 101%;
    accent-color: ${themeGet("colors.text2")};
    -webkit-appearance: slider-vertical;
    writing-mode: bt-lr;
    ${({ $isDarkTheme }) =>
        $isDarkTheme &&
        css`
            accent-color: #fff;
        `}
`;

export const StyledLoading = styled.div`
    flex-grow: 1;
    position: absolute;
    width: 190px;
`;
