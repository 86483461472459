import history from "src/utils/history";
import { toastError } from "src/utils/toast";
import { all, call, takeLatest, put, select } from "typed-redux-saga";
import CONFIG from "src/config";
import { Project } from "../../types/api/authentication";
import { RootState } from "../store";
import {
    updateProjectApi,
    createLocationApi,
    updateLocationsApi,
    createProjectApi,
} from "../../api/business/buiness";

import {
    doUpdateProject,
    doUpdateProjectFail,
    doCreateLocation,
    doCreateLocationFail,
    doConnectGmailGoogle,
    doConnectGmailGoogleFail,
    doUpdateLocation,
    doUpdateProjectSuccess,
    doUpdateLocationFail,
    doCreateProject,
    doCreateProjectSuccess,
    doCreateProjectFail,
} from "../slices/business";
import { doFetchAuthProfile } from "../slices/authentication";

function* doUpdateProjectSaga(action: ReturnType<typeof doUpdateProject>) {
    try {
        const updateProject = yield* call(
            updateProjectApi,
            Number(action.payload.project_id),
            action.payload
        );

        yield* put(doUpdateProjectSuccess(updateProject));

        history.push("/business/location");
    } catch (error) {
        const Error = JSON.parse(JSON.stringify(error));
        yield* put(doUpdateProjectFail(Error.data));
    }
}

function* doCreateProjectSaga(action: ReturnType<typeof doCreateProject>) {
    try {
        const createProject = yield* call(createProjectApi, action.payload);

        yield* put(doCreateProjectSuccess(createProject));

        history.push("/business/location");
    } catch (error) {
        const Error = JSON.parse(JSON.stringify(error));
        yield* put(doCreateProjectFail(Error.data));
    }
}

function* doCreateLocationSaga(action: ReturnType<typeof doCreateLocation>) {
    try {
        const { createProject, updateProject } = yield* select(
            (store: RootState) => store.bussiness
        );

        const project: Project = {
            id: createProject?.id || updateProject?.id || 0,
            name: createProject?.name || updateProject?.name || "",
            created_at: null,
            updated_at: null,
            email: "",
            country_code: "",
            phone: createProject?.phone || updateProject?.phone || "",
            photo: "",
            website: createProject?.website || updateProject?.website || "",
            cc: null,
            forwarding_sms_number: null,
            forwarding_number: null,
            onboarding_complete: null,
            procedures: [],
            locations: [],
            invites: [],
            sms_balance: 0,
            owner: {
                id: 0,
            },
            auto_recharge: false,
            auto_recharge_balance_to_in_cents: 0,
            auto_recharge_when_balance_falls_below_in_cents: 0,
        };
        if (project) {
            yield* call(updateProjectApi, project.id, {
                name: project.name,
                phone: project.phone,
                onboarding_complete: true,
            });
        }
        yield* put(doFetchAuthProfile());
        yield* call(createLocationApi, action.payload);
        history.push("/tickets");
    } catch (error) {
        const Error = JSON.parse(JSON.stringify(error));
        yield* put(doCreateLocationFail(Error.data));
    }
}

function* doUpdateLocationSaga(action: ReturnType<typeof doUpdateLocation>) {
    try {
        const { project } = yield* select((store: RootState) => store.project);
        if (project) {
            yield* call(updateProjectApi, project.id, {
                name: project.name,
                phone: project.phone,
                onboarding_complete: true,
            });
        }
        yield* call(
            updateLocationsApi,
            Number(action.payload.id),
            action.payload
        );
        history.push("/tickets");
    } catch (error) {
        const Error = JSON.parse(JSON.stringify(error));
        yield* put(doUpdateLocationFail(Error.data));
    }
}

function* doConnectGmailGoogleSaga(
    action: ReturnType<typeof doConnectGmailGoogle>
) {
    try {
        window.location.assign(
            `${CONFIG.BASE_URL}/auth/gmail/redirect?redirect_url=${CONFIG.APP_URl}/tickets`
        );
    } catch (error) {
        yield* put(doConnectGmailGoogleFail());
        toastError(error);
    }
}

export function* businessSaga(): Generator {
    yield all([
        takeLatest(doUpdateProject, doUpdateProjectSaga),
        takeLatest(doCreateProject, doCreateProjectSaga),
        takeLatest(doCreateLocation, doCreateLocationSaga),
        takeLatest(doUpdateLocation, doUpdateLocationSaga),
        takeLatest(doConnectGmailGoogle, doConnectGmailGoogleSaga),
    ]);
}
