import { all, fork } from "typed-redux-saga";
import { creditCardSaga } from "./billing/creditCard";
import { invoiceSaga } from "./billing/invoice";
import { smsSaga } from "./billing/sms";
import { subscriptionSaga } from "./billing/subscription";
import { locationSaga } from "./practice/location";
import { notificationSettingSaga } from "./notification/notification";
import { activeStaffsSaga } from "./manage-users/active-staffs";
import { pendingPermissionSaga } from "./manage-users/pending-permission";
import { procedureSaga } from "./services/procedure";
import { procedureStaffSaga } from "./services/procedureStaff";
import { categorySaga } from "./services/category";
import { integrationGoogleAdsSaga } from "./channels/index";
import { widgetSaga } from "./channels/widget";

function* saga(): Generator {
    yield all([
        fork(locationSaga),
        fork(invoiceSaga),
        fork(notificationSettingSaga),
        fork(creditCardSaga),
        fork(smsSaga),
        fork(subscriptionSaga),
        fork(activeStaffsSaga),
        fork(pendingPermissionSaga),
        fork(procedureSaga),
        fork(procedureStaffSaga),
        fork(categorySaga),
        fork(integrationGoogleAdsSaga),
        fork(widgetSaga),
    ]);
}
export default saga;
