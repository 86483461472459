import { FC, useState } from "react";
import { X } from "react-feather";
import ReactTooltip from "react-tooltip";
import { formatBytes, renderFileType } from "src/helpers/fileHelpers";
import ModalViewSelectFile from "../apps/file-manager/modal-select-view";
import {
    StyledFileAttach,
    StyledFileName,
    StyledFileSize,
    StyledFileThumb,
    StyledRemoveFile,
} from "./style";

interface IProps {
    index: number;
    file: File;
    onRemoveFile: (index: number) => void;
}

const FilePreview: FC<IProps> = ({ index, file, onRemoveFile }) => {
    const [showPreview, setShowPreview] = useState(false);
    return (
        <>
            <StyledFileAttach>
                <StyledFileThumb
                    color={renderFileType(file.name).color}
                    onClick={() => setShowPreview(true)}
                >
                    <i
                        className={`far fa-file-${
                            renderFileType(file.name).type
                        }`}
                    />
                </StyledFileThumb>
                <div>
                    <StyledFileName
                        data-tip={file.name}
                        data-for={`note-preview-file-${index}`}
                    >
                        {file.name}
                        <ReactTooltip
                            place="bottom"
                            effect="solid"
                            id={`note-preview-file-${index}`}
                        />
                    </StyledFileName>
                    <StyledFileSize>({formatBytes(file.size)})</StyledFileSize>
                    <StyledRemoveFile
                        className="remove-btn"
                        onClick={() => onRemoveFile(index)}
                    >
                        <X size={15} color="#fff" />
                    </StyledRemoveFile>
                </div>
            </StyledFileAttach>
            <ModalViewSelectFile
                show={showPreview}
                onClose={() => setShowPreview(false)}
                src={URL.createObjectURL(file)}
                type={file.type}
                name={file.name}
            />
        </>
    );
};

export default FilePreview;
