import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import { ArrowLeft, ArrowRight, DownloadCloud, X } from "react-feather";
import moment from "moment";
import CONFIG from "src/config";
import { File, Lead } from "src/types/api/ticket";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { generateLeadImageColor } from "src/helpers/generateLeadImageColor";
import usePressEscape from "src/hooks/usePressEscape";
import {
    StyledWrap,
    StyledModalBody,
    StyledModal,
    StyledModalHeader,
    StyledModalFooter,
    StyledModalClosed,
    StyledCard,
    StyledThumb,
    StyledBoxWrap,
    StyledButton,
    StyleButtonWrap,
    StyledImg,
    StyledWrapIcon,
    StyledWrapFooter,
} from "./style";
import Sender from "../../ticket/sender";

interface IProps {
    show: boolean;
    onClose: () => void;
    src: string;
    type: string;
    lead: Lead | null;
    file: File | null;
    handleDownload: () => void;
    files: File[];
    isMessageable?: boolean;
}

const ModalViewFile: FC<IProps> = ({
    show,
    handleDownload,
    onClose,
    src,
    type,
    lead,
    file,
    files,
    isMessageable,
}) => {
    usePressEscape(onClose);

    const [fileType, setFileType] = useState("");
    const [fileSrc, setFileSrc] = useState("");
    const [fileName, setFileName] = useState("");
    const [fileCreatedAt, setFileCreatedAt] = useState<Date>();

    const convertFilePath = (f: File) =>
        isMessageable
            ? f.url
            : `${CONFIG.GCS_IMAGE_URL}${f.path.split("\\").join("/")}`;

    useEffect(() => {
        if (type && src && file && show) {
            setFileType(type);
            setFileSrc(src);
            setFileName(file?.name);
            setFileCreatedAt(file?.created_at);
        }
    }, [type, src, file, show]);

    const renderFile = (vF: File) => {
        switch (vF.mime_type) {
            case "application/pdf": {
                return (
                    <StyledCard>
                        <StyledThumb color="danger">
                            <i className="far fa-file-pdf" />
                        </StyledThumb>
                    </StyledCard>
                );
                break;
            }
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            case "application/msword": {
                return (
                    <StyledCard>
                        <StyledThumb color="primary">
                            <i className="far fa-file-word" />
                        </StyledThumb>
                    </StyledCard>
                );
                break;
            }
            default: {
                return (
                    <StyledImg
                        src={convertFilePath(vF)}
                        alt="img"
                        className="listImage"
                    />
                );
                break;
            }
        }
    };

    const convertFileType = (mimeType: string) => {
        switch (mimeType) {
            case "application/pdf": {
                setFileType("far fa-file-pdf");
                break;
            }
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            case "application/msword": {
                setFileType("far fa-file-word");
                break;
            }
            default: {
                setFileType("far fa-file-image");
                break;
            }
        }
    };

    const handleSelect = (
        id: number,
        mineType: string,
        path: string,
        name: string,
        createAt: Date
    ) => {
        convertFileType(mineType);
        setFileSrc(path);
        setFileName(name);
        setFileCreatedAt(createAt);
    };

    const handleMoveLeft = () => {
        if (!files) {
            return;
        }

        for (let i = 0; i < files?.length; i += 1) {
            if (convertFilePath(files[i]) === fileSrc) {
                if (i + 1 === files?.length) {
                    handleSelect(
                        files[0].id,
                        files[0].mime_type,
                        convertFilePath(files[0]),
                        files[0].name,
                        files[0].created_at
                    );
                } else {
                    handleSelect(
                        files[i + 1].id,
                        files[i + 1].mime_type,
                        convertFilePath(files[i + 1]),
                        files[i + 1].name,
                        files[i + 1].created_at
                    );
                }
            }
        }
    };

    const handleMoveRight = () => {
        if (!files) {
            return;
        }

        for (let i = 0; i < files?.length; i += 1) {
            if (convertFilePath(files[i]) === fileSrc) {
                if (i - 1 < 0) {
                    handleSelect(
                        files[files?.length - 1].id,
                        files[files?.length - 1].mime_type,
                        convertFilePath(files[files?.length - 1]),
                        files[files?.length - 1].name,
                        files[files?.length - 1].created_at
                    );
                } else {
                    handleSelect(
                        files[i - 1].id,
                        files[i - 1].mime_type,
                        convertFilePath(files[i - 1]),
                        files[i - 1].name,
                        files[i - 1].created_at
                    );
                }
            }
        }
    };

    return (
        <StyledModal show={show} onClose={onClose}>
            <StyledModalHeader>
                <Sender
                    color={generateLeadImageColor(lead?.id || 0)}
                    name={[lead?.first_name, lead?.last_name].join(" ")}
                    time={moment(fileCreatedAt).format("ll")}
                    fileName={fileName}
                    className="modalView"
                />
                <StyledWrapIcon>
                    <DownloadCloud
                        cursor="pointer"
                        size={25}
                        onClick={handleDownload}
                    />
                    <StyledModalClosed onClose={onClose}>
                        <X size={25} />
                    </StyledModalClosed>
                </StyledWrapIcon>
            </StyledModalHeader>
            <StyledModalBody>
                <StyleButtonWrap>
                    <StyledButton onClick={handleMoveRight}>
                        <ArrowLeft />
                    </StyledButton>
                </StyleButtonWrap>
                <StyledWrap
                    className={classNames({
                        imageFile: fileType !== "far fa-file-image",
                    })}
                >
                    {fileType === "far fa-file-image" ? (
                        <StyledImg
                            src={fileSrc}
                            alt="img"
                            className="showImage"
                        />
                    ) : (
                        <>
                            {fileType === "far fa-file-word" ? (
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileSrc}`}
                                    title="file"
                                />
                            ) : (
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src={fileSrc}
                                    title="file"
                                />
                            )}
                        </>
                    )}
                </StyledWrap>
                <StyleButtonWrap>
                    <StyledButton onClick={handleMoveLeft}>
                        <ArrowRight />
                    </StyledButton>
                </StyleButtonWrap>
            </StyledModalBody>
            {files && (
                <StyledModalFooter>
                    <StyledWrapFooter>
                        <PerfectScrollbar>
                            <div style={{ display: "flex" }}>
                                {files?.map((f) => {
                                    return (
                                        <StyledBoxWrap
                                            key={f.id}
                                            onClick={() =>
                                                handleSelect(
                                                    f.id,
                                                    f.mime_type,
                                                    convertFilePath(f),
                                                    f.name,
                                                    f.created_at
                                                )
                                            }
                                            className={classNames({
                                                "is-selected":
                                                    fileSrc ===
                                                    convertFilePath(f),
                                            })}
                                        >
                                            {renderFile(f)}
                                        </StyledBoxWrap>
                                    );
                                })}
                            </div>
                        </PerfectScrollbar>
                    </StyledWrapFooter>
                </StyledModalFooter>
            )}
        </StyledModal>
    );
};

export default ModalViewFile;
