import styled, { themeGet, css } from "@doar/shared/styled";
import { Button } from "@doar/components";

export const StyledWrap = styled.div<{ $disabled?: boolean }>`
    margin-bottom: 10px;
    ${({ $disabled }) =>
        $disabled &&
        css`
            pointer-events: none;
        `}
`;

export const StyledMenuItem = styled(({ ...rest }) => <Button {...rest} />)`
    display: inline-block;
    align-items: center;
    background: #fff;
    width: 100%;
    padding: 0.5rem 1rem;
    min-width: calc(200px - 2rem);
    width: 100%;
    text-align: center;
    justify-content: space-between;
    color: ${themeGet("colors.text")};
    &:hover {
        color: ${themeGet("colors.heading")};
        background-color: ${themeGet("colors.light")};
    }
    &.disabled {
        pointer-events: none;
        background-color: ${themeGet("colors.light")};
    }
    div {
        height: 20px;
    }
`;

export const StyledDropdown = styled.div<{ $show?: boolean }>`
    position: absolute;
    width: 295px;
    top: 100%;
    background-color: #fff;
    transition: height 400ms ease;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    box-shadow: 0 0 8px 2px rgb(28 39 60 / 4%);
    z-index: 1000;
    display: none;
    left: -105px;
    ${({ $show }) =>
        $show &&
        css`
            display: block;
        `}
`;

export const StyledMenu = styled.div`
    &.view-phone {
        & .leadPhone {
            margin: 0 12px;
            &:hover {
                margin-right: 25px;

                .phone {
                    margin-right: 14px;
                }
                & .copyBtn {
                    right: 22px;
                    bottom: 0px;
                }
            }
        }
    }
    position: absolute;
    left: 30px;
    background-color: #fff;
    border: 1px solid rgba(131, 146, 165, 0.27);
    border-radius: 0.25rem;
    box-shadow: 0 0 8px 2px rgb(28 39 60 / 4%);
    z-index: 1000;
    .dropdownInSide {
        z-index: 1000;
    }
    .dropdownOutSide {
        z-index: 1000;
    }
    span {
        font-weight: 500;
        padding: 15px 10px;
    }
    & .leadPhone {
        display: inline-block;
        margin-left: 12px;
        padding: 10px;
        .phone {
            overflow: hidden;
            line-height: 1;
            text-overflow: ellipsis;
        }
        & .copyBtn {
            right: 30px;
            bottom: 5px;
            display: none;
        }
        &:hover {
            & .copyBtn {
                display: block;
            }
        }
    }
`;

export const StyledHeader = styled.div`
    text-align: center;
    padding: 20px;
    position: relative;
    overflow: auto;
    & .backArrow {
        position: absolute;
        left: 0;
        top: 0;
        cursor: pointer;
        z-index: 10;
        &:hover {
            opacity: 0.7;
        }
    }
    & .leadInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        & .copyBtn {
            display: none;
        }
        & .leadName {
            display: flex;
            font-weight: 600;
            font-size: 1rem;
            margin-bottom: 5px;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        & .leadEmail {
            position: relative;
            .email {
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &:hover {
            & .copyBtn {
                display: block;
            }
        }
    }
`;

export const StyledCopyBtn = styled.div`
    position: absolute;
    right: -25px;
    bottom: -3px;
    cursor: pointer;
    z-index: 10;
`;

export const StyledMediaWrap = styled.div`
    display: flex;
    justify-content: center;
    & .open {
        width: 40px;
        height: 60px;
        margin: 0 5px !important;
    }
`;

export const StyledMedia = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0 5px;
    & .textMedia {
        font-size: 12px;
        margin-top: 2px;
    }
`;

export const StyledButtonMedia = styled.div<{ $color?: string }>`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    ${({ $color }) =>
        $color &&
        css`
            background: ${$color};
        `}
    & .icon {
        height: 20px;
    }
    &:hover {
        filter: brightness(95%);
    }
`;

export const StyledLoading = styled.div`
    text-align: center;
    width: 100%;
`;
