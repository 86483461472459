import { Dropdown, DropdownToggle, DropdownMenu } from "@doar/components";
import { Message } from "src/types/api/ticket";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { stringify, parse } from "query-string";
import {
    StyledCard,
    StyledQuestionText,
    StyledCardBody,
    StyledDropdownItem,
} from "./style";

interface Props {
    message: Message;
}

const Question: FC<Props> = ({ message }) => {
    const history = useHistory();
    const [show, setShow] = useState(false);

    const onReply = (type: "sms" | "email") => {
        const payload = {
            ...parse(history.location.search),
            channel: message.channel_id,
            message_id: message.id,
            ...(type === "email" && { compose: "email" }),
            ...(type === "sms" && { compose: "sms" }),
        };

        history.push({
            search: stringify(payload),
        });
    };

    return (
        <StyledCard>
            <StyledCardBody>
                <StyledQuestionText>
                    {message.messageable.question}
                </StyledQuestionText>

                <Dropdown
                    direction="down"
                    isShow={show}
                    getState={(state) => setShow(state)}
                >
                    <DropdownToggle color="dark" size="sm">
                        Answer
                    </DropdownToggle>
                    <DropdownMenu show={show}>
                        <StyledDropdownItem
                            path="#"
                            onClick={() => {
                                onReply("email");
                                setShow(false);
                            }}
                        >
                            Answer by email
                        </StyledDropdownItem>
                        <StyledDropdownItem
                            path="#"
                            onClick={() => {
                                onReply("sms");
                                setShow(false);
                            }}
                        >
                            Answer by text
                        </StyledDropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </StyledCardBody>
        </StyledCard>
    );
};

export default Question;
