import { FC, useEffect, useMemo, useRef, useState } from "react";
import NoteItem from "src/components/apps/ticket/note/profile-view/note-item";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { Lead } from "src/types/api/ticket";
import Dropzone from "react-dropzone";
import { useQuery } from "react-query";
import { getLeadNotesApi } from "src/api/lead/note";
import { doSetLeadNote } from "src/redux/slices/ticket/note";
import LeadHeader from "src/components/apps/leads/lead-header";
import ScrollBar from "src/components/scrollbar";
import UpdateNote from "../../../../components/apps/ticket/note/profile-view/update-note";
import {
    StyledNote,
    StyledFormTitle,
    StyledNoteWrapper,
    StyledHr,
    StyledBackgroundDraggable,
} from "./style";
import EmptyNote from "./empy-note";
import Loading from "./loading";

interface IProps {
    isShowProfileInfo?: boolean;
    lead: Lead | null;
}
const Note: FC<IProps> = ({ isShowProfileInfo, lead }) => {
    const dispatch = useAppDispatch();
    const { notes, status } = useAppSelector((store) => ({
        notes: store.ticket.note.data,
        status: store.ticket.note.status,
    }));
    const { lead: leadData } = useAppSelector((store) => store.contact.lead);
    const { data, isLoading } = useQuery(
        ["fetchNotes", { notable_id: leadData?.id || lead?.id }],
        () => leadData && getLeadNotesApi(lead?.id || leadData?.id)
    );
    const [isDragOver, setDragOver] = useState(false);
    const [files, setFiles] = useState<File[] | null>(null);
    const noteRef = useRef<HTMLDivElement>(null);
    const [scrollHeight, setScrollHeight] = useState(0);

    useEffect(() => {
        if (noteRef?.current?.offsetHeight) {
            setScrollHeight(noteRef.current.offsetHeight);
        }
    }, [noteRef?.current?.offsetHeight, status, notes]);

    useEffect(() => {
        dispatch(
            doSetLeadNote({
                data: data || null,
                status: isLoading ? "loading" : "finished",
            })
        );
    }, [data, dispatch, isLoading]);

    const hasData = useMemo(() => {
        return (
            status === "finished" &&
            notes &&
            notes?.data &&
            notes?.data?.length > 0
        );
    }, [notes, status]);

    const onDropFile = (selectFiles: File[]) => {
        setDragOver(false);

        let filesArray: File[] = [];
        if (files) {
            filesArray = [...files];
        }
        setFiles([...filesArray, ...Array.from(selectFiles)] || null);
    };

    return (
        <Dropzone
            onDrop={(acceptedFiles) => onDropFile(acceptedFiles)}
            onDragOver={() => setDragOver(true)}
            onDragLeave={() => setDragOver(false)}
            noClick
        >
            {({ getRootProps }) => (
                <StyledNoteWrapper {...getRootProps()}>
                    {isDragOver && (
                        <StyledBackgroundDraggable>
                            <p>Drop file here</p>
                        </StyledBackgroundDraggable>
                    )}
                    {isShowProfileInfo ? (
                        <div>
                            <LeadHeader lead={lead} isShowBack={false} />
                            <StyledHr />
                            <StyledFormTitle>Notes</StyledFormTitle>
                        </div>
                    ) : (
                        ""
                    )}
                    <StyledNote>
                        <ScrollBar childrenHeight={scrollHeight}>
                            {status === "loading" && <Loading />}
                            {status === "finished" && (
                                <div
                                    className="note-wrap"
                                    style={{ height: "100%" }}
                                    ref={noteRef}
                                >
                                    {hasData ? (
                                        <>
                                            {notes?.data?.map((note) => (
                                                <NoteItem
                                                    key={note.id}
                                                    note={note}
                                                />
                                            ))}
                                        </>
                                    ) : (
                                        <EmptyNote />
                                    )}
                                </div>
                            )}
                        </ScrollBar>
                        <UpdateNote
                            lead={lead || null}
                            files={files}
                            onChangeFiles={(f) => setFiles(f)}
                        />
                    </StyledNote>
                </StyledNoteWrapper>
            )}
        </Dropzone>
    );
};

export default Note;
