import { Button } from "@doar/components";
import { FC, useState } from "react";
import ReactTooltip from "react-tooltip";
import CONFIG from "src/config";
import {
    getFileType,
    isImageFile,
    renderFileType,
} from "src/helpers/fileHelpers";
import { File } from "src/types/api/ticket";
import ModalViewFile from "../apps/file-manager/modal-view";
import {
    StyledFileName,
    StyledFileThumbIcon,
    StyledFileThumbImg,
    StyledFileWrap,
} from "./style";

interface IProps {
    file: File;
    files: File[];
    isDarkTheme?: boolean;
    isMessageable?: boolean;
}
const FileView: FC<IProps> = ({ file, isDarkTheme, isMessageable, files }) => {
    const [isView, setView] = useState(false);

    const getSourceFile = () =>
        isMessageable
            ? file.url
            : `${CONFIG.GCS_IMAGE_URL}${file.path?.split("\\").join("/")}`;

    const handleDownload = () => {
        window?.open(file.download_url, "_blank")?.focus();
    };

    return (
        <StyledFileWrap key={file.id}>
            {isImageFile(file.path) ? (
                <StyledFileThumbImg
                    src={
                        isMessageable
                            ? file.url
                            : `${CONFIG.GCS_IMAGE_URL}${file.path}`
                    }
                />
            ) : (
                <StyledFileThumbIcon color={renderFileType(file.path).color}>
                    <i
                        className={`far fa-file-${
                            renderFileType(file.path).type
                        }`}
                    />
                </StyledFileThumbIcon>
            )}
            <StyledFileName
                data-tip={`${file.original_name}.${getFileType(file.path)}`}
                data-for={`note-show-file-${file.id}`}
            >
                {file.original_name}.{getFileType(file.path)}
                <ReactTooltip
                    place="top"
                    effect="solid"
                    id={`note-show-file-${file.id}`}
                />
            </StyledFileName>
            <Button
                variant="texted"
                ml="auto"
                color={isDarkTheme ? "secondary" : "primary"}
                onClick={() => setView(true)}
            >
                Open file
            </Button>
            <ModalViewFile
                show={isView}
                onClose={() => setView(false)}
                handleDownload={handleDownload}
                src={getSourceFile()}
                type={`far fa-file-${renderFileType(file.path).type}`}
                lead={null}
                file={file}
                files={files}
                isMessageable
            />
        </StyledFileWrap>
    );
};

export default FileView;
