import { Input, InputGroup, Button, Label } from "@doar/components";
import styled, { css, device, themeGet } from "@doar/shared/styled";

export const StyledWrap = styled.div<{ hasError: boolean; hasThread: boolean }>`
    background-color: #fff;
    padding-top: 10px;
    margin-top: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 100%;
    overflow: hidden;
    position: relative;
    &:focus-visible {
        outline: none;
    }

    .quill {
        ${({ hasError }) =>
            hasError &&
            css`
                border-bottom: 1px solid ${themeGet("colors.danger")};
            `};
    }
    .ql {
        &-container {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
            border-color: transparent !important;
            /* border-color: ${themeGet("colors.border")} !important; */
            height: 100%;
            font-size: 13px;
            ${device.large} {
                font-size: 14px;
            }
        }
        &-editor {
            padding: 0;
            height: 100%;
            overflow: inherit;
            color: ${themeGet("colors.text")};
            ${({ hasThread }) =>
                !hasThread &&
                css`
                    min-height: 200px;
                `};
        }
        &-blank::before {
            left: 0 !important;
        }
    }
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            background-color: ${themeGet("colors.darkdarken5")};
            .ql-editor {
                color: ${themeGet("colors.gray500")};
            }
            .ql-snow.ql-toolbar button {
                .ql-stroke {
                    stroke: ${themeGet("colors.gray700")};
                }
                &:hover,
                &:focus {
                    background-color: ${themeGet("colors.gray900")};
                    .ql-stroke {
                        stroke: #fff;
                    }
                }
            }
        `}
`;

export const StyledToolbarWrap = styled.div`
    margin-top: auto;
    align-items: center;
    justify-content: start;
    display: flex;
    margin-bottom: 2px;
    .ql-picker.ql-expanded .ql-picker-options {
        bottom: 100%;
        top: auto;
    }
    .send-email-btn {
        min-width: 102px;
        div {
            height: 18px;
        }
    }
`;

export const StyledToolbar = styled.div`
    padding: 0px !important;
    border: 0 !important;
    .ql {
        &-toolbar {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
        }
        &-formats {
            display: inline-flex;
        }
    }
`;

export const StyledForm = styled.form`
    min-height: 500px;
    display: flex;
    flex-direction: column;
    max-height: 100%;
`;

export const StyledInput = styled(({ ...rest }) => <Input {...rest} />)`
    border-bottom: 1px solid ${themeGet("colors.gray200")};

    ${({ state }) =>
        state === "error" &&
        css`
            /* box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%); */
            border-bottom: 1px solid ${themeGet("colors.danger")};
        `};
`;

export const StyledThread = styled.div`
    margin-top: 10px;
`;

export const StyledBlockQuote = styled.blockquote`
    padding-left: 10px;
    max-height: 100%;
    overflow: auto;
    min-height: 200px;
    border-left: 2px solid ${themeGet("colors.border")};
`;

export const StyledDropFileOverlay = styled.div`
    position: absolute;
    left: 0;
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    background: #fff;
    opacity: 0.7;
    font-size: 2rem;
    color: ${themeGet("colors.gray600")};
    font-weight: 500;
    animation: 0.3s boundOut ease-out;
    @keyframes boundOut {
        0% {
            font-size: 1.5rem;
        }
        100% {
            font-size: 2rem;
        }
    }
`;

export const StyledToLabel = styled.div`
    position: absolute;
    color: gray;
    z-index: 10;
    top: 10px;
    left: 3px;
`;

export const StyledInputGroup = styled(({ ...rest }) => (
    <InputGroup {...rest} />
))`
    position: relative;
`;

export const StyledChannelWrap = styled.div`
    position: relative;
    display: flex;
    min-width: 230px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledGmailIcon = styled.img`
    position: absolute;
    z-index: 10;
    width: 20px;
    bottom: 12px;
    left: 50px;
`;

export const StyledTextButtonWrap = styled.div<{ $hidden?: boolean }>`
    margin-top: 10px;
    border-radius: 4px;
    border-bottom: 1px solid ${themeGet("colors.border")};
    &:hover {
        border-bottom: 1px solid hsl(0, 0%, 70%);
    }
    ${({ $hidden }) =>
        $hidden &&
        css`
            display: none;
        `}
`;

export const StyledTextButton = styled(({ ...rest }) => <Button {...rest} />)`
    height: 41px;
    color: grey;
`;

export const StyledAttachFileBtn = styled(({ ...rest }) => <Label {...rest} />)`
    margin-top: 5px;
    margin-left: 15px;
    margin-right: 9px;
    cursor: pointer;
    color: #444;
    &:hover {
        color: #06c;
    }
`;

export const StyledFileAttach = styled.div`
    display: flex;
    align-items: center;
    background: ${themeGet("colors.gray200")};
    padding: 5px 10px;
    max-width: 400px;
    height: 28px;
    margin-top: 5px;
`;

export const StyledFileName = styled.div`
    font-weight: 500;
    color: ${themeGet("colors.primary")};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 315px;
`;

export const StyledFileSize = styled.div`
    font-weight: 500;
    color: ${themeGet("colors.gray600")};
    margin-left: 3px;
    margin-right: 5px;
`;

export const StyledRemoveFile = styled.div`
    margin-left: auto;
    height: 15px;
    cursor: pointer;
`;

export const StyledTextWrap = styled.div`
    max-height: 400px;
    overflow: auto;
    height: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;

export const StyledFileAttachWrap = styled.div`
    margin-top: auto;
`;

export const StyledWarning = styled.div`
    padding-bottom: 10px;
    color: ${themeGet("colors.primary")};
`;
