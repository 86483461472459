export const LEAD_FIELDS = {
    firstName: "firstName",
    lastName: "lastName",
    email: "email",
    phone: "phone",
    dateOfBirth: "dateOfBirth",
    emailOptOut: "emailOptOut",
    smsOptOut: "smsOptOut",
    source: "source",
    address1: "address1",
    address2: "address2",
    gender: "gender",
    provider: "provider",
    medicalCondition: "medicalCondition",
    smoker: "smoker",
    weight: "weight",
    bmi: "bmi",
    heightInFeet: "heightInFeet",
    heightInInch: "heightInInch",
};

export const GENDER = {
    male: "Male",
    female: "Female",
};

export const MEDICAL_CONDITION = {
    diabetes: "Diabetes",
    bloodClots: "Blood clots",
    cancer: "Cancer",
    heartDisease: "Heart disease",
    organTransplant: "Organ transplant",
    other: "Other",
    none: "None",
};

export const STAGE = {
    new: "New",
    followup: "Followup",
    consultBooked: "Consult Booked",
    consultComplete: "Consult Complete",
    quoteSent: "Quote Sent",
    depositPaid: "Deposit Paid",
    surgeryBooked: "Surgery Booked",
    surgeryComplete: "Surgery Complete",
    lost: "Lost",
    notQualified: "Not Qualified",
};

export const FILTER_BY_FIELDS: {
    [key: string]:
    | "created_from"
    | "created_to"
    | "in[source]"
    | "in[procedure_id]"
    | "is_qualified"
    | "in[stage]"
    | "stage"
    | "in[staff_id]"
    | "in[location_id]"
    | "keyword";
} = {
    createdFrom: "created_from",
    createdTo: "created_to",
    source: "in[source]",
    procedure: "in[procedure_id]",
    isQualified: "is_qualified",
    multipleStage: "in[stage]",
    singleStage: "stage",
    staff: "in[staff_id]",
    location: "in[location_id]",
    keyword: "keyword",
};
