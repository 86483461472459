import { CardBody } from "@doar/components";
import { FC } from "react";
import { Message, MessageStatus, MessageType } from "src/types/api/ticket";
import { AlertCircle, Check, Clock } from "react-feather";
import { classic } from "@doar/shared/styled/colors";
import {
    StyledCard,
    StyledFailedMessage,
    StyledStatus,
    StyledStatusContainer,
    StyledStatusWrap,
} from "./style";

interface IProps {
    message: Message;
}

const SmsMessage: FC<IProps> = ({ message }) => {
    const formatStatus = (status: MessageStatus) => {
        if (!status) {
            return "";
        }
        if (status === "on_queue") {
            return "Pending";
        }
        return `${status[0].toUpperCase()}${status.substring(1)}`
            .split("_")
            .join(" ");
    };

    const renderStatus = () => {
        if (message.status === "failed") {
            return (
                <>
                    <AlertCircle
                        color={classic.danger}
                        size={18}
                        cursor="pointer"
                    />
                    <StyledFailedMessage>
                        {message.failed_message}
                    </StyledFailedMessage>
                </>
            );
        }
        if (message.status === "on_hold") {
            return (
                <>
                    <Clock
                        color={classic.gray600}
                        size={18}
                        style={{ marginRight: "5px" }}
                    />
                    Waiting for patient consent
                </>
            );
        }
        if (message.status === "on_queue") {
            return (
                <>
                    <Clock
                        size={16}
                        color={classic.gray600}
                        style={{ marginRight: "5px" }}
                    />
                    {formatStatus(message.status)}
                </>
            );
        }
        return (
            <>
                <Check
                    size={16}
                    color={classic.gray600}
                    style={{ marginRight: "5px" }}
                />
                {formatStatus(message.status)}
            </>
        );
    };
    return (
        <StyledCard isOutbound={message.type === MessageType.Outbound}>
            <CardBody>{message.message}</CardBody>
            {message.type === MessageType.Outbound && (
                <StyledStatusWrap>
                    <StyledStatus>
                        <StyledStatusContainer>
                            {renderStatus()}
                        </StyledStatusContainer>
                    </StyledStatus>
                </StyledStatusWrap>
            )}
        </StyledCard>
    );
};

export default SmsMessage;
