import { useCallback } from "react";
import { useQuery } from "react-query";
import { getTicketNoteMentionsApi } from "src/api/lead/mention";
import { useAppDispatch } from "src/redux/hooks";
import { setMentions } from "src/redux/slices/ticket/mention";

const useFetchMention = (): [() => void] => {
    const dispatch = useAppDispatch();
    const { data, isLoading } = useQuery("fetchMentions", () =>
        getTicketNoteMentionsApi({ page: 1 })
    );

    return [
        useCallback(() => {
            dispatch(
                setMentions({
                    data: data || null,
                    status: isLoading ? "loading" : "finished",
                })
            );
        }, [data, dispatch, isLoading]),
    ];
};

export default useFetchMention;
