export const items = [
    {
        id: 1,
        name: "12:00 AM",
    },
    {
        id: 2,
        name: "12:15 AM",
    },
    {
        id: 3,
        name: "12:30 AM",
    },
    {
        id: 4,
        name: "12:45 AM",
    },
    {
        id: 5,
        name: "01:00 AM",
    },
    {
        id: 6,
        name: "01:15 AM",
    },
    {
        id: 7,
        name: "01:30 AM",
    },
    {
        id: 8,
        name: "01:45 AM",
    },
    {
        id: 9,
        name: "02:00 AM",
    },
    {
        id: 10,
        name: "02:15 AM",
    },
    {
        id: 11,
        name: "02:30 AM",
    },
    {
        id: 12,
        name: "02:45 AM",
    },
    {
        id: 13,
        name: "03:00 AM",
    },
    {
        id: 14,
        name: "03:15 AM",
    },
    {
        id: 15,
        name: "03:30 AM",
    },
    {
        id: 16,
        name: "03:45 AM",
    },
    {
        id: 17,
        name: "04:00 AM",
    },
    {
        id: 18,
        name: "04:15 AM",
    },
    {
        id: 19,
        name: "04:30 AM",
    },
    {
        id: 20,
        name: "04:45 AM",
    },
    {
        id: 21,
        name: "05:00 AM",
    },
    {
        id: 22,
        name: "05:15 AM",
    },
    {
        id: 23,
        name: "05:30 AM",
    },
    {
        id: 24,
        name: "05:45 AM",
    },
    {
        id: 25,
        name: "06:00 AM",
    },
    {
        id: 26,
        name: "06:15 AM",
    },
    {
        id: 27,
        name: "06:30 AM",
    },
    {
        id: 28,
        name: "06:45 AM",
    },
    {
        id: 29,
        name: "07:00 AM",
    },
    {
        id: 30,
        name: "07:15 AM",
    },
    {
        id: 31,
        name: "07:30 AM",
    },
    {
        id: 32,
        name: "07:45 AM",
    },
    {
        id: 33,
        name: "08:00 AM",
    },
    {
        id: 34,
        name: "08:15 AM",
    },
    {
        id: 35,
        name: "08:30 AM",
    },
    {
        id: 36,
        name: "08:45 AM",
    },
    {
        id: 37,
        name: "09:00 AM",
    },
    {
        id: 38,
        name: "09:15 AM",
    },
    {
        id: 39,
        name: "09:30 AM",
    },
    {
        id: 40,
        name: "09:45 AM",
    },
    {
        id: 41,
        name: "10:00 AM",
    },
    {
        id: 42,
        name: "10:15 AM",
    },
    {
        id: 43,
        name: "10:30 AM",
    },
    {
        id: 44,
        name: "10:45 AM",
    },
    {
        id: 45,
        name: "11:00 AM",
    },
    {
        id: 46,
        name: "11:15 AM",
    },
    {
        id: 47,
        name: "11:30 AM",
    },
    {
        id: 48,
        name: "11:45 AM",
    },
    {
        id: 49,
        name: "12:00 PM",
    },
    {
        id: 50,
        name: "12:15 PM",
    },
    {
        id: 51,
        name: "12:30 PM",
    },
    {
        id: 52,
        name: "12:45 PM",
    },
    {
        id: 53,
        name: "01:00 PM",
    },
    {
        id: 54,
        name: "01:15 PM",
    },
    {
        id: 55,
        name: "01:30 PM",
    },
    {
        id: 56,
        name: "01:45 PM",
    },
    {
        id: 57,
        name: "02:00 PM",
    },
    {
        id: 58,
        name: "02:15 PM",
    },
    {
        id: 59,
        name: "02:30 PM",
    },
    {
        id: 60,
        name: "02:45 PM",
    },
    {
        id: 61,
        name: "03:00 PM",
    },
    {
        id: 62,
        name: "03:15 PM",
    },
    {
        id: 63,
        name: "03:30 PM",
    },
    {
        id: 64,
        name: "03:45 PM",
    },
    {
        id: 65,
        name: "04:00 PM",
    },
    {
        id: 66,
        name: "04:15 PM",
    },
    {
        id: 67,
        name: "04:30 PM",
    },
    {
        id: 68,
        name: "04:45 PM",
    },
    {
        id: 69,
        name: "05:00 PM",
    },
    {
        id: 70,
        name: "05:15 PM",
    },
    {
        id: 71,
        name: "05:30 PM",
    },
    {
        id: 72,
        name: "05:45 PM",
    },
    {
        id: 73,
        name: "06:00 PM",
    },
    {
        id: 74,
        name: "06:15 PM",
    },
    {
        id: 75,
        name: "06:30 PM",
    },
    {
        id: 76,
        name: "06:45 PM",
    },
    {
        id: 77,
        name: "07:00 PM",
    },
    {
        id: 78,
        name: "07:15 PM",
    },
    {
        id: 79,
        name: "07:30 PM",
    },
    {
        id: 80,
        name: "07:45 PM",
    },
    {
        id: 81,
        name: "08:00 PM",
    },
    {
        id: 82,
        name: "08:15 PM",
    },
    {
        id: 83,
        name: "08:30 PM",
    },
    {
        id: 84,
        name: "08:45 PM",
    },
    {
        id: 85,
        name: "09:00 PM",
    },
    {
        id: 86,
        name: "09:15 PM",
    },
    {
        id: 87,
        name: "09:30 PM",
    },
    {
        id: 88,
        name: "09:45 PM",
    },
    {
        id: 89,
        name: "10:00 PM",
    },
    {
        id: 90,
        name: "10:15 PM",
    },
    {
        id: 91,
        name: "10:30 PM",
    },
    {
        id: 92,
        name: "10:45 PM",
    },
    {
        id: 93,
        name: "11:00 PM",
    },
    {
        id: 94,
        name: "11:15 PM",
    },
    {
        id: 95,
        name: "11:30 PM",
    },
    {
        id: 96,
        name: "11:45 PM",
    },
];
