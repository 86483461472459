import { FC, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { ChevronDown, ChevronUp } from "react-feather";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { doSetSelectedTab } from "src/redux/slices/contacts/lead";
import {
    StyledDropdownItem,
    StyledDropdownMenu,
    StyledToggleActivity,
} from "./style";

const MessageTypeSelection: FC = () => {
    const dispatch = useAppDispatch();
    const { selectedTab } = useAppSelector((store) => store.contact.lead);
    const [showDropdownMessageType, setShowDropdownMessageType] =
        useState(false);

    const dropdownRef = useOnclickOutside(() =>
        setShowDropdownMessageType(false)
    );

    return (
        <StyledToggleActivity
            ref={dropdownRef}
            onClick={() => setShowDropdownMessageType(!showDropdownMessageType)}
        >
            {selectedTab === "conversations" ? "Conversation" : "Activity"}
            <div className="arrow">
                {showDropdownMessageType ? (
                    <ChevronUp size={15} />
                ) : (
                    <ChevronDown size={15} />
                )}
            </div>
            <StyledDropdownMenu $show={showDropdownMessageType}>
                <StyledDropdownItem
                    onClick={() => dispatch(doSetSelectedTab("activity"))}
                >
                    Activity
                </StyledDropdownItem>
                <StyledDropdownItem
                    onClick={() => dispatch(doSetSelectedTab("conversations"))}
                >
                    Conversation
                </StyledDropdownItem>
            </StyledDropdownMenu>
        </StyledToggleActivity>
    );
};

export default MessageTypeSelection;
