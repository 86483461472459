import { Modal as OriginModal } from "@doar/components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FC, useCallback, useEffect, KeyboardEvent } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useOnEscape } from "@doar/shared/hooks";
import { IModal } from "@doar/components/src/ui/modal/modal";

const Modal: FC<IModal> = ({
    className,
    show,
    size,
    centered,
    onClose,
    children,
    ...restProps
}) => {
    useOnEscape(() => {
        if (typeof onClose === "function" && show) {
            onClose();
        }
    });

    return (
        <OriginModal
            className={className}
            show={show}
            size={size}
            centered={centered}
            onClose={onClose}
            {...restProps}
        >
            {children}
        </OriginModal>
    );
};
export default Modal;
