import styled, { themeGet } from "@doar/shared/styled";
import { Button } from "@doar/components";
import { tinycolor } from "@doar/shared";

export const StyledIcon = styled.span``;

export const StyledDropdown = styled.div`
    height: 90px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    overflow: hidden;
    transition: height 400ms ease;
    min-width: 5rem;
    font-size: 0.875rem;
    border: 1px solid
        ${(props) =>
            tinycolor(props.theme.colors.text3).setAlpha(0.27).toString()};
    border-radius: 0.25rem;
    box-shadow: 0 0 8px 2px rgb(28 39 60 / 4%);
    z-index: 100;
`;

export const StyledMenu = styled.div`
    padding: 5px;
    z-index: 10;
`;

export const StyledNavItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.3rem;
    position: relative;
    p {
        display: inline;
        &:hover {
            color: ${themeGet("colors.info")};
            font-weight: 400;
        }
    }
`;

export const StyledNavbar = styled.nav`
    bottom: 25%;
    left: 2%;
    z-index: 1000;
    position: absolute;
    &.sms-confirm {
        position: absolute;
        bottom: 126px;
        left: -132px;
        ul {
            div {
                width: 200px;
            }
        }
    }
`;

export const StyledNavbarNav = styled.ul`
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
`;

export const StyledOption = styled.span`
    display: inline-block;
    margin-top: -5px;

    &.open {
        margin-right: 6px;
    }
`;

export const StyledMenuItem = styled(({ ...rest }) => <Button {...rest} />)`
    display: inline-flex;
    align-items: center;
    background: transparent;
    padding: 0.5rem 1rem;
    min-width: calc(200px - 2rem);
    justify-content: space-between;
    width: 200px;
    color: ${themeGet("colors.text")};
    &:hover {
        color: ${themeGet("colors.heading")};
        background-color: ${themeGet("colors.light")};
    }
`;

export const StyledIconButton = styled.div`
    border-radius: 50%;
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${themeGet("colors.text")};
`;
