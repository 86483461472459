import styled from "@doar/shared/styled";

export const StyledLoadingWrapper = styled.div`
    min-height: 100%;
    padding: 20px;
`;

export const StyledInfo = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledAvt = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
`;

export const StyledHalf = styled.div`
    width: 50%;
    display: flex;
`;
