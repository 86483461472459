import { AxiosResponse } from "axios";
import { CommonPagination } from "src/types/api/common";
import { GetNotificationSetting, INotificationSetting } from "src/types/api/notificationSettings";
import request from "src/utils/request";

export const getNotificationSettingsApi = (): Promise<CommonPagination<GetNotificationSetting>> =>
    request.get<CommonPagination<GetNotificationSetting>, CommonPagination<GetNotificationSetting>>(
        "/api/notification-settings"
    );

export const updateNotificationSettingApi = (notificationId: number, form: INotificationSetting | null): Promise<AxiosResponse> =>
    request.put(
        `/api/notification-settings/${notificationId}`, form
    );
