import { PhoneNumber } from "src/types/api/app";
import { CommonPagination } from "src/types/api/common";
import request from "src/utils/request";

type GetPhoneNumberRequestParams = {
    phone?: string;
};
export const getPhoneNumbersApi = (
    params?: GetPhoneNumberRequestParams
): Promise<CommonPagination<PhoneNumber>> => {
    return request.get<
        CommonPagination<PhoneNumber>,
        CommonPagination<PhoneNumber>
    >("/api/phoneNumbers", { params });
};

type GetAvailablePhoneParams = {
    country_code: string;
    area_code: string;
};

export interface AvailablePhone {
    friendly_name: string;
    phone_number: string;
    locality: string;
}

export const getAvailablePhone = (
    params: GetAvailablePhoneParams
): Promise<AvailablePhone[]> =>
    request.get("/api/phone-numbers/available", { params });
