import { Dropdown, DropdownMenu, DropdownToggle } from "@doar/components";
import { FC, useState } from "react";
import {
    ChevronDown,
    ChevronUp,
    Calendar as CalendarIcon,
} from "react-feather";
import moment from "moment";

import Calendar from "src/components/calendar";
import { StyledDropdownWrap } from "./style";

interface Props {
    onChange: (date: Date) => void;
}

const DateInModalTask: FC<Props> = ({ onChange }) => {
    const [show, setShow] = useState(false);
    const [value, setValue] = useState<Date>();
    return (
        <StyledDropdownWrap>
            <Dropdown
                direction="down"
                isShow={show}
                getState={(state) => setShow(state)}
            >
                <DropdownToggle variant="outlined" className="dropdownToggle">
                    <div className="value">
                        <span>
                            <CalendarIcon size={20} />
                        </span>
                        <p style={{ color: "#1b2e4b" }}>
                            {(value && moment(value).format("MMMM DD, YYYY")) ||
                                "Date"}
                        </p>
                    </div>
                    <div className="selectionArrow">
                        {show ? <ChevronUp /> : <ChevronDown />}
                    </div>
                </DropdownToggle>
                <DropdownMenu show className="dropdownMenu">
                    <Calendar
                        value={value}
                        onChange={(date) => {
                            if (!Array.isArray(date)) {
                                setValue(date);
                                setShow(false);
                                onChange(date);
                            }
                        }}
                        notChoosePreviousDate={new Date()}
                    />
                </DropdownMenu>
            </Dropdown>
        </StyledDropdownWrap>
    );
};

export default DateInModalTask;
