/* eslint-disable react/destructuring-assignment */
import { FC, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { CSSTransition } from "react-transition-group";
import AddNewPhoneContainer from "src/containers/apps/ticket/group/drop-down/add-new-phone-container";
import { Lead } from "src/types/api/ticket";
import { StyledMenu, StyledDropdown, StyledMenuItem } from "../style";

interface Props {
    lead: Lead | null;
    show: boolean;
    onClose: () => void;
    onAddPhoneSuccess?: (leadId?: number) => void;
    onAddPhoneFail?: () => void;
}

const HandleAddPhone: FC<Props> = ({
    lead,
    show,
    onClose,
    onAddPhoneSuccess,
    onAddPhoneFail,
}) => {
    const [activeMenu, setActiveMenu] = useState<"main" | "addPhone">("main");

    const onCloseDropdown = () => {
        onClose();
        setActiveMenu("main");
    };

    const ref = useOnclickOutside(() => onCloseDropdown());

    return (
        <StyledDropdown ref={ref} $show={show}>
            <CSSTransition in={activeMenu === "main"} timeout={0} unmountOnExit>
                <StyledMenu className="dropdownOutSide">
                    <StyledMenuItem
                        variant="texted"
                        onClick={() => setActiveMenu("addPhone")}
                    >
                        Add missing phone number
                    </StyledMenuItem>
                </StyledMenu>
            </CSSTransition>
            <CSSTransition
                in={activeMenu === "addPhone"}
                timeout={0}
                unmountOnExit
            >
                <StyledMenu className="dropdownInSide">
                    <AddNewPhoneContainer
                        lead={lead}
                        onClose={onCloseDropdown}
                        onAddPhoneSuccess={onAddPhoneSuccess}
                        onAddPhoneFail={onAddPhoneFail}
                    />
                </StyledMenu>
            </CSSTransition>
        </StyledDropdown>
    );
};

export default HandleAddPhone;
