import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import App from "./App";
import { store, persistor } from "./redux/store";
import ThemeProvider from "./theme-provider";

const queryClient = new QueryClient();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider>
                <QueryClientProvider client={queryClient}>
                    <App />
                </QueryClientProvider>
            </ThemeProvider>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);
