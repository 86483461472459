import { Button } from "@doar/components";
import moment from "moment";
import { FC } from "react";
import ReactCalendar from "react-calendar";
import { StyledWrap } from "./style";

interface IProps {
    className?: string;
    maxDate?: boolean;
    value?: Date | Date[] | null | undefined;
    onChange?: (date: Date | Date[]) => void;
    notChoosePreviousDate?: Date;
}

const Calendar: FC<IProps> = ({
    className,
    value,
    onChange,
    maxDate,
    notChoosePreviousDate,
}) => {
    const formatShortWeekday = (locale: string, date: Date): string => {
        const days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
        const d = new Date(date);
        const dayName = days[d.getDay()].substring(0, 2);
        return dayName;
    };
    const formatDay = (locale: string, date: Date): string => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, "0");
        return day;
    };
    const classes = className ? `${className} calendar` : "calendar";
    return (
        <StyledWrap className={classes}>
            {maxDate ? (
                <>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "auto auto",
                            gridColumnGap: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <p style={{ margin: 0 }}>Starting</p>
                        <p style={{ margin: 0 }}>Ending</p>
                        <input
                            id="createStartDate"
                            name="createStartDate"
                            value={
                                value
                                    ? moment(
                                          value.toString().split(",")[0]
                                      ).format("ll")
                                    : ""
                            }
                        />
                        <input
                            id="createEndDate"
                            name="createEndDate"
                            value={
                                value
                                    ? moment(
                                          value.toString().split(",")[1]
                                      ).format("ll")
                                    : ""
                            }
                        />
                    </div>
                    <ReactCalendar
                        maxDate={new Date()}
                        value={value}
                        onChange={onChange}
                        calendarType="US"
                        prevLabel={<i className="fa fa-chevron-left" />}
                        nextLabel={<i className="fa fa-chevron-right" />}
                        formatShortWeekday={formatShortWeekday}
                        formatDay={formatDay}
                        selectRange
                        showDoubleView
                    />
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <Button width={120} color="white">
                            Cancel
                        </Button>
                        <Button width={120} color="dark" marginLeft={10}>
                            Apply
                        </Button>
                    </div>
                </>
            ) : (
                <ReactCalendar
                    value={value}
                    onChange={onChange}
                    calendarType="US"
                    prevLabel={<i className="fa fa-chevron-left" />}
                    nextLabel={<i className="fa fa-chevron-right" />}
                    formatShortWeekday={formatShortWeekday}
                    formatDay={formatDay}
                    minDate={notChoosePreviousDate}
                />
            )}
        </StyledWrap>
    );
};

export default Calendar;
