import { all, call, put, takeLatest } from "typed-redux-saga";
import { getMessagesApi } from "../../../api/message/message";
/* eslint-disable no-empty */
import {
    start,
    success,
    loadMore,
    loadMoreSuccess,
} from "../../slices/ticket/message";

function* getListMessageSaga(action: ReturnType<typeof start>) {
    try {
        const response = yield* call(getMessagesApi, {
            page: 1,
            type: "outbound",
            has: "staff_id",
            with: "recipients",
        });
        yield* put(success(response));
    } catch (error) { }
}
function* loadMoreListMessage(action: ReturnType<typeof loadMore>) {
    try {
        const response = yield* call(getMessagesApi, {
            page: action.payload.page,
            type: "outbound",
            has: "staff_id",
            with: "recipients",
        });

        yield* put(loadMoreSuccess(response));
    } catch (error) { }
}

export function* messageSaga(): Generator {
    yield all([
        takeLatest(start, getListMessageSaga),
        takeLatest(loadMore, loadMoreListMessage),
    ]);
}
