import styled from "@doar/shared/styled";

export const StyledWrap = styled.div`
    width: 230px;
`;

export const StyledNameWrap = styled.div`
    display: flex;
    align-items: center;
    & .name {
        margin-left: 10px;
        text-align: left;
    }

    & .lname {
        color: #d1d9e4;
        margin-left: -5px;
        text-align: left;
    }
`;
