/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Heading } from "@doar/components";
import { groupBy } from "lodash-es";
import moment from "moment";
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual } from "react-redux";
import { loadMore } from "src/redux/slices/ticket/list";
import { toggleBody } from "src/redux/slices/ui";
import IllustrationCaugthupIcon from "src/assets/svg/illustration_caugthup.svg";
import { Mention } from "src/types/api/ticket";
import { doSetLead } from "src/redux/slices/contacts/lead";
import { setMentionDetails } from "src/redux/slices/ticket/mention";
import { getLeadApi } from "src/api/lead/lead";
import { useQuery } from "react-query";
import ScrollBar from "../../../../components/scrollbar";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
    StyledBody,
    StyledGroup,
    StyledLabel,
    StyledList,
    StyledLoadingWrapper,
    StyledNoDataWrapper,
    StyledReadMore,
    StyledTopDiv,
} from "./style";
import GroupItem from "./group-item";
import useFetchMention from "../hook/useFetchMention";
import TicketLoading from "../group/loading";
import Filter, { mentionIsRead, StateProps as FilterType } from "./filter";

const GroupMention: FC = () => {
    const dispatch = useAppDispatch();
    const [fetchMention] = useFetchMention();
    const [filterType, setFilterType] = useState<FilterType>("Unread");

    const { data, status, isBody, details } = useAppSelector(
        (state) => ({
            data: state.ticket.mention.data,
            status: state.ticket.mention.status,
            isBody: state.ui.isBody,
            details: state.ticket.mention.details,
        }),
        shallowEqual
    );

    const leadResponse = useQuery(
        ["fetchMentionLead", { lead_id: details?.note.noteable_id }],
        () => (details ? getLeadApi(details.note?.noteable_id) : null)
    );

    useEffect(() => {
        dispatch(
            doSetLead({
                lead: leadResponse?.data || null,
                loading: !!leadResponse?.isLoading,
            })
        );
    }, [leadResponse?.data, leadResponse?.isLoading, dispatch]);

    useEffect(() => {
        fetchMention();
    }, [fetchMention]);

    const handleViewDetail = useCallback(
        (mention: Mention | null) => {
            dispatch(setMentionDetails(mention));

            if (!isBody) {
                dispatch(toggleBody());
            }
        },
        [dispatch, isBody]
    );

    const dayName = (item: Mention) => {
        return moment(item.created_at).format("DD/MM/YYYY");
    };
    const notes = useMemo(() => {
        if (!data) {
            return [];
        }

        let mentions = data.data;

        if (filterType === "Read") {
            mentions = mentions.filter((i) => mentionIsRead(i)) || [];
        }

        if (filterType === "Unread") {
            mentions = mentions.filter((i) => !mentionIsRead(i)) || [];
        }

        handleViewDetail(mentions.length ? mentions[0] : null);

        let mentionData = groupBy(mentions, dayName);

        const sortKey = Object.keys(mentionData).sort(
            (a, b) =>
                moment(b, "DD/MM/YYYY").valueOf() -
                moment(a, "DD/MM/YYYY").valueOf()
        );
        const sortMentioned = sortKey.reduce((acc: any, key: any) => {
            // eslint-disable-next-line no-param-reassign
            acc[key] = mentionData[key];
            return acc;
        }, {});
        mentionData = sortMentioned;
        return mentionData;
    }, [data, filterType, handleViewDetail]);

    const handleLoadMore = () => {
        if (data) {
            dispatch(loadMore({ page: Number(data.current_page) + 1 }));
        }
    };

    const renderContent = () => {
        return (
            <>
                {Object.keys(notes).length > 0 && (
                    <>
                        {Object.entries(notes).map(([k, v]) => {
                            return (
                                <Fragment key={k}>
                                    <StyledLabel>
                                        {moment(k, "DD/MM/YYYY").calendar(
                                            null,
                                            {
                                                sameDay: "[Today]",
                                                nextDay: "[Tomorrow]",
                                                nextWeek: "dddd, MMM DD yyyy",
                                                lastDay: "[Yesterday]",
                                                lastWeek: "dddd, MMM DD yyyy",
                                                sameElse: "dddd, MMM DD yyyy",
                                            }
                                        )}
                                    </StyledLabel>
                                    <StyledList>
                                        {v.map((mention) => (
                                            <GroupItem
                                                key={mention.id}
                                                mention={mention}
                                                onClick={() =>
                                                    handleViewDetail(mention)
                                                }
                                            />
                                        ))}
                                    </StyledList>
                                </Fragment>
                            );
                        })}

                        {data?.next_page_url && status === "finished" && (
                            <StyledReadMore>
                                <Button
                                    size="xs"
                                    color="light"
                                    fullwidth
                                    onClick={handleLoadMore}
                                >
                                    Load more
                                </Button>
                            </StyledReadMore>
                        )}
                    </>
                )}
                {status === "finished" && Object.keys(notes).length === 0 && (
                    <StyledNoDataWrapper>
                        <img src={IllustrationCaugthupIcon} alt="" />
                        <p className="text">You&apos;re all caught up!</p>
                        <p className="note">You&apos;ve seen all new tasks</p>
                    </StyledNoDataWrapper>
                )}
            </>
        );
    };

    return (
        <StyledGroup>
            <ScrollBar>
                <StyledBody>
                    <StyledTopDiv>
                        <Heading tt="uppercase" fontWeight={600} mb="0px">
                            Mentioned
                        </Heading>
                        <Filter
                            mentions={data?.data || []}
                            selected={filterType}
                            onChange={(state) => setFilterType(state)}
                        />
                    </StyledTopDiv>
                    <>{renderContent()}</>
                    {status === "loading" && (
                        <StyledLoadingWrapper
                            hasData={Boolean(data && data.data.length !== 0)}
                        >
                            <TicketLoading />
                        </StyledLoadingWrapper>
                    )}
                </StyledBody>
            </ScrollBar>
        </StyledGroup>
    );
};

export default GroupMention;
