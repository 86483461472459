import {
    getLeadsApi,
    deleteLeadApi,
    getAllLeadsApi,
    updateLeadApi,
    deleteContactsApi,
    createLeadApi,
    getLeadApiBySelectAndGroupBy,
} from "src/api/lead/lead";
import { toastError } from "src/utils/toast";
import { all, call, put, select, takeLatest } from "typed-redux-saga";
import {
    doDeleteLead,
    doDeleteLeadSuccess,
    doGetAllLeads,
    doGetLeads,
    doGetLeadsSuccess,
    doGetSourceLeads,
    doGetSourceLeadsSuccess,
    doUpdateLead,
    doUpdateLeadSuccess,
    doUpdateLeadFail,
    doGetAllLeadsSuccess,
    doDeleteContacts,
    doGetLeadStages,
    doGetLeadStagesSuccess,
    doDeleteContactsSuccess,
    doDeleteContactsFail,
    doCreateLead,
    doCreateLeadSuccess,
    doUpdateLeadStage,
    doUpdateLeadStageFail,
    doUpdateLeadStageSuccess,
} from "../slices/contacts/lead";
import { clearRoom } from "../slices/ticket/daily";
import { updateDetail } from "../slices/ticket/detail";
import { RootState } from "../store";

function* getLeadsSaga(action: ReturnType<typeof doGetLeads>) {
    try {
        const fields = yield* select(
            (store: RootState) => store.contact.lead.filterGroup
        );

        const response = yield* call(getLeadsApi, {
            ...fields,
            ...(action.payload.limit && {
                limit: action.payload.limit,
            }),
            ...(action.payload.page && { page: action.payload.page }),
        });

        yield* put(
            doGetLeadsSuccess({
                leads: response.data,
                pagination: {
                    total: response.total,
                    currentPage: response.current_page,
                    limit: Number(response.per_page),
                    from: response.from,
                    to: response.to,
                },
            })
        );
    } catch (error) {
        toastError(error);
    }
}

function* getAllLeadsSaga(action: ReturnType<typeof doGetAllLeads>) {
    try {
        const response = yield* call(getAllLeadsApi, action.payload);
        const getData = JSON.parse(JSON.stringify(response));
        yield* call(action.payload.onSuccess, getData.data);
        yield* put(doGetAllLeadsSuccess());
    } catch (error) {
        toastError(error);
    }
}

function* getSourceLeadsSaga(action: ReturnType<typeof doGetSourceLeads>) {
    try {
        const response = yield* call(
            getLeadApiBySelectAndGroupBy,
            action.payload
                ? {
                      ...(action.payload.source && {
                          source: action.payload.source,
                      }),
                      groupBy: "source",
                      select: "source",
                  }
                : null
        );
        const getData = JSON.parse(JSON.stringify(response));
        yield* put(doGetSourceLeadsSuccess({ allSource: getData.data }));
    } catch (error) {
        toastError(error);
    }
}

function* getStageLeadsSaga(action: ReturnType<typeof doGetLeadStages>) {
    try {
        const response = yield* call(
            getLeadApiBySelectAndGroupBy,
            action.payload
                ? {
                      ...(action.payload.stage && {
                          stage: action.payload.stage,
                      }),
                      groupBy: "stage",
                      select: "stage,count(*)",
                  }
                : null
        );
        const getData = JSON.parse(JSON.stringify(response));
        yield* put(doGetLeadStagesSuccess({ allStage: getData.data }));
    } catch (error) {
        toastError(error);
    }
}

function* deleteLeadSaga(action: ReturnType<typeof doDeleteLead>) {
    try {
        yield* call(deleteLeadApi, action.payload.id);

        yield* put(doGetLeads({}));

        yield* put(doDeleteLeadSuccess(action.payload.id));

        yield* call(action.payload.onSuccess);
    } catch (err) {
        toastError(err);

        const error = JSON.parse(JSON.stringify(err));
        if (action.payload.onFail) {
            yield* call(action.payload.onFail, error?.error?.message);
        }
    }
}

function* deleteContactsSaga(action: ReturnType<typeof doDeleteContacts>) {
    try {
        yield* call(deleteContactsApi, { ids: action.payload.ids });

        yield* put(doDeleteContactsSuccess(action.payload.ids));

        yield* call(action.payload.onSuccess);
    } catch (err) {
        toastError(err);
        yield* put(doDeleteContactsFail());
    }
}

function* updateLeadSaga(action: ReturnType<typeof doUpdateLead>) {
    const { id, form, onFail, onSuccess } = action.payload;
    try {
        const response = yield* call(updateLeadApi, id, form);
        yield* put(doUpdateLeadSuccess(response));
        yield* put(clearRoom());

        const ticket = yield* select(
            (store: RootState) => store.ticket.detail.data
        );
        if (ticket) {
            yield* put(
                updateDetail({
                    data: {
                        ...ticket,
                        lead: response,
                    },
                    status: "finished",
                })
            );
        }
        if (onSuccess) {
            yield* call(onSuccess, response.id);
        }
    } catch (err) {
        yield* put(doUpdateLeadFail());

        const error = JSON.parse(JSON.stringify(err));
        if (onFail) {
            if (form.phone) {
                yield* call(
                    onFail,
                    error?.data?.errors?.phone
                        ? error?.data?.errors?.phone[0]
                        : error?.data?.message
                );
            }

            if (form.email) {
                yield* call(
                    onFail,
                    error?.data?.errors?.email
                        ? error?.data?.errors?.email[0]
                        : error?.data?.message
                );
            }
        }
    }
}

function* createLeadSaga(action: ReturnType<typeof doCreateLead>) {
    const { form, onFail, onSuccess } = action.payload;
    try {
        const response = yield* call(createLeadApi, form);
        yield* put(doCreateLeadSuccess(response));

        if (onSuccess) {
            yield* call(onSuccess, response.id);
        }
    } catch (err) {
        const error = JSON.parse(JSON.stringify(err));
        if (onFail) {
            yield* call(onFail, error?.data?.message);
        }
    }
}

function* updateLeadStageSaga(action: ReturnType<typeof doUpdateLeadStage>) {
    try {
        yield* call(updateLeadApi, action.payload.id, {
            stage: action.payload.newStage,
        });

        yield* put(
            doUpdateLeadStageSuccess({
                id: action.payload.id,
                newStage: action.payload.newStage,
                oldStage: action.payload.oldStage,
            })
        );
    } catch (err) {
        yield* put(doUpdateLeadStageFail());
        if (action.payload.onFail) {
            yield* call(
                action.payload.onFail,
                JSON.parse(JSON.stringify(err))?.data?.message
            );
        }
    }
}

export function* contactSaga(): Generator {
    yield all([
        takeLatest(doGetLeads, getLeadsSaga),
        takeLatest(doGetSourceLeads, getSourceLeadsSaga),
        takeLatest(doGetLeadStages, getStageLeadsSaga),
        takeLatest(doDeleteLead, deleteLeadSaga),
        takeLatest(doDeleteContacts, deleteContactsSaga),
        takeLatest(doGetAllLeads, getAllLeadsSaga),
        takeLatest(doUpdateLead, updateLeadSaga),
        takeLatest(doCreateLead, createLeadSaga),
        takeLatest(doUpdateLeadStage, updateLeadStageSaga),
    ]);
}
