/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import { Spinner } from "@doar/components";
import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { startMeeting } from "src/redux/slices/ticket/daily";
import { Lead } from "src/types/api/ticket";
import { StyledMenu, StyledDropdown, StyledMenuItem } from "../style";

interface Props {
    lead: Lead | null;
    show: boolean;
    onClose: () => void;
}
const HandleVideoCall: FC<Props> = ({ lead, show, onClose }) => {
    const [loading, setLoading] = useState(false);
    const ref = useOnclickOutside(() => onClose());
    const meetingRoom = useAppSelector((store) => store.ticket.daily.room);

    useEffect(() => {
        if (meetingRoom?.url) {
            setLoading(true);
        } else setLoading(false);
    }, [meetingRoom?.url]);

    const dispatch = useAppDispatch();

    const handleCreateRoom = async () => {
        setLoading(true);
        await dispatch(startMeeting({ leadId: lead?.id || null }));
    };
    return (
        <StyledDropdown $show={show} ref={ref}>
            <StyledMenu className="dropdownOutSide">
                <StyledMenuItem
                    variant="texted"
                    onClick={handleCreateRoom}
                    disabled={loading}
                    className={classNames({
                        disabled: loading,
                    })}
                >
                    Start a video call
                    {loading && <Spinner size="xs" />}
                </StyledMenuItem>
            </StyledMenu>
        </StyledDropdown>
    );
};

export default HandleVideoCall;
