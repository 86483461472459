import { createRoomMeeting } from "src/api/ticket/ticket";
import {
    clearRoom,
    meetingFail,
    startMeeting,
    meetingSuccess,
} from "src/redux/slices/ticket/daily";
import { doCheckMeetingRoomSuccess } from "src/redux/slices/ticket/ui";
import { all, call, put, takeLatest } from "typed-redux-saga";

function* getMeetingRoomSaga(action: ReturnType<typeof startMeeting>) {
    try {
        yield* put(clearRoom());
        const response = yield* call(createRoomMeeting, action.payload.leadId);
        yield* put(meetingSuccess(response));
        yield* put(
            doCheckMeetingRoomSuccess({
                path: "Call Lead",
            })
        );
    } catch (error) {
        const Error = JSON.parse(JSON.stringify(error));
        yield* put(meetingFail({ message: Error.data.message }));
    }
}

export function* dailyRoomSaga(): Generator {
    yield all([takeLatest(startMeeting, getMeetingRoomSaga)]);
}
