import { Audit } from "src/types/api/ticket";
import request from "src/utils/request";

export const storeAuditApi = (form: {
    type: "read" | "ticket_reopen" | "ticket_closed";
    auditable_id: number;
    auditable_type: `App\\Models\\Ticket` | `App\\Models\\Note`;
}): Promise<Audit> =>
    request.post<Audit, Audit>(`api/audits`, {
        type: form.type,
        auditable_id: form.auditable_id,
        auditable_type: form.auditable_type,
    });
