import styled, { ColorProps, color } from "@doar/shared/styled";

export const StyledFileWrap = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const StyledFileThumbIcon = styled(({ ...rest }) => (
    <div {...rest} />
))<ColorProps>`
    background-color: #fff;
    width: 40px;
    height: 40px;
    padding: 10px;
    ${color};
`;

export const StyledFileThumbImg = styled.img`
    width: 40px;
    height: 40px;
    object-fit: cover;
`;

export const StyledFileName = styled.div`
    font-weight: 500;
    margin-left: 10px;
    overflow: hidden;
    max-width: 60%;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: default;
`;
