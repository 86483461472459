import {
    createTaskApi,
    getTasksApi,
    deleteTaskApi,
    updateTaskApi,
} from "src/api/task/task";
import { all, call, put, takeLatest } from "typed-redux-saga";
import {
    create,
    createFail,
    createSuccess,
    fail,
    start,
    success,
    doDelete,
    deleteFail,
    deleteSuccess,
    doUpdate,
    doUpdateFail,
    doUpdateSuccess,
} from "../../slices/ticket/task";

function* getListTaskSaga() {
    try {
        const response = yield* call(getTasksApi);
        yield* put(success(response));
    } catch (error) {
        yield* put(fail());
    }
}

function* createTaskSaga(action: ReturnType<typeof create>) {
    try {
        const response = yield* call(createTaskApi, action.payload.form);
        yield* call(action.payload.onSuccess);
        yield* put(start());
        yield* put(createSuccess(response));
    } catch (error) {
        yield* call(action.payload.onFail);
        yield* put(createFail());
    }
}

function* deleteTaskSaga(action: ReturnType<typeof doDelete>) {
    try {
        yield* call(deleteTaskApi, action.payload.id);
        yield* put(start());
        yield* put(deleteSuccess(action.payload.id));

        yield* call(action.payload.onSuccess);
    } catch (err) {
        const error = JSON.parse(JSON.stringify(err));
        if (action.payload.onFail) {
            yield* call(action.payload.onFail, error?.error?.message);
            yield* put(deleteFail());
        }
    }
}

function* updateTaskSaga(action: ReturnType<typeof doUpdate>) {
    const { id, form, onFail, onSuccess } = action.payload;
    try {
        const response = yield* call(updateTaskApi, id, form);
        yield* put(start());
        yield* put(doUpdateSuccess(response));

        if (onSuccess) {
            yield* call(onSuccess, response.id);
        }
    } catch (err) {
        yield* put(doUpdateFail());

        const error = JSON.parse(JSON.stringify(err));
        if (onFail) {
            yield* call(onFail, error?.error?.message);
            yield* put(doUpdateFail());
        }
    }
}

export function* taskSaga(): Generator {
    yield all([
        takeLatest(start, getListTaskSaga),
        takeLatest(create, createTaskSaga),
        takeLatest(doDelete, deleteTaskSaga),
        takeLatest(doUpdate, updateTaskSaga),
    ]);
}
