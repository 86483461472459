import { FC } from "react";
import SkeletonLoading from "src/components/skeleton-loading";
import { StyledLoadingWrap } from "./style";

const HtmlLoading: FC = () => {
    const styles = {
        borderRadius: "20px",
    };
    return (
        <StyledLoadingWrap>
            <SkeletonLoading height="30px" style={styles} />
            <br />
            <SkeletonLoading height="20px" style={styles} width="50%" />
            <SkeletonLoading height="20px" style={styles} width="80%" />
            <SkeletonLoading height="20px" style={styles} width="30%" />
            <SkeletonLoading height="20px" style={styles} width="50%" />
            <SkeletonLoading height="20px" style={styles} width="70%" />
            <SkeletonLoading height="20px" style={styles} width="100%" />
            <SkeletonLoading height="20px" style={styles} width="50%" />
            <SkeletonLoading height="20px" style={styles} width="30%" />
            <SkeletonLoading height="20px" style={styles} width="20%" />
        </StyledLoadingWrap>
    );
};

export default HtmlLoading;
