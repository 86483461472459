import { FC, useState } from "react";
import { Button, Dropdown, DropdownToggle } from "@doar/components";
import { useHistory } from "react-router-dom";
import { stringify } from "query-string";
import { useOnEscape } from "@doar/shared/hooks";
import useOnclickOutside from "react-cool-onclickoutside";
import { CheckSquare, Mail, MessageSquare } from "react-feather";
import ModalNewTask from "../modal-new-task";
import {
    StyledWrap,
    StyledDropMenu,
    StyledDropItem,
    StyledText,
    StyledButton,
} from "./style";

const ComposeBtn: FC = () => {
    const history = useHistory();
    const [showModalCreateTask, setShowModalCreateTask] = useState(false);
    const [show, setShow] = useState(false);
    const onComposeEmail = () => {
        history.push({
            search: stringify({
                compose: "email",
            }),
        });
        setShow(false);
    };
    const onComposeSms = () => {
        history.push({
            search: stringify({
                compose: "sms",
                modal: "open",
            }),
        });
        setShow(false);
    };
    useOnEscape(() => setShow(false));

    const onClose = () => {
        setShow(false);
    };

    const ref = useOnclickOutside(() => onClose());

    return (
        <>
            <StyledWrap>
                <StyledButton ref={ref}>
                    <Dropdown
                        direction="down"
                        className="dropdown"
                        isShow={show}
                    >
                        <DropdownToggle variant="texted">
                            <Button onClick={() => setShow(!show)}>
                                Create
                            </Button>
                        </DropdownToggle>
                        <StyledDropMenu>
                            <StyledDropItem onClick={onComposeSms}>
                                <MessageSquare fontSize="9px" />
                                <StyledText>Send a text</StyledText>
                            </StyledDropItem>

                            <StyledDropItem onClick={onComposeEmail}>
                                <Mail fontSize="9px" />
                                <StyledText>Send an email</StyledText>
                            </StyledDropItem>

                            <StyledDropItem
                                onClick={() => {
                                    setShow(false);
                                    setShowModalCreateTask(true);
                                }}
                            >
                                <CheckSquare fontSize="9px" />
                                <StyledText style={{ marginTop: "2px" }}>
                                    Create a task
                                </StyledText>
                            </StyledDropItem>
                        </StyledDropMenu>
                    </Dropdown>
                </StyledButton>
            </StyledWrap>
            <ModalNewTask
                show={showModalCreateTask}
                onClose={() => setShowModalCreateTask(false)}
            />
        </>
    );
};

export default ComposeBtn;
