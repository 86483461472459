import {
    Avatar,
    AvatarInitial,
    Button,
    Label,
    Spinner,
} from "@doar/components";
import { FC, useEffect, useState, KeyboardEvent } from "react";
import { Paperclip, Upload } from "react-feather";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { doCreateNote } from "src/redux/slices/ticket/note";
import { OnChangeHandlerFunc, SuggestionDataItem } from "react-mentions";
import { Lead, Mention, Note } from "src/types/api/ticket";
import { useHistory } from "react-router-dom";
import { isImageFile } from "src/helpers/fileHelpers";
import FilePreview from "src/components/file-preview";
import ImagePreview from "src/components/image-preview";
import { create as markSeen } from "src/redux/slices/ticket/audit";
import { useQuery } from "react-query";
import Mentions from "src/components/widgets/mentions/Mentions";
import { generateLeadImageColor } from "src/helpers/generateLeadImageColor";
import { getStaffsApi } from "../../../../../../api/staff/staff";
import {
    StyledDropdownItem,
    StyledFilesContainer,
    StyledIcon,
    StyledShare,
    StyledWrap,
} from "./style";

interface Props {
    lead?: Lead | null;
    files: File[] | null;
    onChangeFiles: (files: File[] | null) => void;
}
const UpdateNote: FC<Props> = ({ lead, files, onChangeFiles }) => {
    const history = useHistory();
    const [value, setValue] = useState("");
    const [loading, setLoading] = useState(false);
    const { ticketDetails, user, notes } = useAppSelector((store) => ({
        ticketDetails: store.ticket.detail.data,
        user: store.authentication.user,
        notes: store.ticket.note.data?.data,
    }));
    const [error, setError] = useState(false);

    const { data } = useQuery(["fetchStaffsInMention"], () =>
        getStaffsApi(null)
    );

    useEffect(() => {
        // set value = '' when change lead
        setValue("");
    }, [lead]);

    const dispatch = useAppDispatch();
    const onChange: OnChangeHandlerFunc = (e) => {
        setValue(e.target.value);
        setError(false);
    };

    const isMentionStaff = (mentions: Mention[]) => {
        for (let i = 0; i < mentions.length; i += 1) {
            if (mentions[i].mentionable_id === user?.staff?.id) {
                return true;
            }
        }
        return false;
    };

    const isNotSeen = (note: Note) => {
        return !note.audits?.find((i) => i.auditable_id === note.id);
    };

    const onMarkSeenNoteMention = () => {
        const notesAreNotSeen =
            notes
                ?.filter((note) => isMentionStaff(note.mentions))
                ?.filter((note) => isNotSeen(note)) || [];

        notesAreNotSeen.forEach((note) => {
            dispatch(
                markSeen({
                    type: "read",
                    noteId: note.id,
                })
            );
        });
    };
    const onSubmit = () => {
        if (lead) {
            if (!value.trim() && (!files || !files.length)) {
                setError(true);
                return;
            }

            setError(false);
            setLoading(true);

            dispatch(
                doCreateNote({
                    form: {
                        note: value.trim() || null,
                        lead_id: lead.id,
                        ...(files && files.length && { files }),
                        ...(history.location.pathname.includes("tickets") &&
                            ticketDetails && {
                                ticket_id: ticketDetails.id,
                            }),
                    },
                    onSuccess: () => {
                        setLoading(false);
                        onChangeFiles(null);
                        setValue("");
                    },
                    onFail: () => {
                        setLoading(false);
                    },
                })
            );
            onMarkSeenNoteMention();
        }
    };

    const imageFiles = () => files?.filter((file) => isImageFile(file.name));

    const notImageFiles = () =>
        files?.filter((file) => !isImageFile(file.name));

    const onRemoveImage = (index: number) => {
        const newArray = imageFiles()?.filter((f, i) => i !== index) || null;
        const notImageFileArray = notImageFiles();

        let result: File[] | null = [];

        if (newArray) {
            result = [...newArray];
        }

        if (notImageFileArray) {
            result = [...result, ...notImageFileArray];
        }

        onChangeFiles(result?.length ? result : null);
    };

    const onRemoveFile = (index: number) => {
        const newArray = notImageFiles()?.filter((f, i) => i !== index) || null;
        const imageFileArray = imageFiles();

        let result: File[] | null = [];

        if (newArray) {
            result = [...newArray];
        }

        if (imageFileArray) {
            result = [...result, ...imageFileArray];
        }

        onChangeFiles(result?.length ? result : null);
    };

    const onKeyDown = (
        event:
            | KeyboardEvent<HTMLTextAreaElement>
            | KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.code === "Enter" && value) {
            event.preventDefault();
            onSubmit();
        }
    };

    const renderSuggestion = (
        suggestion: SuggestionDataItem,
        search: string,
        highlightedDisplay: React.ReactNode,
        index: number,
        focused: boolean
    ) => {
        const staff = data?.data.find((i) => i.id === suggestion.id);
        return (
            <StyledDropdownItem active={focused}>
                <Avatar size="xs" shape="rounded" mr="10px">
                    {staff?.photo ? (
                        <img src={staff.photo} alt="" />
                    ) : (
                        <AvatarInitial
                            bg={generateLeadImageColor(Number(suggestion.id))}
                        >
                            {suggestion.display
                                ?.split(" ")
                                .map((i) => i[0].toUpperCase())
                                .join("") || ""}
                        </AvatarInitial>
                    )}
                </Avatar>
                {suggestion.display}
            </StyledDropdownItem>
        );
    };

    return (
        <StyledWrap>
            <StyledShare $hasError={error}>
                <Mentions
                    placeholder="Type a note"
                    onChange={onChange}
                    value={value}
                    data={
                        data?.data.map((i) => ({
                            id: i.id,
                            display: [i.first_name, i.last_name].join("."),
                        })) || []
                    }
                    suggestionsPortalHost={document.body}
                    forceSuggestionsAboveCursor
                    allowSuggestionsAboveCursor
                    onKeyDown={onKeyDown}
                    renderSuggestion={renderSuggestion}
                    disabled={loading}
                />
            </StyledShare>
            <StyledFilesContainer>
                {imageFiles()?.map((file, index) => {
                    return (
                        <ImagePreview
                            key={String(index)}
                            index={index}
                            file={file}
                            onRemoveImage={onRemoveImage}
                        />
                    );
                })}
                {notImageFiles()?.map((file, index) => {
                    return (
                        <FilePreview
                            key={String(index)}
                            index={index}
                            file={file}
                            onRemoveFile={onRemoveFile}
                        />
                    );
                })}
            </StyledFilesContainer>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
                <StyledIcon>
                    <Label htmlFor="note-files">
                        <Paperclip width={14} height={14} cursor="pointer" />
                    </Label>
                    <input
                        type="file"
                        hidden
                        id="note-files"
                        multiple
                        onChange={(e) => {
                            if (e?.target?.files && e.target.files.length > 0) {
                                let filesArray: File[] = [];
                                if (files) {
                                    filesArray = [...files];
                                }
                                onChangeFiles(
                                    [
                                        ...filesArray,
                                        ...Array.from(e?.target?.files),
                                    ] || null
                                );
                            }
                        }}
                    />
                </StyledIcon>
                <div style={{ marginLeft: "auto" }}>
                    <Button
                        onClick={onSubmit}
                        disabled={loading}
                        mt="10px"
                        color="light"
                    >
                        <div style={{ height: "18px" }}>
                            {loading ? (
                                <Spinner size="xs" color="primary" />
                            ) : (
                                <Upload size={18} />
                            )}
                        </div>
                    </Button>
                </div>
            </div>
        </StyledWrap>
    );
};

export default UpdateNote;
