import { FC, useEffect, useRef, useState } from "react";
import ScrollBar from "src/components/scrollbar";
import { useAppSelector } from "src/redux/hooks";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";
import ChatGroup from "./chat-group";
import { StyledScrollZone } from "./style";

const MailBody: FC = () => {
    const location = useLocation();
    const parsed = parse(location.search);
    const scrollZoneRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);

    const { selectedTab, messages, lead, ticket } = useAppSelector((store) => ({
        selectedTab: store.contact.lead.selectedTab,
        messages: store.lead.message.messages,
        lead: store.contact.lead.lead,
        ticket: store.ticket.detail.data,
    }));

    useEffect(() => {
        if (scrollZoneRef?.current) {
            setHeight(scrollZoneRef.current.offsetHeight);
        }
    }, [scrollZoneRef?.current?.offsetHeight, selectedTab, messages?.length]);

    return (
        <ScrollBar childrenHeight={parsed.type !== "outbound" ? height : 0}>
            <div ref={scrollZoneRef}>
                <StyledScrollZone>
                    <ChatGroup lead={lead} ticket={ticket || undefined} />
                </StyledScrollZone>
            </div>
        </ScrollBar>
    );
};

export default MailBody;
