import { combineReducers } from "@reduxjs/toolkit";
import fileReducer from "./file";
import messageReducer from "./message";

const leadReducer = combineReducers({
    file: fileReducer,
    message: messageReducer,
});

export default leadReducer;
