import { combineReducers } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import leadReducer from "./lead";

const persistConfig = {
    key: 'filter',
    storage,
    whitelist: ['filterGroup']
}

const contactReducer = combineReducers({
    lead: persistReducer(persistConfig, leadReducer),
});

export default contactReducer;
